/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './GenericErrorPage.scss';
import '../../styles/commonPageStyles.scss';

import { Button, DesktopCard, TitleWithUnderLine } from '@boi/core/lib';
import React, { useContext } from 'react';
import type { Match } from 'react-router-dom';
import { Link, withRouter } from 'react-router-dom';

import { commonPageStylesIdentifier } from '../../constants';
import { backToHome } from '../../constants/buttonLabelConstants';
import { routes } from '../../routes';
import type { HomePayloadType, PayloadType } from '../../types';
import UserContext from '../../UserContext';
import HomeErrorPage from './HomeErrorPage';
import MotorErrorPage from './MotorErrorPage';
import RSAErrorPage from './RSAErrorPage';
import TravelErrorPage from './TravelErrorPage';

const className = 'c-GenericErrorPage';

interface Props {
  payload?: PayloadType | HomePayloadType;
  errorType?: string;
  match: Match;
  error?: string;
}

const GenericErrorPage = (props: Props) => {
  const { getUserName } = useContext(UserContext);
  const errorType = (() => {
    if (props.errorType) return props.errorType;

    if (props.match.params.type) return props.match.params.type;

    return '';
  })();

  const redTextMessage =
    'We have not been able to get an online quote from our insurance partner.';

  let quoteReferenceLine =
    'If you would like to discuss your proposal for insurance further ';
  const reference = `your quotation reference is`;

  // $FlowFixMe
  if (props.payload?.response?.quote_reference) {
    // $FlowFixMe
    quoteReferenceLine = `${quoteReferenceLine} ${reference} ${props.payload.response.quote_reference}`;
  } else {
    quoteReferenceLine = "";
  }

  const isCardError = props.error === "CARD_PAYMENT";

  const firstName = getUserName();

  const errorPages = {
    '': '',
    rsa: <RSAErrorPage firstName={firstName} />,
    home: (
      <HomeErrorPage
        firstName={firstName}
        redTextMessage={redTextMessage}
        quoteReferenceLine={quoteReferenceLine}
        isCardError={isCardError}
      />
    ),
    car: <MotorErrorPage firstName={firstName} isCardError={isCardError} />,
    travel: (
      <TravelErrorPage
        firstName={firstName}
        redTextMessage={redTextMessage}
        isCardError={isCardError}
      />
    ),
    van: <div></div>,
    business: <div></div>,
    life: <div></div>,
    pet: <div></div>,
    health: <div></div>,
    pensions: <div></div>,
    savingsAndInvestments: <div></div>
  };

  const getControlButtons = () => isCardError ? (
    <>
      <Link to={`${routes.makePayment.url}${errorType}`}
            data-ga
            id={`GenericErrorPage__tryAgainButton`} data-testid={`GenericErrorPage__tryAgainButton`}>
        <Button fluid={true}>Try again</Button>
      </Link>
      <div className={`${className}__buttonContainer`}>
        <Link to={routes.wallet.url}
              data-ga
              id={`GenericErrorPage__homeButton`} data-testid={`GenericErrorPage__homeButton`}>
          {backToHome}
        </Link>
      </div>
    </>
  ) : (
    <Link to={routes.wallet.url}
          data-ga
          id={`GenericErrorPage__homeButton`} data-testid={`GenericErrorPage__homeButton`}>
      <Button fluid={true}>{backToHome}</Button>
    </Link>);

  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__showOnDesktopOnly`}>
        <div className={`${commonPageStylesIdentifier}__pageTitle`}>
          <TitleWithUnderLine>Get a Quote</TitleWithUnderLine>
        </div>
      </div>
      <DesktopCard>
        {errorPages[errorType]}
        <div className={`${className}__buttonContainer`}>
          {getControlButtons()}

        </div>

      </DesktopCard>
    </div>
  );
};


export default withRouter(GenericErrorPage);
