// @flow

import { CAR_INSURANCE_TYPE, TRAVEL_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import type { LatestCallAddressType } from '../../types/commonTypes';
import type { AboutYouProfile, UserProfileType } from '../../types/profile';
import { rawToIrishFormat } from './../../helpers';

const employmentStatusValues = {
  OCC143: 'HOUSEWIFE_HUSBAND',
  OCC234: 'RETIRED',
  OCC260: 'STUDENT',
  SEF: 'SELF_EMPLOYED',
  UAC: 'UNEMPLOYED'
};

const getEmploymentStatusCode = (occupationCode?: string) =>
  occupationCode
    ? ['OCC143', 'OCC234', 'OCC260', 'SEF', 'UAC'].includes(occupationCode)
      ? employmentStatusValues[occupationCode]
      : 'EMPLOYED'
    : '';

const getOrEmpty = (fieldValue?: string) => fieldValue || '';

const getAddressFieldValue = (
  address: LatestCallAddressType,
  fieldName: string
) => (address && address[fieldName] ? address[fieldName] : '');

const getTitleOrEmpty = (insuranceType: string, fieldValue?: string) => {
  if (!fieldValue) {
    return '';
  } else if (fieldValue === 'DR') {
    return insuranceType === CAR_INSURANCE_TYPE ? '' : fieldValue;
  }
  return fieldValue;
};

const getAddressFieldValueForInsuranceType = (
  address: LatestCallAddressType,
  fieldName: string,
  insuranceType: string
): string => (insuranceType === TRAVEL_INSURANCE_TYPE ? '' : getAddressFieldValue(address, fieldName));

export const mapAboutYouProfileValues = (
  values: UserProfileType,
  insuranceType: string
): AboutYouProfile => ({
  title: getTitleOrEmpty(insuranceType, values.title),
  firstName: getOrEmpty(values.first_name),
  lastName: getOrEmpty(values.last_name),
  email: getOrEmpty(values.email),
  addressLine1: getAddressFieldValueForInsuranceType(values.address, 'address_line1', insuranceType),
  addressLine2: getAddressFieldValueForInsuranceType(values.address, 'address_line2', insuranceType),
  addressLine3: getAddressFieldValueForInsuranceType(values.address, 'address_line3', insuranceType),
  addressTown: getAddressFieldValueForInsuranceType(values.address, 'town', insuranceType),
  addressCounty: getAddressFieldValueForInsuranceType(values.address, 'county', insuranceType),
  addressEircode: getAddressFieldValueForInsuranceType(values.address, 'eircode', insuranceType),
  phoneNo: getOrEmpty(values.phone),
  dob: rawToIrishFormat(getOrEmpty(values.date_of_birth)),
  employmentStatus: getEmploymentStatusCode(values.occupation),
  mainOccupation: values.occupation,
  policyLength: values.policies?.length || 0,
});
