/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../../styles/commonFormStyles.scss';
import './YourCoverForm.scss';

import {
  AccordionText,
  DatePicker,
  Dropdown,
  InputField,
  SimpleSwitch
} from '@boi/core/lib';
import { noop } from '@boi/core/lib/utils';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { Form, FormikProps, setNestedObjectValues, withFormik } from 'formik';
import React, { Component } from 'react';
import * as yup from 'yup';

import type AboutYouFormValues from '../../../components/Home/AboutYouForm';
import type YourHomeFormValues from '../../../components/Home/YourHomeForm';
import type { HomePrepoulationCheck } from '../../../constants/home';
import {
  CONTENTS_COVER_TYPE_OPTIONS,
  CONTENTS_COVER_TYPE_VALUES,
  marketingConsentInForm
} from '../../../constants/home';
import {
  HOME_COVER_TYPE,
  PREPOPULATION_PREFORMED
} from '../../../constants/home';
import { commonFormStylesIdentifier } from '../../../constants/styleConstants/commonClassNames';
import { addDaysToDate, getCurrentDate } from '../../../helpers/DateHelper';
import { saveData } from '../../../helpers/HomeCommonMethods';
import { decimalNumberMask } from '../../../helpers/NumberMask';
import MarketingConsent from '../../HomeMarketingConsent/MarketingConsent';
import SpecifiedItems from '../../SpecifiedItems';
import UnspecifiedItems from '../../UnspecifiedItems';
import YourCoverFormYupSchema from './YourCoverFormYupSchema';

export type specifiedItemsProp = {
  item: string,
  value: string,
  description: string
};

export type YourCoverFormValues = {
  policyStart: string,
  rebuildCost: string,
  contentsCoverOf: string,
  coverTypeValidation: string,
  homeTypeValidation: string,
  checkRebuildCost: boolean,
  specifiedItems: specifiedItemsProp[],
  county: string,
  marketingConsent: boolean,
  marketingConsentEmail: boolean,
  marketingConsentPost: boolean,
  marketingConsentPhone: boolean,
  marketingConsentText: boolean,
  consent: boolean,
  getInTouch: boolean
};

export type State = {
  selectedOptions: any
};

interface Props {
  formOpened: noop;
  yourCoverValid: boolean;
  validForm: noop;
  setUpdatedYourCoverValues: noop;
  yourCoverFormValues: YourCoverFormValues;
  showRebuildCost?: boolean;
  isPreFilled: boolean;
  yourCoverFormTrackCount: number;
  prepopulationStatus: HomePrepoulationCheck;
  yourHomeObj: YourHomeFormValues;
  aboutYouObj: AboutYouFormValues;
  values: noop;
}

const className = 'c-YourCoverForm';

const setFormIsValidReturnValues = (
  isSubmitting: boolean,
  isValid: boolean,
  yourCoverValid: boolean,
  validForm: noop,
  setUpdatedYourCoverValues: noop,
  values?: YourCoverFormValues
) => {
  if (!isSubmitting && isValid !== yourCoverValid) {
    validForm('yourCover', isValid);
    setUpdatedYourCoverValues(values);
  }
};

class YourCoverForm extends Component<
  Props & FormikProps<YourCoverFormValues>,
  State
> {
  state = {
    selectedOptions: this.getAllOptions()
  };

  prepoulateWithLatest = () => {
    if (this.props.prepopulationStatus === PREPOPULATION_PREFORMED) {
      this.props.setValues({
        ...this.props.yourCoverFormValues,
        checkRebuildCost: this.props.showRebuildCost,
        coverTypeValidation: this.props.yourHomeObj.coverType,
        homeTypeValidation: this.props.yourHomeObj.homeType,
        county: this.props.aboutYouObj.county
      });
    }
  };

  //eslint-disable-next-line complexity
  touchAllFields = () => {
    if (
      this.props.aboutYouObj?.policyLength !== undefined &&
      (this.props.aboutYouObj.policyLength > 0 ||
        this.props.aboutYouObj.policyLength === 0) &&
      this.props.yourCoverFormTrackCount === 0
    ) {
      this.props.setFieldTouched(
        setNestedObjectValues(this.props.values !== '', true)
      );
    } else if (
      this.props.yourCoverFormTrackCount > 0 ||
      this.props.prepopulationStatus === PREPOPULATION_PREFORMED
    ) {
      this.props.setTouched(setNestedObjectValues(this.props.values, true));
    }
  };

  componentDidMount() {
    this.props.formOpened('yourCover');

    if (this.props.isPreFilled) {
      this.props.validateForm().then((errors: {}) => {
        this.props.validForm('yourCover', Object.keys(errors).length === 0);
      });
    }
    this.touchAllFields();
    this.setValidationValues();
    this.prepoulateWithLatest();
  }

  setcountyValue = (prevProps: Props) => {
    if (this.props.aboutYouObj.county !== prevProps.aboutYouObj.county) {
      this.updateFormValueAndYourCoverObject(
        'county',
        this.props.aboutYouObj.county
      );
    }
  };

  updateCoverValues = (preProps: Props) => {
    if (preProps.values !== this.props.values) {
      this.props.setUpdatedYourCoverValues({
        ...this.props.yourCoverFormValues,
        checkRebuildCost: this.props.showRebuildCost,
        coverTypeValidation: this.props.yourHomeObj.coverType,
        homeTypeValidation: this.props.yourHomeObj.homeType,
        county: this.props.aboutYouObj.county
      });
    }
  };

  // eslint-disable-next-line complexity
  componentDidUpdate(prevProps: Props & FormikProps<YourCoverFormValues>) {
    (() => {
      if (
        this.props.yourCoverFormTrackCount !==
          prevProps.yourCoverFormTrackCount ||
        this.props.prepopulationStatus !== prevProps.prepopulationStatus
      ) {
        this.touchAllFields();
      }
    })();

    (() => {
      if (this.props.prepopulationStatus !== prevProps.prepopulationStatus) {
        this.prepoulateWithLatest();
      }
    })();
    this.resetValidationForContentCoverValues(prevProps);
    if (!this.props.showRebuildCost && this.props.values.rebuildCost !== '') {
      this.clearRebuildCost();
    }

    if (
      !this.props.showRebuildCost &&
      this.props.showRebuildCost !== this.props.values.checkRebuildCost
    ) {
      this.updateFormValueAndYourCoverObject('checkRebuildCost', false);
    }

    if (
      this.props.values?.specifiedItems?.length > 0 ||
      this.props.values?.unspecifiedItems !== ''
    ) {
      if (this.props.values?.specifiedItemsSwitch !== '') {
        if (
          !this.props.values?.specifiedItemsSwitch &&
          (this.props.values?.specifiedItems?.length > 0 &&
            this.props.values?.specifiedItems[0]?.value !== '')
        ) {
          // $FlowFixMe
          this.updateFormValueAndYourCoverObject('specifiedItems', [
            {
              item: '',
              value: '',
              description: ''
            }
          ]);
        }
      }
      if (
        !this.props.values?.unspecifiedItemsSwitch &&
        this.props.values?.unspecifiedItems !== ''
      ) {
        this.updateFormValueAndYourCoverObject('unspecifiedItems', '');
      }
    }
    this.setcountyValue(prevProps);
    this.updateCoverValues(prevProps);
  }

  clearRebuildCost = () => {
    const { showRebuildCost } = this.props;
    if (showRebuildCost === false) {
      this.updateFormValueAndYourCoverObject('rebuildCost', '');
      this.setState({ selectedOptions: this.getAllOptions() });
    }
  };

  // eslint-disable-next-line complexity
  setValidationValues = () => {
    this.props.setValues({
      ...this.props.yourCoverFormValues,
      checkRebuildCost: this.props.showRebuildCost,
      coverTypeValidation: this.props.yourHomeObj.coverType,
      homeTypeValidation: this.props.yourHomeObj.homeType,
      county: this.props.aboutYouObj.county
    });
  };

  resetValidationForContentCoverValues = (prevProps: Props) => {
    if (this.props.yourHomeObj.coverType !== prevProps.yourHomeObj.coverType) {
      this.updateFormValueAndYourCoverObject(
        'coverTypeValidation',
        this.props.yourHomeObj.coverType
      );
      this.props.setFieldValue('checkRebuildCost', this.props.showRebuildCost);
    }
    if (this.props.yourHomeObj.homeType !== prevProps.yourHomeObj.homeType) {
      this.updateFormValueAndYourCoverObject(
        'homeTypeValidation',
        this.props.yourHomeObj.homeType
      );
      this.props.setFieldValue('checkRebuildCost', this.props.showRebuildCost);
    }
  };

  updateFormValueAndYourCoverObject = (
    fieldName: string,
    value: string | boolean | number
  ) => {
    const { setFieldValue, setYourCoverObjForUpdatedFields } = this.props;
    setFieldValue(fieldName, value);
    setYourCoverObjForUpdatedFields(fieldName, value);
  };

  /**
   * Need to call setYourCoverObjForUpdatedFields in onBlur rather than onChange to save details on the form correctly
   * when a user closes the form while inputting data.
   **/
  handleCustomBlur = (
    fieldName: string,
    event: SyntheticEvent<HTMLInputElement>
  ) => {
    var target = event.target;
    if (target instanceof HTMLInputElement) {
      const {
        handleBlur,
        setYourCoverObjForUpdatedFields,
        setValues,
        values
      } = this.props;
      setValues({ ...values, [fieldName]: target.value });
      setYourCoverObjForUpdatedFields(fieldName, target.value);
      handleBlur(event);
    }
  };

  getAllOptions(): Array<{ value: string, label: string }> {
    return CONTENTS_COVER_TYPE_VALUES.map((content: string, index: number) => ({
      value: String(CONTENTS_COVER_TYPE_OPTIONS[index]),
      label: content
    }));
  }

  // eslint-disable-next-line complexity
  render() {
    const {
      isSubmitting,
      isValid,
      setTouched,
      handleBlur,
      yourCoverValid,
      validForm,
      setUpdatedYourCoverValues,
      values,
      errors,
      touched,
      handleChange,
      setFieldValue,
      showRebuildCost
    } = this.props;

    setFormIsValidReturnValues(
      isSubmitting,
      isValid,
      yourCoverValid,
      validForm,
      setUpdatedYourCoverValues,
      values
    );
    //LEAVING AS LINKS WILL BE NEEDED
    // const homeAssumptionsURL = {
    //   '': '//boi.com/insurancehomeassumptionsmpd',
    //   [HOME_COVER_TYPE.OWN_LIVE]: '//boi.com/insurancehomeassumptionsmpd',
    //   [HOME_COVER_TYPE.OWN_RENT]: '//boi.com/insurancehomeassumptionsrip',
    //   [HOME_COVER_TYPE.OWN_HOLIDAY]: '//boi.com/insurancehomeassumptionshh',
    //   [HOME_COVER_TYPE.RENT]: '//boi.com/insurancehomeassumptionsmpd'
    // };
    const isFieldError = (status: string) =>
      touched[status] && errors[status] !== undefined;

    const renderUnspecifiedItems = () => {
      return (
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor="unspecifiedItemsSwitch"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            <AccordionText
              label="Add Cover for Unspecified Items"
              icon="info"
              iconAlign="right"
              customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
            >
              <div className={`${className}__InfoText`} tabIndex={0}>
                <ul>
                  <li>
                    This covers accidental loss, damage or theft for personal
                    belongings usually carried inside or outside your home such
                    as clothing, jewellery and sports equipment.
                  </li>
                  <li>
                    The maximum we will pay for any one item is €1,500 so If the
                    value of any single item you own exceeds €1,500, you will
                    need to add it under the Specified All Risks Inside and
                    Outside the Home section.
                  </li>
                  <li>
                    You are responsible for paying the excess amount of €150 for
                    any claim under this section.
                  </li>
                  <li>
                    Note - some items will need to be Specified separately
                    regardless of their value.
                  </li>
                  <li>
                    Terms and Conditions apply. See your Policy documentation
                    for full details of cover{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="//personalbanking.bankofireland.com/insure-and-protect/insurance/home-insurance-quote/important-documents/"
                    >
                      here
                    </a>
                    .
                  </li>
                </ul>
              </div>
            </AccordionText>
          </label>
          <span className={`${className}__input`}>
            <div className={`${className}__switchContainer`}>
              <label
                htmlFor="unspecifiedItemsSwitch"
                className={`${commonFormStylesIdentifier}__fieldLabel ${commonFormStylesIdentifier}--withNoMargin`}
              />
              <SimpleSwitch
                name="unspecifiedItemsSwitch"
                onLabel="on"
                on={values['unspecifiedItemsSwitch']}
                onClick={() => {
                  this.updateFormValueAndYourCoverObject(
                    'unspecifiedItemsSwitch',
                    !values['unspecifiedItemsSwitch']
                  );
                  saveData(
                    'yourCoverObj',
                    'unspecifiedItemsSwitch',
                    !values['unspecifiedItemsSwitch']
                  );
                }}
                onBlur={handleBlur}
              />
            </div>
            {values.unspecifiedItemsSwitch && (
              <div
                className={`${commonFormStylesIdentifier}__dynamicFieldsContainer`}
              >
                <UnspecifiedItems
                  isFieldError={isFieldError}
                  handleChange={(e: SyntheticEvent<HTMLInputElement>) => {
                    this.updateFormValueAndYourCoverObject(
                      'unspecifiedItems',
                      e.currentTarget.value
                    );
                    saveData(
                      'yourCoverObj',
                      'unspecifiedItems',
                      e.currentTarget.value
                    );
                  }}
                  handleBlur={handleBlur}
                  value={values.unspecifiedItems}
                  errors={errors}
                />
              </div>
            )}
          </span>
        </div>
      );
    };

    const renderSpecifiedItems = () => {
      return (
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor="specifiedItemsSwitch"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            <AccordionText
              label="Add cover for All Risks inside and outside the home (e.g. Specified Valuables)"
              icon="info"
              iconAlign="right"
              customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
            >
              <div className={`${className}__InfoText`} tabIndex={0}>
                <ul className={`${className}__bulletPoints`}>
                  <li tabIndex={0}>
                    This covers accidental loss, damage or theft for Specified
                    personal belongings usually carried inside or outside your
                    home such as jewellery, mobiles phones, laptops and pedal
                    cycles.
                  </li>
                  <li tabIndex={0}>
                    You can select the amount that you want to insure each item
                    for. It is important that you insert a full description and
                    serial number (where available) of the item to be insured
                    (e.g. Gold 0.5ct Solitaire Diamond Ring).
                  </li>
                  <li tabIndex={0}>
                    For cover to be accepted on any item over €7,500, we may
                    require a professional valuation obtained within the last 18
                    months to be sent to us.
                  </li>
                  <li tabIndex={0}>
                    You are responsible for paying the excess amount of €150 for
                    any claim under All Risks.
                  </li>
                  <li tabIndex={0}>
                    Terms and Conditions apply. See your Policy documentation
                    for full details of cover{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="//personalbanking.bankofireland.com/insure-and-protect/insurance/home-insurance-quote/important-documents/"
                    >
                      here
                    </a>
                    .
                  </li>
                </ul>
              </div>
            </AccordionText>
          </label>
          <span className={`${className}__input`}>
            <div className={`${className}__switchContainer`}>
              <label
                htmlFor="specifiedItemsSwitch"
                className={`${commonFormStylesIdentifier}__fieldLabel ${commonFormStylesIdentifier}--withNoMargin`}
              />
              <SimpleSwitch
                name="specifiedItemsSwitch"
                onLabel="on"
                on={values['specifiedItemsSwitch']}
                onClick={() => {
                  this.updateFormValueAndYourCoverObject(
                    'specifiedItemsSwitch',
                    !values['specifiedItemsSwitch']
                  );
                  saveData(
                    'yourCoverObj',
                    'specifiedItemsSwitch',
                    !values['specifiedItemsSwitch']
                  );
                }}
                onBlur={handleBlur}
              />
            </div>
            {values['specifiedItemsSwitch'] && (
              <div
                className={`${commonFormStylesIdentifier}__dynamicFieldsContainer`}
              >
                <SpecifiedItems
                  errors={errors}
                  handleItemsChange={(field: string, value: any) => {
                    this.updateFormValueAndYourCoverObject(field, value);
                    saveData('yourCoverObj', field, value);
                  }}
                  values={values}
                  setFieldValue={setFieldValue}
                  setTouched={setTouched}
                  touched={touched.specifiedItems}
                />
              </div>
            )}
          </span>
        </div>
      );
    };

    const checkCoverType = () => {
      let result;
      result = !(
        this?.props?.yourHomeObj?.coverType === HOME_COVER_TYPE.OWNUNOCCUPIED ||
        this?.props?.yourHomeObj?.coverType === HOME_COVER_TYPE.OWNCONSTRUCTION
      );
      if (!result) {
        values.contentsCoverOf = '';
      }
      return result;
    };
    const checkSpecifiedAndUnspecifiedCoverType = () => {
      return (
        this.props.yourHomeObj.coverType === HOME_COVER_TYPE.OWN_LIVE ||
        this.props.yourHomeObj.coverType === HOME_COVER_TYPE.RENT
      );
    };

    const setContentsDropdownValue = (value: string) => {
      const value1 = parseFloat(value.replace(/,/g, ''));
      const filteredOption = this.getAllOptions().filter((option: any) => {
        return option.value <= value1;
      });
      this.setState({ selectedOptions: filteredOption });
    };

    return (
      <div className={`${className}__container`}>
        <Form>
          <div
            className={`${commonFormStylesIdentifier}__nonDynamicFieldsContainer`}
          >
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="policyStart"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                Policy start date
              </label>
              <DatePicker
                error={errors.policyStart}
                name="policyStart"
                onBlur={handleBlur}
                onChange={(e: SyntheticEvent<HTMLInputElement>) => {
                  this.updateFormValueAndYourCoverObject(
                    'policyStart',
                    e.currentTarget.value
                  );
                  saveData(
                    'yourCoverObj',
                    'policyStart',
                    e.currentTarget.value
                  );
                }}
                placeholder="Type here"
                touched={touched.policyStart}
                value={values.policyStart}
                minDate={getCurrentDate()}
                maxDate={addDaysToDate(getCurrentDate(), 90)}
              />
            </div>
            {showRebuildCost ? (
              <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
                <label
                  htmlFor="rebuildCost"
                  className={`${commonFormStylesIdentifier}__fieldLabel`}
                  data-testid={`${className}__rebuildCost`}
                >
                  <AccordionText
                    label="How much would it cost to rebuild your home?"
                    secondLineLabel="The minimum rebuilding cost for homes in Dublin is €175,000 and for all other areas is €130,000."
                    icon="info"
                    iconAlign="right"
                    customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
                  >
                    <div className={`${className}__InfoText`} tabIndex={0}>
                      This is the cost to completely rebuild your property and
                      domestic outbuildings as new, including permanent fixtures
                      and fittings, site clearance, architect and surveyor fees.
                      Please note this is not the market value of your home.
                      <br />
                      You can use the Society of Chartered Surveyors Calculator
                      for guidance{' '}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="//scsi.ie"
                      >
                        here
                      </a>
                      , if you are unsure of the rebuild cost of your property.
                      <br />
                      <b>Important!</b>
                      <br />
                      If your Buildings Sum Insured is too low, we may only pay
                      a proportion of your claim so you must select this
                      carefully
                    </div>
                  </AccordionText>
                </label>
                <span className={`${className}__input`}>
                  <InputField
                    data-testid={`${className}__rebuildCost`}
                    error={
                      errors['rebuildCost'] !== undefined &&
                      touched['rebuildCost']
                    }
                    placeholder="Enter an amount"
                    errorMessage={errors['rebuildCost']}
                    prefix={faEuroSign}
                    name="rebuildCost"
                    value={values.rebuildCost}
                    onBlur={(event: SyntheticEvent<HTMLInputElement>) => {
                      setFieldValue('rebuildCost', event.currentTarget.value);
                      this.handleCustomBlur('rebuildCost', event);
                    }}
                    masked
                    onChange={(event: SyntheticEvent<HTMLInputElement>) => {
                      handleChange(event);
                      setContentsDropdownValue(event.currentTarget.value);
                      saveData(
                        'yourCoverObj',
                        'rebuildCost',
                        event.currentTarget.value
                      );
                    }}
                    mask={decimalNumberMask}
                  />
                </span>
              </div>
            ) : null}
            {checkCoverType() ? (
              <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
                <label
                  htmlFor="contentsCoverOf"
                  className={`${commonFormStylesIdentifier}__fieldLabel`}
                >
                  <AccordionText
                    label="What is the value of the contents of this property?"
                    secondLineLabel="This is typically less than your rebuild cost for your home"
                    icon="info"
                    iconAlign="right"
                    customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
                  >
                    <div className={`${className}__InfoText`} tabIndex={0}>
                      This should cover all household goods stored within the
                      Property owned by You or any member of Your Household or
                      for which they are responsible including Home Office
                      Equipment, Valuables, clothing, Carpets, Personal Effects
                      and Money. Please note permanent fittings such as fitted
                      kitchens or fitted wooden flooring fall under your
                      buildings sum insured.
                      <br />
                      <b>Important!</b>
                      <br />
                      If your Contents Sum Insured is too low, we may only pay a
                      proportion of your claim so you must select this
                      carefully.
                    </div>
                  </AccordionText>
                </label>
                <span className={`${className}__input`}>
                  <Dropdown
                    error={errors['contentsCoverOf'] !== undefined}
                    errorMessage={errors['contentsCoverOf']}
                    name="contentsCoverOf"
                    value={values.contentsCoverOf}
                    onChange={(event: SyntheticEvent<HTMLSelectElement>) => {
                      handleChange(event);
                      setFieldValue(
                        'contentsCoverOf',
                        event.currentTarget.value
                      );
                      this.updateFormValueAndYourCoverObject(
                        'contentsCoverOf',
                        event.currentTarget.value
                      );
                      saveData(
                        'yourCoverObj',
                        'contentsCoverOf',
                        event.currentTarget.value
                      );
                    }}
                    onBlur={(event: SyntheticEvent<HTMLInputElement>) => {
                      this.handleCustomBlur('contentsCoverOf', event);
                    }}
                  >
                    <option value={''}>Select</option>
                    {this.state.selectedOptions.map(
                      (option: any, index: number) => (
                        <option key={index} value={option.value}>
                          {index === this.state.selectedOptions.length - 1
                            ? option.label + '+'
                            : option.label}
                        </option>
                      )
                    )}
                  </Dropdown>
                </span>
              </div>
            ) : null}
            {checkSpecifiedAndUnspecifiedCoverType() ? (
              <>
                <div
                  className={`${commonFormStylesIdentifier}__fieldContainer ${commonFormStylesIdentifier}--withNoMargin ${commonFormStylesIdentifier}--withMarginTop`}
                >
                  <label
                    className={`${commonFormStylesIdentifier}__fieldLabel`}
                  >
                    You do not have to add cover for unspecified or specified
                    items in order to take out a policy.
                  </label>
                </div>
                {renderUnspecifiedItems()}
                {renderSpecifiedItems()}
              </>
            ) : null}
            {marketingConsentInForm && <MarketingConsent {...this.props} />}
          </div>
        </Form>
      </div>
    );
  }
}

const FormikYourCoverForm = withFormik<Props, YourCoverFormValues>({
  mapPropsToValues(): {} {
    let initialValues = {
      policyStart: '',
      rebuildCost: '',
      contentsCoverOf: '',
      coverTypeValidation: '',
      homeTypeValidation: '',
      unspecifiedItems: '',
      checkRebuildCost: '',
      specifiedItems: [
        {
          item: '',
          value: '',
          description: ''
        }
      ],
      unspecifiedItemsSwitch: '',
      specifiedItemsSwitch: '',
      county: '',
      marketingConsent: '',
      marketingConsentEmail: '',
      marketingConsentPost: '',
      marketingConsentPhone: '',
      marketingConsentText: '',
      consent: '',
      getInTouch: ''
    };
    const valuesObject = {};
    Object.keys(initialValues).forEach((key: string) => {
      valuesObject[key] = initialValues[key];
    });
    return valuesObject;
  },
  handleSubmit(values: YourCoverFormValues, { setSubmitting }: FormikProps) {
    setSubmitting(false);
  },
  isInitialValid: (props: Props) => props.yourCoverValid,
  validationSchema: yup.object().shape(YourCoverFormYupSchema),
  displayName: 'YourCoverForm'
})(YourCoverForm);

export default FormikYourCoverForm;
