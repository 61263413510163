import '../../../pages/GetQuotePage/GetQuotePage.scss';

import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { CognitoContext } from '../../../CognitoUtils';
import { HOME_QUOTE_THANK_YOU_STORAGE } from '../../../constants';
import { HOME_INSURANCE_TYPE } from '../../../constants/insuranceTypeConstants';
import { HOME_LATEST_QUOTE_STORAGE } from '../../../constants/sessionStorage/homeStorageIdentifiers';
import { AGENT_SELECTED_QUOTE } from '../../../constants/sessionStorage/userStorageIdentifiers';
import {
  getAccessToken,
  getObjectFromSessionStorage,
  isAgent,
  rawToIrishFormat,
  saveObjectInSessionStorage
} from '../../../helpers';
import { cognitoAttributeFieldMappings } from '../../../helpers/CognitorHelper';
import {
  formatResponse,
  getLatestUnpurchasedHomeQuote
} from '../../../services/latestHomeQuoteService';
import { getUserProfile } from '../../../services/profile';
import { retrieveTemporaryQuote, storeTemporaryQuote } from '../../../services/temporaryQuoteService';
import { LogOutEvent, useSub } from '../../../utils/pubSub';
import AnimatedSpinner from '../../AnimatedSpinner/AnimatedSpinner';
import AboutYouComponent from './AboutYouComponent';

const getEmploymentStatusCode = occupationCode => {
  if (['OCC143', 'OCC234', 'OCC260', 'SEF', 'UAC'].includes(occupationCode))
    return occupationCode;
  return occupationCode ? 'EMP' : '';
};
const getOrEmpty = fieldValue => fieldValue || '';

const getAddressFieldValue = (address, fieldName) =>
  address && address[fieldName] ? address[fieldName] : '';

const getDisplayAddressValue = addressValues => {
  return ['address_line1', 'address_line2', 'address_line3', 'county']
    .map(addressField => getAddressFieldValue(addressValues, addressField))
    .filter(e => !!e)
    .join(', ');
};

export const mapAboutYouProfileValues = values => ({
  title: getOrEmpty(values.title),
  otherTitle: getOrEmpty(values.otherTitle),
  firstName: getOrEmpty(values.first_name),
  lastName: getOrEmpty(values.last_name),
  email: getOrEmpty(values.email),
  displayAddress: getDisplayAddressValue(values.address),
  addressLine1: getAddressFieldValue(values.address, 'address_line1'),
  addressLine2: getAddressFieldValue(values.address, 'address_line2'),
  addressLine3: getAddressFieldValue(values.address, 'address_line3'),
  town: getAddressFieldValue(values.address, 'town'),
  county: getAddressFieldValue(values.address, 'county'),
  eircode: getAddressFieldValue(values.address, 'eircode'),
  phoneNo: getOrEmpty(values.phone),
  dob: rawToIrishFormat(getOrEmpty(values.date_of_birth)),
  employmentStatus: getEmploymentStatusCode(values.occupation),
  mainOccupation: values.occupation,
  promoCode: getOrEmpty(values.promotion_code),
  staffNumber: getOrEmpty(values.staff_number),
  policyLength: values.policies?.length
});

const AboutYouFormWrapper = props => {
  const className = 'c-GetQuotePage';
  const [quoteDetails, setQuoteDetails] = useState(null);
  const { cognitoUser } = useContext(CognitoContext);

  const setCognitoAttributes = (profile = {}) => {
    const cognitorUserValues = {};
    if (cognitoUser && cognitoUser.attributes) {
      Object.keys(cognitoUser.attributes).forEach(key => {
        const cognitoAttributeField = cognitoAttributeFieldMappings[key];
        if (
          cognitoAttributeField &&
          !cognitorUserValues[cognitoAttributeField]
        ) {
          cognitorUserValues[cognitoAttributeFieldMappings[key]] =
            cognitoUser.attributes[key];
        }
      });
      const aboutYouValues = profile.isDefaultResponse
        ? { ...profile, ...cognitorUserValues }
        : cognitorUserValues;
      setQuoteDetails({ aboutYou: aboutYouValues });
    }
  };

  const saveLatestQuote = quote => {
    saveObjectInSessionStorage(`${HOME_LATEST_QUOTE_STORAGE}`, quote);
  };

  const getTemporaryQuote = () =>
    retrieveTemporaryQuote(HOME_INSURANCE_TYPE)
      .then(temporaryLocalQuote => temporaryLocalQuote
        ? {
          aboutYou: temporaryLocalQuote.aboutYouObj,
          yourHome: temporaryLocalQuote.yourHomeObj,
          yourCover: temporaryLocalQuote.yourCoverObj
        } : undefined);

  const getAgentQuote = () => {
    const agentSelectedQuote = getObjectFromSessionStorage(AGENT_SELECTED_QUOTE);
    return agentSelectedQuote && Object.keys(agentSelectedQuote).length > 0
      ? formatResponse(agentSelectedQuote.home_quote)
      : undefined;
  };

  const getLatestUnpurchasedQuote = () => {
    const accessToken = getAccessToken(cognitoUser);
    if (accessToken) {
      return getLatestUnpurchasedHomeQuote(props)
        .then(latestHomeQuote => {
          if (Object.keys(latestHomeQuote).length === 0) {
            getUserProfile().then(res => {
              if (!res || res.isDefaultResponse) {
                // If no profile or the default response is returned then the user has no profile
                setCognitoAttributes(res);
                return Promise.resolve(undefined);
              }
              return {
                aboutYou: mapAboutYouProfileValues(res)
              };
            });
          } else {
            return latestHomeQuote;
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
    return Promise.resolve(undefined);
  };

  useEffect(() => {
    let isMounted = true;
    if (!quoteDetails && cognitoUser) {
      const loadQuote = isAgent()
        ? Promise.resolve(getAgentQuote())
        : getTemporaryQuote().then(temporaryQuote =>
          (isMounted && temporaryQuote)
            ? temporaryQuote
            : getLatestUnpurchasedQuote()
        );
      loadQuote.then(maybeQuote => {
        const quote = maybeQuote || {};
        if (isMounted) {
          setQuoteDetails(quote);
          saveLatestQuote(quote);
        }
      });
    } else {
      setQuoteDetails({});
      saveLatestQuote({});
    }
    return () => {
      isMounted = false;
    };

  }, []);

  useSub(LogOutEvent, () => {
    const quoteData = getObjectFromSessionStorage(HOME_QUOTE_THANK_YOU_STORAGE);
    storeTemporaryQuote(HOME_INSURANCE_TYPE, quoteData);
  });

  return quoteDetails ? (
    <AboutYouComponent latestQuote={quoteDetails} history={props.history} />
  ) : (
    <div className={`${className}__spinnerContainer`}>
      <AnimatedSpinner size="large" />
    </div>
  );
};

AboutYouFormWrapper.propTypes = {
  history: PropTypes.any
};

export default AboutYouFormWrapper;
