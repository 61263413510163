/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { CognitoContext } from './CognitoUtils';
import App from './components/App';
import LoadUserRoute from './components/LoadUserRoute';
import PrivateRoute from './components/PrivateRoute';
import { AGENT, PUBLIC } from './constants/agent';
import { isAgent } from './helpers/AgentCheck';
import MotorHoldingPage from './pages/MotorHoldingPage/MotorHoldingPage';
import { routesConfig } from './routes/routesConfig';
import type { CognitoContextType, FeatureFlags } from './types/commonTypes';

export interface BOIRoute {
  url: string;
  showInMenu: boolean;
  displayBack: boolean;
}

const maintenanceRoutes = (featureFlags: ?FeatureFlags) =>
  featureFlags?.motor_enabled
    ? undefined
    : [<Route key={0} exact path="**/car" component={MotorHoldingPage} />];

// flow is getting removed
// eslint-disable-next-line flowtype/no-weak-types
const getLoadUserRoute = (route: string, Component: any, index: number) => (
  <LoadUserRoute
    key={`loadUserRoute_${index}`}
    exact
    path={route}
    component={Component}
  />
);

// flow is getting removed
// eslint-disable-next-line flowtype/no-weak-types
const getPrivateRoute = (route: string, Component: any, index: number) => (
  <PrivateRoute
    key={`privateRoute_${index}`}
    exact
    path={route}
    component={Component}
  />
);

// flow is getting removed
// eslint-disable-next-line flowtype/no-weak-types
const getRoute = (
  route: string,
  Component?: any,
  render?: any,
  index: number
) =>
  render ? (
    <Route exact path={route} render={render} key={`route_${index}`} />
  ) : (
    <Route exact path={route} component={Component} key={`route_${index}`} />
  );

const { loadUserRoutes, privateRoutes, routes } = routesConfig[
  isAgent() ? AGENT : PUBLIC
];

export const BOIRoutes = () => {
  const user: ?CognitoContextType = useContext(CognitoContext);

  return (
  <Switch>
    <Route exact path="/">
      <Redirect to="/wallet" />
    </Route>
    <Route>
      {() => (
        <App>
          <Switch>
            {maintenanceRoutes(user?.feature?.flags)}
            {loadUserRoutes.map(
              (route: { path: string, component: any }, index: number) =>
                getLoadUserRoute(route.path, route.component, index)
            )}
            {privateRoutes.map(
              (route: { path: string, component: any }, index: number) =>
                getPrivateRoute(route.path, route.component, index)
            )}
            {routes.map(
              (
                route: { path: string, component?: any, render?: any },
                index: number
              ) => getRoute(route.path, route.component, route.render, index)
            )}
          </Switch>
        </App>
      )}
    </Route>
  </Switch>
)};
