/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../styles/commonPageStyles.scss';
import '../../styles/commonFormStyles.scss';
import './MotorViewQuote.scss';

import Auth from '@aws-amplify/auth';
import { Text, TitleWithUnderLine } from '@boi/core/lib';
import { Form, FormikProps, withFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import type { Location, RouterHistory } from 'react-router-dom';

import { config, customer_config } from '../../amplify.config';
import { CognitoContext } from '../../CognitoUtils';
import { MobileBackButton } from '../../components/MobileBackButton';
import { MotorPremiumBreakdown } from '../../components/PremiumBreakdowns';
import ProgressBar from '../../components/ProgressBar';
import QuoteDisplay from '../../components/QuoteDisplay';
import QuoteInfo from '../../components/QuoteInfo';
import {
  commonPageStylesIdentifier,
  DIRECT_DEBIT_STORAGE,
  GET_QUOTE_VALUES_STORAGE,
  RECALCULATED_QUOTE_RESPONSE_STORAGE,
  RECALCULATED_QUOTE_VALUES_STORAGE,
  REQUEST_FAILURE,
  REQUEST_LOADING,
  REQUEST_SUCCESS,
  SELECTED_MOTOR_QUOTE_STORAGE
} from '../../constants';
import { FORCE_LOGIN } from '../../constants/home';
import { CAR_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import {
  JOURNEY_YOUR_QUOTE,
  MOTOR_TRAVEL_JOURNEY,
  STAGES_MOTOR_TRAVEL_JOURNEY
} from '../../constants/journey';
import { POPULAR_COVER, PRESTIGE_COVER } from '../../constants/motor';
import {
  getObjectFromSessionStorage,
  isAgent,
  removeFromSessionStorage,
  saveInSessionStorage} from '../../helpers';
import { mapMotorQuote } from '../../mappings/motor/MapMotorQuote';
import { routes } from '../../routes';
import { postMotorQuote } from '../../services/motor/motorServices';
import { getUsers } from '../../services/retriveUser';
import type { MotorPayloadType,PayloadType } from '../../types';
import type {
  AboutYouFormType,
  MotorQuoteResponseType,
  MotorQuoteType,
  MotorViewQuoteTypes,
  YourCarCoverFormTypes,
  YourCarFormTypes
} from '../../types';
import { type RequestStateType } from '../../types';
import ViewQuoteLogo from '../ViewQuote/ViewQuoteLogo';
import {
  getShowUpToFeatureNumber,
  listOfFeatures
} from './ListOfMotorFeatures';
import MotorQuoteExcess from './MotorQuoteExcess';
import { motorViewQuoteFormInitialValues } from './motorViewQuoteFormInitialValues.js';

type MotorQuoteValues = AboutYouFormType & YourCarFormTypes & YourCarCoverFormTypes;

interface Props {
  location: Location;
  history: RouterHistory;
}

export const getBreakdownValuesFromQuoteModel = (
  motorQuoteModel: MotorQuoteResponseType,
  levelOfCover: string = ''
) => {
  return (motorQuoteModel || {})[levelOfCover] ? motorQuoteModel[levelOfCover] : {};
};

const MotorViewQuote = (props: Props & FormikProps<MotorViewQuoteTypes>) => {
  const {
    handleBlur,
    isValid,
    errors,
    values,
    history,
    setFieldValue,
    setFieldTouched,
    handleChange,
    touched
  } = props;
  const className = 'c-MotorViewQuote';

  const insuranceType = props.match.params.type || '';
  const FULL_DIRECT_DEBIT_STORAGE_IDENTIFIER = `${insuranceType}${DIRECT_DEBIT_STORAGE}`;
  const [carInsuranceType, setCarInsuranceType] = useState('');
  const [insuranceLevelOfCover, setInsuranceLevelOfCover] = useState('');
  const [quoteReference, setQuoteReference] = useState(0);
  const [price, setPrice] = useState(0);
  const [carRegistration, setCarRegistration] = useState('');
  const [premiumBreakdownValues, setPremiumBreakdownValues] = useState<?MotorQuoteValues>();
  const [buttonType, setButtonType] = useState('proceed');
  const [premiumDetails, setPremiumDetails] = useState({});
  const [quoteValues, setQuoteValues] = useState<?MotorQuoteValues>(undefined);
  const [requestStatus, setRequestStatus] = useState<RequestStateType>(
    REQUEST_LOADING
  );
  const { feature, cognitoUser } = useContext(CognitoContext);

  const bindingQuote = getObjectFromSessionStorage(`${insuranceType}Quote`);
  let payload: PayloadType = {};

  useEffect(() => {
    const payload = {
      ...getObjectFromSessionStorage(SELECTED_MOTOR_QUOTE_STORAGE),
      ...getObjectFromSessionStorage(RECALCULATED_QUOTE_RESPONSE_STORAGE)
    };
    const motorQuoteValues = {
      ...getObjectFromSessionStorage(
        `${insuranceType}${GET_QUOTE_VALUES_STORAGE}`
      ),
      ...getObjectFromSessionStorage(
        `${insuranceType}${RECALCULATED_QUOTE_VALUES_STORAGE}`
      )
    };
    setInsuranceLevelOfCover(payload.selectedQuoteType);
    if (payload.response) {
      setQuoteReferenceFromState(payload.response);
      setPriceFromState(payload.response, payload.selectedQuoteType);
      setPremiumDetails(
        getBreakdownValuesFromQuoteModel(
          payload.response,
          payload.selectedQuoteType
        )
      );
      setupMotorQuote(motorQuoteValues);
    }
  }, [props.location]);

  removeFromSessionStorage(FULL_DIRECT_DEBIT_STORAGE_IDENTIFIER);

  const setPriceFromState = (
    motorQuoteModel: MotorQuoteResponseType,
    insuranceLevelOfCovered: string
  ) => {
    let price = 0;
    if (insuranceLevelOfCovered === POPULAR_COVER) {
      price = getQuoteTotalAmount(motorQuoteModel.popular);
    } else if (insuranceLevelOfCovered === PRESTIGE_COVER) {
      price = getQuoteTotalAmount(motorQuoteModel.prestige);
    }
    setPrice(Number(price));
  };

  const setupMotorQuote = (
    motorQuoteValues: MotorQuoteValues
  ) => {
    setCarInsuranceType(motorQuoteValues.coverType || '');
    setCarRegistration(motorQuoteValues.carReg || '');
    setQuoteValues(motorQuoteValues);
    setPremiumBreakdownValues(motorQuoteValues);
    Object.entries(motorQuoteValues).forEach(
      ([key, value]: [string, mixed]) => {
        setFieldValue(key, value);
      }
    );
  };

  const setQuoteReferenceFromState = (
    motorQuoteModel: MotorQuoteResponseType
  ) => {
    motorQuoteModel
      ? setQuoteReference(motorQuoteModel.quote_reference)
      : setQuoteReference(0);
  };

  const getQuoteTotalAmount = (motorQuote: MotorQuoteType = {}) => {
    return motorQuote.total_amount || 0;
  };

  const handleChangeWithRecalculate = (
    fieldName: string,
    value: string | number
  ) => {
    setButtonType('recalculate');
    setFieldValue(fieldName, value);
  };

  const checkResponse = (payload: MotorPayloadType) => {
    if (payload.response?.acceptance_notifications) {
      setRequestStatus(REQUEST_FAILURE);
      history.push(`${routes.genericErrorPage.url}${CAR_INSURANCE_TYPE}`);
    } else if (payload.response?.quote_reference) {
      setQuoteReferenceFromState(payload.response);
      setPriceFromState(payload.response, insuranceLevelOfCover);
      saveInSessionStorage(
        SELECTED_MOTOR_QUOTE_STORAGE,
        JSON.stringify({
          ...payload,
          selectedQuoteType: insuranceLevelOfCover
        })
      );
      setButtonType('proceed');
      setRequestStatus(REQUEST_SUCCESS);
      setPremiumDetails(
        getBreakdownValuesFromQuoteModel(
          payload.response,
          insuranceLevelOfCover
        )
      );
      setPremiumBreakdownValues(values);
    }
  };

  const recalculateMotorQuote = async () => {
    if (values) {
      setRequestStatus(REQUEST_LOADING);
      const newMotorQuoteValues = { ...quoteValues, ...values };
      // Save Motor Quote Values to storage even if the call fails
      saveInSessionStorage(
        `${insuranceType}${RECALCULATED_QUOTE_VALUES_STORAGE}`,
        JSON.stringify(newMotorQuoteValues)
      );
      const newMappingsData = mapMotorQuote(newMotorQuoteValues);
      payload = await postMotorQuote(newMappingsData, props);
      if (payload.response) {
        checkResponse(payload);
      } else {
        setRequestStatus(REQUEST_FAILURE);
        history.push(`${routes.genericErrorPage.url}${CAR_INSURANCE_TYPE}`);
      }
    }
  };

  const journey = (
    quoteData: any,
    userExistOrLoggedIn: any,
    isAgent: boolean
  ) => {
    if (isAgent) {
      Auth.configure(config);
    }
    if (userExistOrLoggedIn) {
      history.push({
        pathname: `${routes.makePayment.url}${CAR_INSURANCE_TYPE}`,
        state: quoteData
      });
    } else {
      saveInSessionStorage(
        FORCE_LOGIN,
        JSON.stringify({ forceLogIn: true, insuranceType: insuranceType })
      );
      history.push(
        !isAgent
          ? `${routes.forceLogin.url}${insuranceType}`
          : `${routes.signUp.url}`
      );
    }
  };

  const goToNextPage = (quoteData: any) => {
    if (isAgent()) {
      Auth.configure(customer_config);
      getUsers({
        dropdownValue: 'email',
        searchInput: bindingQuote?.aboutYouObj?.email || ''
      })
        .then((res: any) => {
          if (res?.user_list?.length > 0) {
            journey(quoteData, true, true);
          } else {
            journey(quoteData, false, true);
          }
        })
        .catch((e: any) => {
          console.error(e);
        });
    } else {
      journey(quoteData, cognitoUser, false);
    }
  };

  const onProceedClicked = () => {
    const quoteData = {
      price: price,
      quoteReference: quoteReference
    };
    saveInSessionStorage(
      FULL_DIRECT_DEBIT_STORAGE_IDENTIFIER,
      JSON.stringify(quoteData)
    );
    feature.flags?.marketing_enabled
      ? history.push({
          pathname: `${routes.marketingConsent.url}${CAR_INSURANCE_TYPE}`,
          state: quoteData
        })
      : goToNextPage(quoteData);
  };

  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      <ProgressBar
        stage={JOURNEY_YOUR_QUOTE}
        stages={STAGES_MOTOR_TRAVEL_JOURNEY}
        labelText={MOTOR_TRAVEL_JOURNEY}
      />
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <TitleWithUnderLine>Your Quotes</TitleWithUnderLine>
      </div>
      <Text size="inherit" className={`${className}__subHeading`}>
        for {carRegistration}
      </Text>
      <div className={`${className}__card`}>
        <div className={`${className}__contentContainer`}>
          <div
            className={`${className}__quoteInfoContainer ${className}__paddedContainer`}
          >
            <div className={`${className}__logo`}>
              <ViewQuoteLogo />
            </div>
            <QuoteInfo
              quoteFeatures={listOfFeatures(
                carInsuranceType,
                insuranceLevelOfCover
              )}
              showUpToFeature={getShowUpToFeatureNumber(
                insuranceLevelOfCover,
                carInsuranceType
              )}
              showReadMoreOption={true}
            />
          </div>
          <Form>
            <MotorQuoteExcess
              carInsuranceType={carInsuranceType}
              insuranceLevelOfCover={insuranceLevelOfCover}
              values={values}
              handleBlur={handleBlur}
              handleChangeWithRecalculate={handleChangeWithRecalculate}
              setFieldTouched={setFieldTouched}
              errors={errors}
              handleChange={handleChange}
              touched={touched}
            />
          </Form>
        </div>
        <div className={`${className}__contentContainer`}>
          <div className={`${className}__paddedContainer`}>
            <MotorPremiumBreakdown
              premiumDetails={premiumDetails}
              values={premiumBreakdownValues}
            />
          </div>
        </div>
        <div className={`${className}__contentContainer`}>
          <div className={`${className}__beforeText`}>
            <h3>Before You Proceed</h3>
            <p>
              {
                'You are responsible for confirming your eligibility for our cover and that the cover you have chosen meets your particular needs.'
              }
              <br />
              {
                'This quote is underwritten exclusively by RSA Insurance Ireland DAC. Quotes are valid for today only provided all information is correct and does not change.'
              }
            </p>
          </div>
        </div>
      </div>
      <QuoteDisplay
        price={price}
        sticky
        disabled={!isValid}
        buttonType={buttonType}
        onClickFunction={onProceedClicked}
        recalculate={recalculateMotorQuote}
        callStatus={requestStatus}
        title="Annual Premium"
        insurerType={null}
      />
    </div>
  );
};

const initialFormValues = {
  ...motorViewQuoteFormInitialValues,
  ...getObjectFromSessionStorage(
    `${CAR_INSURANCE_TYPE}${RECALCULATED_QUOTE_VALUES_STORAGE}`
  )
};

const FormikMotorViewQuote = withFormik<Props, MotorViewQuoteTypes>({
  mapPropsToValues: () => initialFormValues,
  handleSubmit(values: MotorViewQuoteTypes, { setSubmitting }: FormikProps) {
    setSubmitting(false);
  },
  displayName: 'ViewQuote'
})(MotorViewQuote);

export default FormikMotorViewQuote;
