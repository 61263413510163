"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

require("./SimpleSwitch.scss");

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utils = require("../../utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SimpleSwitch = function SimpleSwitch(_ref) {
  var on = _ref.on,
      onClick = _ref.onClick,
      name = _ref.name,
      isYesNoQuestion = _ref.isYesNoQuestion;
  var className = 'c-SimpleSwitch';
  var activeClassNames = [className, on ? "".concat(className, "--on") : "".concat(className, "--off")].join(' ');

  var getContent = function getContent() {
    if (isYesNoQuestion) return on ? 'Yes' : 'No';else return on ? 'On' : 'Off';
  };

  var triggerOnKey = function triggerOnKey(e) {
    return 'Enter' === e.key && onClick();
  }; // Test instructions: click on button, inpsect input


  return _react2.default.createElement(_react.Fragment, null, _react2.default.createElement("input", {
    className: "".concat(className, "__input"),
    "data-testid": "".concat(className, "__input$").concat(name),
    type: "checkbox",
    name: name,
    id: name,
    checked: on,
    readOnly: true
  }), _react2.default.createElement("span", {
    onClick: onClick,
    className: activeClassNames,
    "data-testid": "".concat(className, "$").concat(name),
    id: "".concat(name, "SwitchButton"),
    "aria-label": "Toggle"
  }, _react2.default.createElement("span", {
    className: "".concat(className, "__content"),
    tabIndex: 0,
    onKeyDown: triggerOnKey
  }, getContent())));
};

SimpleSwitch.defaultProps = {
  onClick: _utils.noop,
  on: false
};
exports.default = SimpleSwitch;