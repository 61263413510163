/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './YourCarForm.scss';
import '../../../styles/commonFormStyles.scss';

import { AccordionText, Dropdown, InputField } from '@boi/core/lib';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { FormikProps } from 'formik';
import React from 'react';

import { commonFormStylesIdentifier } from '../../../constants';
import carPurchasedValues from '../../../constants/dropdowns/carPurchasedValues';
import carUse from '../../../constants/dropdowns/carUse';
import drivingExperienceValues from '../../../constants/dropdowns/drivingExperienceValues';
import kmPerYearValues from '../../../constants/dropdowns/kmPerYearValues';
import noClaimsBonusValues from '../../../constants/dropdowns/noClaimsBonusValues';
import { isFieldError, mapDropdownOptions } from '../../../helpers';
import { decimalNumberMask } from '../../../helpers/NumberMask';
import type { RouterPropsType, YourCarFormTypes } from '../../../types';
import CarRegLookup from '../../CarRegLookup';

const className = 'c-YourCarForm';
const YourCarForm = (props: RouterPropsType & FormikProps<YourCarFormTypes>) => {

  const { errors, handleBlur, handleChange, setFieldValue, setFieldTouched, touched, values, history } = props;

  return (
    <div className={`${className}__container`}>
      <CarRegLookup
        values={values}
        name="carReg"
        errors={errors}
        errorMessage={errors.carReg}
        placeholder="Type here"
        onChange={handleChange}
        touched={touched}
        onBlur={handleBlur}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        isFieldError={isFieldError}
        history={history}
      />
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor="carPurchased" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          {`How long have you owned the vehicle you're insuring?`}
        </label>
        <Dropdown
          error={isFieldError('carPurchased', touched, errors)}
          errorMessage={errors.carPurchased}
          touched={touched}
          onBlur={handleBlur}
          placeholder="Select here"
          name="carPurchased"
          value={values.carPurchased}
          onChange={handleChange}
        >
          <option value="">Select here</option>
          {mapDropdownOptions(carPurchasedValues, 'car_purchased_values')}
        </Dropdown>
      </div>

      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor='vehicleValue' className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Vehicle value
        </label>
        <InputField
          error={isFieldError('vehicleValue', touched, errors)}
          errorMessage={errors.vehicleValue}
          touched={touched}
          onBlur={handleBlur}
          placeholder="Enter an amount"
          prefix={faEuroSign}
          name="vehicleValue"
          value={values.vehicleValue}
          onChange={handleChange}
          masked
          mask={decimalNumberMask}
        />
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor="carUsedForWork" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          As well as personal use, how else do you use your car?
        </label>
        <Dropdown
          error={isFieldError('carUsedForWork', touched, errors)}
          errorMessage={errors.carUsedForWork}
          touched={touched}
          onBlur={handleBlur}
          placeholder="Select here"
          name="carUsedForWork"
          value={values.carUsedForWork}
          onChange={handleChange}
        >
          <option value="">Select here</option>
          {mapDropdownOptions(carUse, 'car_uses')}
        </Dropdown>
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor="annualKm" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Annual km
        </label>
        <Dropdown
          error={isFieldError('annualKm', touched, errors)}
          errorMessage={errors.annualKm}
          touched={touched}
          onBlur={handleBlur}
          placeholder="Select here"
          name="annualKm"
          value={values.annualKm}
          onChange={handleChange}
        >
          <option value="">Select here</option>
          {mapDropdownOptions(kmPerYearValues, 'km_per_year')}
        </Dropdown>
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor="drivingExpType" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Tell us about your current driving experience?
        </label>
        <Dropdown
          error={isFieldError('drivingExpType', touched, errors)}
          errorMessage={errors.drivingExpType}
          touched={touched}
          onBlur={handleBlur}
          placeholder="Select here"
          name="drivingExpType"
          value={values.drivingExpType}
          onChange={handleChange}
        >
          <option value="">Select here</option>
          {mapDropdownOptions(drivingExperienceValues, 'dropdownValue')}
        </Dropdown>
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainerWithNoMargin`}>
        <label htmlFor="noClaimsBonus" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          <AccordionText
            label="How many consecutive years have you been driving claims free?"
            icon="info"
            iconAlign="right"
            customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
          >
            <div className={`${className}__readMore`} tabIndex={0}>
              If you hold an active policy or previously held a policy in your own name you may be entitled to a No
              Claims
              Discount (NCD) based on the number of years you have been claim free. If you have been a named driver on
              someone {'else\'s'} policy you may be entitled to a discount based on your driving history. Simply enter the
              number of years claims free on your proof of No Claims Bonus (if {'you\'ve'} been insured in your own name) or
              the number of years that {'you\'ve'} been named, claims-free, on someone {'else\'s'} policy.
            </div>
          </AccordionText>
        </label>
        <Dropdown
          error={isFieldError('noClaimsBonus', touched, errors)}
          errorMessage={errors.noClaimsBonus}
          touched={touched}
          onBlur={handleBlur}
          placeholder="Type here"
          name="noClaimsBonus"
          value={values.noClaimsBonus}
          onChange={handleChange}
        >
          <option value="">Select here</option>
          {mapDropdownOptions(noClaimsBonusValues, 'no_claims_bonus_years')}
        </Dropdown>
      </div>
    </div>
  );
};

export default YourCarForm;
