/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './GetQuoteThankYouPage.scss';
import '../../styles/commonPageStyles.scss';

import React  from 'react';
import { Redirect } from 'react-router-dom';

import type AboutYouFormValues from '../../components/Home/AboutYouForm';
import type YourHomeFormValues from '../../components/Home/YourHomeForm';
import type YourCoverFormValues from '../../components/Home/YourHomeForm';
import { DIRECT_DEBIT_STORAGE, HOME_QUOTE_STORAGE } from '../../constants';
import { HOME_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { removeFromSessionStorage, saveInSessionStorage } from '../../helpers';
import { routes } from '../../routes';
import type { HomePayloadType } from '../../types';

interface Props {
  aboutYouObj: AboutYouFormValues;
  yourHomeObj: YourHomeFormValues;
  yourCoverObj: YourCoverFormValues;
  mappedQuoteApplication: mixed;
  payload: HomePayloadType;
}

const Success = (props: Props) => {
  removeFromSessionStorage(`${HOME_INSURANCE_TYPE}${DIRECT_DEBIT_STORAGE}`);
  const linkAddress = `${routes.viewMultipleQuotes.url}home`;

  const viewQuoteData = {
    aboutYouObj: props.aboutYouObj,
    yourHomeObj: props.yourHomeObj,
    yourCoverObj: props.yourCoverObj,
    mappedQuoteApplication: props.mappedQuoteApplication,
    response: props.payload
  };
  saveInSessionStorage(HOME_QUOTE_STORAGE, JSON.stringify(viewQuoteData));

  return <Redirect push to={`${linkAddress}`} />;
  
};

export default Success;
