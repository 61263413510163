/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../styles/commonPageStyles.scss';
import './TravelDeclaration.scss';

import Auth from '@aws-amplify/auth';
import { Button, DesktopCard, TitleWithUnderLine } from '@boi/core/lib';
import { sanitize } from 'dompurify';
import marked from 'marked';
import React, { useContext, useState } from 'react';
import type { Location, RouterHistory } from 'react-router-dom';

import { config, customer_config } from '../../amplify.config';
import { CognitoContext } from '../../CognitoUtils';
import { MobileBackButton } from '../../components/MobileBackButton';
import ProgressBar from '../../components/ProgressBar';
import { FORCE_LOGIN } from '../../constants/home';
import { TRAVEL_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import {
  JOURNEY_YOUR_QUOTE,
  MOTOR_TRAVEL_JOURNEY,
  STAGES_MOTOR_TRAVEL_JOURNEY
} from '../../constants/journey';
import { localTravelInsurancePhoneNumber } from '../../constants/phoneNumbers';
import {
  DECLARATION_DETAILS_STORAGE,
  DIRECT_DEBIT_STORAGE
} from '../../constants/sessionStorage';
import { commonPageStylesIdentifier } from '../../constants/styleConstants';
import {
  getObjectFromSessionStorage,
  isAgent,
  saveInSessionStorage
} from '../../helpers';
import { routes } from '../../routes';
import { getUsers } from '../../services/retriveUser';

const noAgreeInfo =
  `Unfortunately if you cannot confirm the above statements we will not be able to offer cover, therefore you will ` +
  `unable to proceed any further, please call ${localTravelInsurancePhoneNumber} for assistance.`;

const className = 'c-TravelDeclaration';
const firstPClassName = `${className}__firstParagraph`;
const secondPClassName = `${className}__secondParagraph`;

const getResponseData = () => {
  const travelGetQuoteResponse = getObjectFromSessionStorage(
    `${TRAVEL_INSURANCE_TYPE}${DECLARATION_DETAILS_STORAGE}`
  );
  return travelGetQuoteResponse ? travelGetQuoteResponse : { declaration: '' };
};

const sanatizer = (unsafeString: string) =>
  sanitize(unsafeString, {
    ALLOWED_TAGS: ['ol', 'ul', 'li']
  });

const getSafeHTMLFromAPI = (markdownString: string) => {
  const htmlString = marked(markdownString);
  return {
    __html: sanatizer(htmlString)
  };
};

interface Props {
  history: RouterHistory;
  location: Location;
  match: any;
}

// eslint-disable-next-line complexity
const TravelDeclaration = ({ location, history, match }: Props) => {
  const { feature, cognitoUser } = useContext(CognitoContext);
  const [noAgreeTouched, setNoAgreeTouched] = useState(false);
  const insuranceType = match.params.type || '';

  const bindingQuote = getObjectFromSessionStorage(`${insuranceType}Quote`);
  const { declaration } = getResponseData();
  const linkAddress = `${routes.marketingConsent.url}${TRAVEL_INSURANCE_TYPE}`;
  const stateValues =
    location.state ||
    getObjectFromSessionStorage(
      `${TRAVEL_INSURANCE_TYPE}${DECLARATION_DETAILS_STORAGE}`
    );

  if (Object.keys(stateValues).length === 0) {
    history.push({ pathname: routes.wallet.url });
    return <></>;
  }

  const journey = (userExistOrLoggedIn: any, isAgent: boolean) => {
    if (isAgent) {
      Auth.configure(config);
    }
    if (userExistOrLoggedIn) {
      history.push({
        pathname: `${routes.makePayment.url}${insuranceType}`,
        state: stateValues
      });
    } else {
      saveInSessionStorage(
        FORCE_LOGIN,
        JSON.stringify({ forceLogIn: true, insuranceType: insuranceType })
      );
      history.push(
        !isAgent
          ? `${routes.forceLogin.url}${insuranceType}`
          : `${routes.signUp.url}`
      );
    }
  };

  const goToNextPage = () => {
    if (isAgent()) {
      Auth.configure(customer_config);
      getUsers({
        dropdownValue: 'email',
        searchInput: bindingQuote?.aboutYouObj?.email || ''
      })
        .then((res: any) => {
          if (res?.user_list?.length > 0) {
            journey(true, true);
          } else {
            journey(false, true);
          }
        })
        .catch((e: any) => {
          console.error(e);
        });
    } else {
      journey(cognitoUser, false);
    }
  };

  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      <ProgressBar
        stage={JOURNEY_YOUR_QUOTE}
        stages={STAGES_MOTOR_TRAVEL_JOURNEY}
        labelText={MOTOR_TRAVEL_JOURNEY}
      />
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <TitleWithUnderLine>Travel Declaration</TitleWithUnderLine>
      </div>
      <DesktopCard>
        <p id="Travel-Declaration-Paragraph-1" className={firstPClassName}>
          You are responsible for answering honestly and with reasonable care
          all questions posed by the insurer. Failure to do so may (i) affect
          the policy terms and/or claims under the policy; and/or (ii) entitle
          the insurer to terminate the policy or treat the policy as if it never
          existed.
        </p>
        <p id="Travel-Declaration-Paragraph-2" className={secondPClassName}>
          Before we provide you with a policy, please make sure you can confirm
          the following statements that apply to every person to be insured:
        </p>
        <div
          className={`${className}__declarationContent`}
          dangerouslySetInnerHTML={getSafeHTMLFromAPI(declaration)}
        />
        <div className={`${className}__confirmButtonContainer`}>
          <Button
            id="TravelDeclarationPage__confirmButton"
            fluid
            secondary
            onClick={() => {
              saveInSessionStorage(
                `${TRAVEL_INSURANCE_TYPE}${DIRECT_DEBIT_STORAGE}`,
                JSON.stringify(stateValues)
              );
              feature.flags?.marketing_enabled
                ? history.push({
                    pathname: linkAddress,
                    state: stateValues
                  })
                : goToNextPage();
            }}
          >
            {`I confirm`}
          </Button>
        </div>
        <div className={`${className}__denyButtonContainer`}>
          <Button
            id="TravelDeclarationPage__denyButton"
            fluid
            secondary
            onClick={() => {
              setNoAgreeTouched(true);
            }}
          >
            {`I do not confirm`}
          </Button>
          {noAgreeTouched && (
            <p className={`${className}__noAgreeInfo`}>{noAgreeInfo}</p>
          )}
        </div>
      </DesktopCard>
    </div>
  );
};

export default TravelDeclaration;
