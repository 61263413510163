/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import '../../../../../styles/commonFormStyles.scss';
import './PenaltyPoints.scss';

import { AccordionText, ButtonGroup, Dropdown, WideDivider } from '@boi/core/lib';
import { FieldArray } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { commonFormStylesIdentifier } from '../../../../../constants';
import { dividerColor } from '../../../../../constants/colors';
import { offences, penaltyPointValues } from '../../../../../constants/dropdowns';
import { getRepeatingFieldName, isFieldError } from '../../../../../helpers';
import { mapDropdownOptions } from '../../../../../helpers/MapDropdownOptions';

const emptyOffence = {
  offence: '',
  penaltyPoints: ''
};

const untouchedOffence = {
  offence: false,
  penaltyPoints: false
};

// eslint-disable-next-line complexity
const PenaltyPoints = (props) => {
  const className = 'c-PenaltyPoints';
  const {
    driverTypePrefix = 'driver', errors, setFieldValue, setFieldTouched,
    showDisqualifiedOrConvictedField = false, touched, values, fieldNamePrefix,
    mainContainerCustomClass = '', repeatingFieldsCustomClass = '', onBlur
  } = props;

  const penaltyOffencesFieldName = `${driverTypePrefix}PenaltyPointOffences`;
  const penaltyOffencesFieldPath = `${fieldNamePrefix ? fieldNamePrefix : ''}${penaltyOffencesFieldName}`;
  const penaltyCountFieldName = `${driverTypePrefix}PenaltyPointOffenceCount`;
  const penaltyCountFieldPath = `${fieldNamePrefix ? fieldNamePrefix : ''}${penaltyCountFieldName}`;

  useEffect(() => {
    if (touched) touched[penaltyOffencesFieldName] = [];
  }, []);

  const penaltyOffenceCountChanged = (newCount) => {
    if (newCount !== '') {
      setFieldValue(penaltyCountFieldPath, Number(newCount));
      let i = 0, max = Number(newCount);
      const newValues = [];
      const newTouched = [];
      while (i !== max) {
        if (values[penaltyOffencesFieldName][i]) {
          newValues.push(values[penaltyOffencesFieldName][i]);
          newTouched.push(touched[penaltyOffencesFieldName][i]);
        } else {
          newValues.push(Object.assign({}, emptyOffence));
          newTouched.push(untouchedOffence);
        }
        ++i;
      }
      setFieldValue(penaltyOffencesFieldPath, newValues);
      setFieldTouched(penaltyOffencesFieldPath, newTouched);

    } else {
      setFieldValue(penaltyCountFieldPath, '');
      setFieldValue(penaltyOffencesFieldPath, []);
    }
  };

  const getAllOfDriversPenaltyPointsAndOffences = (fieldName) => {
    const fieldPath = getRepeatingFieldName(fieldName, fieldNamePrefix);
    const driversPointsAndOffences = [];
    if (values[fieldName]) {
      values[fieldName].forEach((pointsAndOffence, index) => {
        driversPointsAndOffences.push(getPenaltyPointAndOffence(fieldPath, pointsAndOffence, index));
      });
    }
    return driversPointsAndOffences;
  };

  const getPenaltyPointOrOffenceValue = (fieldName, index) => {
    const hasFieldValue = values[penaltyOffencesFieldName] && values[penaltyOffencesFieldName][index] && values[penaltyOffencesFieldName][index][fieldName];
    return hasFieldValue ? values[penaltyOffencesFieldName][index][fieldName] : '';
  };

  const checkPenaltyPointOrOffenceFieldAttribute = (fieldName, index, attribute) => {
    return attribute && attribute.length > index && attribute[index] && attribute[index][fieldName];
  };

  const shouldShowPenaltyPointOrOffenceError = (fieldName, index) => {
    return checkPenaltyPointOrOffenceFieldAttribute(fieldName, index, errors[penaltyOffencesFieldName]) && checkPenaltyPointOrOffenceFieldAttribute(fieldName, index, touched[penaltyOffencesFieldName]);
  };

  const getPenaltyPointOrOffenceErrorMessage = (fieldName, index) => {
    return checkPenaltyPointOrOffenceFieldAttribute(fieldName, index, errors[penaltyOffencesFieldName]) ? errors[penaltyOffencesFieldName][index][fieldName] : '';
  };

  /* Used on the penaltyPoints and offence fields so that their touched value gets set as true */
  const handleBlurPenaltyPointOrOffenceFields = (fieldName, index) => {
    if (touched[penaltyOffencesFieldName] && touched[penaltyOffencesFieldName][index] && touched[penaltyOffencesFieldName][index][fieldName] !== undefined) {
      const fieldPath = `${getRepeatingFieldName(penaltyOffencesFieldName, fieldNamePrefix)}[${index}].${fieldName}`;
      setFieldTouched(fieldPath, true);
    }
  };

  const handleChangePenaltyPointOrOffenceValues = (value, fieldName, offenceNumber) => {
    if (values[penaltyOffencesFieldName] && values[penaltyOffencesFieldName][offenceNumber]) {
      const fieldPath = `${getRepeatingFieldName(penaltyOffencesFieldName, fieldNamePrefix)}[${offenceNumber}].${fieldName}`;
      setFieldValue(fieldPath, value);
    }
  };

  const getPenaltyPointAndOffence = (fieldPath, pointsAndOffence, index) => {
    const penaltyPointsFieldName = `penaltyPoints`;
    const offencesFieldName = `offence`;
    return <div key={`${driverTypePrefix}_penalty_offence_${index}`} className={`${className}__offenceContainer`}>
      {index > 0 ? <WideDivider color={dividerColor} height={1} margin="30px 0" /> : null}
      <div className={`${commonFormStylesIdentifier}__itemCountContainer`}>
        <label className={`${commonFormStylesIdentifier}__itemCountLabel`}>Offence {index + 1}</label>
      </div>
      <div key={`${driverTypePrefix}_penalty_points_${index}`}
           className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor={`${fieldPath}[${index}].${penaltyPointsFieldName}`}
               className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Penalty points for each offence
        </label>
        <Dropdown
          placeholder="Select here"
          error={shouldShowPenaltyPointOrOffenceError(penaltyPointsFieldName, index)}
          errorMessage={getPenaltyPointOrOffenceErrorMessage(penaltyPointsFieldName, index)}
          name={`${fieldPath}[${index}].${penaltyPointsFieldName}`}
          value={getPenaltyPointOrOffenceValue(penaltyPointsFieldName, index)}
          onChange={(event) => handleChangePenaltyPointOrOffenceValues(event.currentTarget.value, penaltyPointsFieldName, index)}
          onBlur={() => handleBlurPenaltyPointOrOffenceFields(penaltyPointsFieldName, index)}
        >
          <option value="">Select here</option>
          {mapDropdownOptions(penaltyPointValues, penaltyPointsFieldName)}
        </Dropdown>
      </div>
      <div key={`${driverTypePrefix}_offence_${index}`} className={`${className}__offenceFieldContainer`}>
        <label htmlFor={`${fieldPath}[${index}].${offencesFieldName}`}
               className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Offence
        </label>
        <Dropdown
          placeholder="Select here"
          error={shouldShowPenaltyPointOrOffenceError(offencesFieldName, index)}
          errorMessage={getPenaltyPointOrOffenceErrorMessage(offencesFieldName, index)}
          name={`${fieldPath}[${index}].${offencesFieldName}`}
          value={getPenaltyPointOrOffenceValue(offencesFieldName, index)}
          onChange={(event) => handleChangePenaltyPointOrOffenceValues(event.currentTarget.value, offencesFieldName, index)}
          onBlur={() => handleBlurPenaltyPointOrOffenceFields(offencesFieldName, index)}
        >
          <option value="">Select here</option>
          {mapDropdownOptions(offences, offencesFieldName)}
        </Dropdown>
      </div>
    </div>;
  };

  const getDisqualifiedOrConvictedField = () => {
    const disqualifiedFieldName = `${driverTypePrefix}DisqualifiedOrConvicted`;
    const fieldPath = getRepeatingFieldName(disqualifiedFieldName, fieldNamePrefix);

    return showDisqualifiedOrConvictedField ?
      <div className={getActiveClassesForEverBeenDisqualifiedField()}>
        <label htmlFor={fieldPath} className={`${commonFormStylesIdentifier}__fieldLabel`}>

          <AccordionText
            label="Have you or any person who will drive the vehicle, ever been disqualified from driving, ever been convicted of a criminal or motoring offence or have any prosecutions pending?"
            secondLineLabel="Please do not disclose convictions that are deemed spent under the Criminal Justice (Spent Convictions and Certain Disclosures) Act 2016."
            icon="info"
            iconAlign="right"
            customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
          >
            <br />
            <div className={`${commonFormStylesIdentifier}__infoText`} tabIndex={0}>
              A spent conviction is a conviction which does not have to be disclosed after 7 years for the purposes of
              obtaining insurance cover. You must disclose any conviction that you or any person who will drive the
              vehicle, have received that is not spent under the Criminal Justice (Spent Convictions and Certain
              Disclosures) Act 2016.
              <br />
              If you are in doubt as to whether a conviction is spent or not we recommend that you seek professional
              legal advice, otherwise contact us at 01 488 4062.
            </div>
          </AccordionText>
        </label>
        <div className={`${commonFormStylesIdentifier}--fieldLabelSpacing`}>
          <ButtonGroup
            name={fieldPath}
            onClick={(value) => {
              setFieldValue(fieldPath, value === 'true');
              setFieldTouched(fieldPath, true);
            }}
            options={[
              { label: 'No', value: false },
              { label: 'Yes', value: true }
            ]}
            value={values[disqualifiedFieldName]}
            touched={touched[disqualifiedFieldName]}
            selected={values[disqualifiedFieldName]}
            error={isFieldError(fieldPath, touched, errors)}
            errorMessage={errors[fieldPath]}
          />
        </div>
      </div> : null;
  };

  const getActiveClassesForEverBeenDisqualifiedField = () => {
    return [
      `${commonFormStylesIdentifier}__fieldContainer`,
      mainContainerCustomClass ? `${mainContainerCustomClass}` : '',
      values[penaltyCountFieldName] > 0 ? `${className}--extraFieldContainerSpacing` : ''
    ].filter(Boolean).join(' ');
  };

  return (
    <div className={className}>
      <div className={`${commonFormStylesIdentifier}__fieldContainer ${mainContainerCustomClass}`}>
        <label className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Number of penalty point offences
        </label>
        <Dropdown
          name={getRepeatingFieldName(penaltyCountFieldName, fieldNamePrefix)}
          value={values[penaltyCountFieldName]}
          onChange={(e) => penaltyOffenceCountChanged(e.target.value)}
          onBlur={(e) => onBlur(penaltyCountFieldName, e)}
          error={isFieldError(penaltyCountFieldName, touched || {}, errors || {})}
          errorMessage={errors[penaltyCountFieldName]}
        >
          <option value="">Select here</option>
          <option value={0}>0</option>
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
        </Dropdown>
      </div>
      <FieldArray
        name={getRepeatingFieldName(penaltyOffencesFieldName, fieldNamePrefix)}
        render={() => {
          return values[penaltyOffencesFieldName] && values[penaltyOffencesFieldName].length > 0 ?
            <div className={`${commonFormStylesIdentifier}__dynamicFieldsContainer ${repeatingFieldsCustomClass}`}>
              {getAllOfDriversPenaltyPointsAndOffences(penaltyOffencesFieldName)}
            </div> : <></>;
        }}
      />
      {getDisqualifiedOrConvictedField()}
    </div>
  );
};

PenaltyPoints.propTypes = {
  driverTypePrefix: PropTypes.string,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  showDisqualifiedOrConvictedField: PropTypes.bool,
  touched: PropTypes.object,
  values: PropTypes.object,
  fieldNamePrefix: PropTypes.string,
  mainContainerCustomClass: PropTypes.string,
  repeatingFieldsCustomClass: PropTypes.string,
  onBlur: PropTypes.func
};

export default PenaltyPoints;
