import PropTypes from 'prop-types';
import React from 'react';

export const ExternalLink = ({text, url, className}) => 
  <a rel="noopener noreferrer" target="_blank" href={url} className={className}>{text}</a>;

ExternalLink.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  className: PropTypes.string,
};