/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './CarPayment.scss';

import { WideDivider } from '@boi/core/lib';
import { Form, FormikProps, withFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import type { Location, Match, RouterHistory } from 'react-router-dom';

import { CognitoContext } from '../../../CognitoUtils';
import LegalTextHomeDirectDebit from '../../../components/LegalTextHomeDirectDebit';
import QuoteDisplay from '../../../components/QuoteDisplay';
import {
  commonFormStylesIdentifier,
  DIRECT_DEBIT_STORAGE,
  GET_QUOTE_VALUES_STORAGE,
  SELECTED_MOTOR_QUOTE_STORAGE
} from '../../../constants';
import { dividerColor } from '../../../constants/colors';
import { CAR_INSURANCE_TYPE } from '../../../constants/insuranceTypeConstants';
import { TOTAL } from '../../../constants/paymentTypes';
import { PAYMENT_SUCCESS } from '../../../constants/sessionStorage/genericStorageIdentifiers.js';
import {
  getCurrentDateAndTime,
  getObjectFromSessionStorage,
  isAgent
} from '../../../helpers';
import { getPenaltyDetails } from '../../../mappings/motor/MapMotorQuote';
import { routes } from '../../../routes';
import { payCar, payCar3DS } from '../../../services/paysafeService';
import type { AdditionalDriverType, DirectDebitType } from '../../../types';
import CardPayment from '../CardPayment';
import DirectDebit from '../DirectDebit';
import carPaymentSchema from './carPaymentSchema.js';
import PaymentFrequency from './PaymentFrequency';
import { PAYMENT_FREQUENCY } from './values';

interface DirectDebitProps {
  history: RouterHistory;
  location: Location;
  match: Match;
  insuranceType: string;
}

const className = 'c-CarPayment';

const paymentOptions = [
  { label: 'Monthly', value: PAYMENT_FREQUENCY.MONTHLY },
  { label: 'Single', value: PAYMENT_FREQUENCY.TOTAL }
];

const CarPaymentForm = (
  props: DirectDebitProps & FormikProps<DirectDebitType>
) => {
  const { isValid, values, setFieldValue, insuranceType, history } = props;

  const [cardBrand, setCardBrand] = useState('');
  const [consentData, setConsentData] = useState({});
  const [selectedQuoteData, setSelectedQuoteData] = useState({
    price: 0,
    quoteReference: '0',
    quoteType: '',
    monthlyPayment: 0,
    depositPayment: 0
  });
  const [paysafeError, setPaysafeError] = useState('');
  const { feature } = useContext(CognitoContext);

  const quoteValues = getObjectFromSessionStorage(
    `${CAR_INSURANCE_TYPE}${GET_QUOTE_VALUES_STORAGE}`
  );

  const redirectToHome = () => {
    history.push({ pathname: routes.wallet.url });
  };

  const readSelectedQuoteData = () => {
    const paymentData = getObjectFromSessionStorage(
      `${SELECTED_MOTOR_QUOTE_STORAGE}`
    );
    if (!paymentData.response) redirectToHome();
    else {
      const quoteType = paymentData.selectedQuoteType;
      setSelectedQuoteData({
        quoteReference: paymentData.response.quote_reference,
        quoteType,
        price: paymentData.response[quoteType].total_amount,
        monthlyPayment: paymentData.response[quoteType].direct_debit_amount,
        depositPayment: paymentData.response[quoteType].card_deposit_amount
      });
    }
  };

  const getMainDriverPenaltyPoints = () => {
    return quoteValues.mainDriverPenaltyPointOffences
      ? getPenaltyDetails(quoteValues.mainDriverPenaltyPointOffences, '1')
      : [];
  };

  const getAdditionalDriversPenaltyPoints = () => {
    const additionalDrivers = quoteValues.additionalDrivers;
    let additionalDriverId = 2;
    let additionalDriversOffences = [];
    if (additionalDrivers) {
      additionalDrivers.forEach((driver: AdditionalDriverType) => {
        const additionalDriverDetails = driver.additionalDriverPenaltyPointOffences
          ? getPenaltyDetails(
              driver.additionalDriverPenaltyPointOffences,
              `${additionalDriverId}`
            )
          : [];
        additionalDriversOffences = [
          ...additionalDriversOffences,
          ...additionalDriverDetails
        ];
        ++additionalDriverId;
      });
    }
    return additionalDriversOffences;
  };

  const getAllDriversPenaltyPointDetails = () => {
    return [
      ...getMainDriverPenaltyPoints(),
      ...getAdditionalDriversPenaltyPoints()
    ];
  };

  useEffect(() => {
    readSelectedQuoteData();
    sessionStorage.removeItem(PAYMENT_SUCCESS);
    const consentStorageData = getObjectFromSessionStorage(
      `${CAR_INSURANCE_TYPE}${DIRECT_DEBIT_STORAGE}`
    );
    if (feature.flags?.marketing_enabled) {
      if (!consentStorageData.consent) redirectToHome();
      else {
        const { email, phone, post, text } = consentStorageData.consent;
        setConsentData({
          marketing_consent: [email, phone, post, text].includes(true),
          marketing: {
            email_consent: email,
            mailing_consent: post,
            mobile_sms_consent: text,
            telephone_consent: phone
          }
        });
      }
    } else {
      setConsentData({});
    }
  }, []);

  useEffect(() => {
    setPaysafeError('');
  }, [values]);

  const getPaymentsDetails = {
    CARD_PAYMENT: (paymentToken: string) => {
      return {
        payment_details: {
          payment_id: paymentToken,
          payment_type: 'CARD_PAYMENT',
          purchaser: values.nameOnCard,
          card_type: cardBrand,
          quote_reference: selectedQuoteData.quoteReference,
          payment_date: getCurrentDateAndTime(),
          product_selected: selectedQuoteData.quoteType,
          collection_day: '',
          installments_number: '0',
          bank_identifier_code: '',
          international_bank_account_number: '',
          price: selectedQuoteData.price
        },
        penalty_details: getAllDriversPenaltyPointDetails(),
        disqualified: quoteValues.mainDriverDisqualifiedOrConvicted,
        ...consentData
      };
    },
    DIRECT_DEBIT: (paymentToken: string) => {
      return {
        payment_details: {
          payment_id: paymentToken,
          payment_type: 'DIRECT_DEBIT_PAYMENT',
          purchaser: values.nameOnCard,
          card_type: 'DD',
          quote_reference: selectedQuoteData.quoteReference,
          installments_number: '10',
          payment_date: getCurrentDateAndTime(),
          bank_identifier_code: values.bic,
          international_bank_account_number: values.iban,
          product_selected: selectedQuoteData.quoteType,
          price: selectedQuoteData.price
        },
        penalty_details: getAllDriversPenaltyPointDetails(),
        disqualified: quoteValues.mainDriverDisqualifiedOrConvicted,
        ...consentData
      };
    }
  };

  const goToPaymentsThankYouPage = (paymentDetails: {}) => {
    props.history.push({
      pathname: `${routes.paymentThankYou.url}${insuranceType}`,
      state: {
        paymentDetails: paymentDetails,
        insuranceType: 'car'
      }
    });
  };

  const submitPayment = () => {
    const successFn = (paymentToken: string) => {
      const payFreq =
        values.paymentFrequency === PAYMENT_FREQUENCY.TOTAL
          ? 'CARD_PAYMENT'
          : 'DIRECT_DEBIT';
      const paymentDetails = getPaymentsDetails[payFreq](paymentToken);
      goToPaymentsThankYouPage(paymentDetails);
    };
    const errorFn = (code: string, message: string) =>
      setPaysafeError(`${message} (Code: ${code})`);
    const priceWithDecimals = Math.round(100.0 * getQuoteDisplayPrice());
    const pay3DSObj = {
      amount: priceWithDecimals,
      cardHolder: values.nameOnCard
    };
    isAgent()
      ? payCar(successFn, errorFn)
      : payCar3DS(pay3DSObj, successFn, errorFn);
  };

  const setCardValidity = (isValidCard: boolean) => {
    setFieldValue('cardIsValid', isValidCard);
  };

  const getQuoteDisplayPrice = () =>
    values.paymentFrequency === PAYMENT_FREQUENCY.MONTHLY
      ? selectedQuoteData.depositPayment
      : selectedQuoteData.price;

  const getQuoteDisplayTitle = () =>
    values.paymentFrequency === PAYMENT_FREQUENCY.MONTHLY
      ? 'Deposit due today:'
      : 'Total';

  const policyStartDate = () => {
    const policyStartString = quoteValues.policyStart;
    if (!policyStartString) return <></>;
    const policyStartDate = new Date(policyStartString);
    return (
      <div className={`${className}__paymentSubtitle`}>
        Monthly instalments will be paid each month on day{' '}
        {policyStartDate.getDate()}.
      </div>
    );
  };

  const getDirectDebitFields = (paymentFrequency: string) =>
    paymentFrequency && paymentFrequency !== PAYMENT_FREQUENCY.TOTAL ? (
      <>
        <div>
          <div className={`${className}__paymentSubtitle`}>
            Monthly direct debit details
            <br />
            10 x €{selectedQuoteData.monthlyPayment.toFixed(2)} monthly
            installments.
          </div>
          <DirectDebit disablePolicyStart {...props} />
        </div>
        {policyStartDate()}

        <div className={`${className}__paymentSubtitle`}>
          Deposit payment details <br />
          Deposit due today €{selectedQuoteData.depositPayment.toFixed(2)}.
        </div>
      </>
    ) : (
      <></>
    );

  const getCardPaymentFields = (paymentFrequency: string) =>
    paymentFrequency ? (
      <CardPayment
        setCardBrand={setCardBrand}
        {...props}
        insuranceType={CAR_INSURANCE_TYPE}
        setIsValid={setCardValidity}
      />
    ) : (
      <></>
    );

  const getPaysafeError = () =>
    paysafeError ? (
      <>
        <div className={`${className}__paysafeError`}>{paysafeError}</div>
      </>
    ) : (
      <></>
    );

  const getLegalText = (paymentFrequency: string) =>
    paymentFrequency && paymentFrequency !== PAYMENT_FREQUENCY.TOTAL ? (
      <LegalTextHomeDirectDebit
        values={values}
        setFieldValue={setFieldValue}
        insurerType={'RSA'}
        insurerMode={CAR_INSURANCE_TYPE}
      />
    ) : (
      <></>
    );

  return (
    <Form>
      <PaymentFrequency {...props} paymentOptions={paymentOptions} />
      <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
        <WideDivider height={1} color={dividerColor} />
      </div>

      {getDirectDebitFields(values.paymentFrequency)}

      {getCardPaymentFields(values.paymentFrequency)}

      {getPaysafeError()}

      {getLegalText(values.paymentFrequency)}

      <QuoteDisplay
        sticky
        price={getQuoteDisplayPrice()}
        title={getQuoteDisplayTitle()}
        buttonType="Buy"
        disabled={!(isValid && values.cardIsValid)}
        onClickFunction={submitPayment}
        quoteReference={selectedQuoteData.quoteReference}
        paymentType={TOTAL}
        insurerType={null}
      />
    </Form>
  );
};

const CarPayment = withFormik<DirectDebitProps, DirectDebitType>({
  mapPropsToValues(): {} {
    return {
      paymentFrequency: '',
      bankRequireOneSignature: '',
      bankAccountYoursWillAcceptDirectDebit: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      county: '',
      bic: '',
      iban: '',
      cardIsValid: false
    };
  },
  handleSubmit(values: DirectDebitType, { setSubmitting }: FormikProps) {
    setSubmitting(false);
  },
  validationSchema: carPaymentSchema,
  displayName: 'CarPaymentForm'
})(CarPaymentForm);

export default CarPayment;
