/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import { noop } from '@boi/core/lib/utils';
import { addDays, endOfToday, format, min, parse, startOfToday } from 'date-fns';
import * as yup from 'yup';
import { Schema } from 'yup';

import {
  carTitleValues,
  driverLicenseTypeValues,
  lastFiveYearsDrivingExperienceValues,
  motorEmploymentStatusValues,
  relationshipToProposerValues
} from '../../../constants/dropdowns';
import { FIRST_NAME_ERROR_TEXT } from '../../../constants/home';
import {
  dateOfBirthYup,
  getValuesFromDropdownDetailsType
} from '../../../helpers';
import {
  nullableBooleanRequired,
  stringRequired
} from '../../../helpers/CommonYupValidation';
import { getOccupationOptions } from '../../../mocks/MainOccupationValues';

const moreThanTwo = 'Please enter in more than two characters';
const lessThanFifty = 'Please enter in less than fifty characters';
const mainOccupationOptions = getOccupationOptions();

const policyStartDateErrorString = 'Start date must be today or in the future.';
const maxPolicyDate = min(addDays(endOfToday(), 45), new Date('2025-03-30'));
const maxPolicyDateStr = format(maxPolicyDate,'DD/MM/YYYY');
const policyEndDateErrorString = `Please enter a date up to ${maxPolicyDateStr}`;

const shouldHaveOccupation = (status: string) => {
  const occupationStatuses: Array<string> = [
    'EMP',
    'SEF',
    'EMPLOYED',
    'SELF_EMPLOYED'
  ];
  return occupationStatuses.includes(status);
};

const checkIfYearsHeldWithinRange = (
  value: number,
  path: string,
  createError: noop
) => {
  if (value < 0) {
    return createError({ path, message: 'Please enter a larger number' });
  } else if (value > 100) {
    return createError({ path, message: 'Please enter a smaller number' });
  }
};

const additionalDriverYupSchema = yup.object().shape({
  title: yup
    .string()
    .oneOf(
      getValuesFromDropdownDetailsType(carTitleValues),
      'Title is required'
    ),
  //First Name and Last Name: can't be empty, less than 50 chars
  firstName: yup
    .string()
    .min(2, moreThanTwo)
    .max(50, lessThanFifty)
    .matches(/^[a-z .'-]+$/i, 'Numbers and symbols are not permitted')
    .required(FIRST_NAME_ERROR_TEXT),
  lastName: yup
    .string()
    .min(2, moreThanTwo)
    .max(50, lessThanFifty)
    .matches(/^[a-z .'-]+$/i, 'Numbers and symbols are not permitted')
    .required('Last name is required'),
  dob: dateOfBirthYup,
  employmentStatus: yup
    .string()
    .oneOf(
      [...getValuesFromDropdownDetailsType(motorEmploymentStatusValues)],
      'Employment status is required'
    ),
  mainOccupation: yup
    .string()
    .when(
      'employmentStatus',
      (employmentStatus: string, schema: Schema<string>) =>
        shouldHaveOccupation(employmentStatus)
          ? schema
              .oneOf(
                mainOccupationOptions.map(
                  (occupation: { value: string }) => occupation.value
                ),
                'Occupation is required'
              )
              .required('Occupation is required')
          : schema
    ),
  relationshipToProposer: yup
    .string()
    .oneOf(
      getValuesFromDropdownDetailsType(relationshipToProposerValues),
      'Relationship to proposer'
    ),
  driversLicenseType: yup
    .string()
    .oneOf(
      getValuesFromDropdownDetailsType(driverLicenseTypeValues),
      'Drivers licence type is required'
    ),
  lastFiveYearsExperience: yup
    .string()
    .oneOf(
      getValuesFromDropdownDetailsType(lastFiveYearsDrivingExperienceValues),
      'Last five years experience required.'
    ),
  yearsHeld: yup
    .string()
    .test('yearsHeldDrivingLicense', 'Years Held is required', function(
      value: string
    ): boolean {
      if (!value) return false;
      const { path, createError } = this;
      const strippedValue = value.replace(/,/g, ``);
      const integerValue = parseInt(strippedValue, 10);
      const error = checkIfYearsHeldWithinRange(
        integerValue,
        path,
        createError
      );
      if (error) return error;
      return true;
    })
    .nullable(true),
  additionalDriverPenaltyPointOffences: yup
    .array()
    .when('additionalDriverPenaltyPointOffenceCount', {
      is: (penaltyOffenceCount: number) => penaltyOffenceCount > 0,
      then: yup
        .array()
        .of(
          yup.object().shape({
            penaltyPoints: stringRequired('Penalty points is required'),
            offence: stringRequired('Offence is required')
          })
        )
        .required('Penalty points and offence required'),
      otherwise: yup.array().notRequired()
    }),
  additionalDriverPenaltyPointOffenceCount: yup
    .number()
    .typeError('Number of penalty points is required')
    .required('Number of penalty points is required')
});

export const yourCarCoverFormYupSchema = {
  policyStart: yup
    .date()
    .transform((_, dateString: string) => parse(dateString))
    .min(startOfToday(), policyStartDateErrorString)
    .max(maxPolicyDate, policyEndDateErrorString)
    .required('Policy start date is required'),
  coverType: yup.string().required('Cover type is required'),
  motorClaims: yup.array().of(
    yup.object().shape({
      claim_type: yup.string().required('Motor claim type is required'),
      claim_status: yup.string().required('Motor claim type is required'),
      claim_amount: yup.string().required('Motor claim amount is required')
    })
  ),
  claimsCount: yup.number().required('Number of claims is required'),
  mainDriverPenaltyPointOffences: yup
    .array()
    .when('mainDriverPenaltyPointOffenceCount', {
      is: (penaltyOffenceCount: number) => penaltyOffenceCount > 0,
      then: yup
        .array()
        .of(
          yup.object().shape({
            penaltyPoints: stringRequired('Penalty points is required'),
            offence: stringRequired('Offence is required')
          })
        )
        .required('Penalty points and offence required'),
      otherwise: yup.array().notRequired()
    }),
  mainDriverPenaltyPointOffenceCount: yup
    .number()
    .required('Number of penalty points is required'),
  mainDriverDisqualifiedOrConvicted: nullableBooleanRequired(
    'isDriverDisqualifiedOrConvicted',
    'You must select an answer to this field'
  ),
  hasAdditionalDrivers: nullableBooleanRequired(
    'hasAdditionalDrivers',
    'You must select an answer to this field'
  ),
  additionalDrivers: yup.array().when('hasAdditionalDrivers', {
    is: true,
    then: yup
      .array()
      .of(additionalDriverYupSchema)
      .required('Additional driver is required'),
    otherwise: yup.array().notRequired()
  })
};

export default yourCarCoverFormYupSchema;
