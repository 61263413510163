/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

export const HOME_INSURANCE_TYPE = 'home';
export const ABOUT_YOU = 'aboutYou';
export const YOUR_COVER = 'yourCover';
export const CAR_INSURANCE_TYPE = 'car';
export const TRAVEL_INSURANCE_TYPE = 'travel';
export const BUSINESS_INSURANCE_TYPE = 'business';
export const LIFE_INSURANCE_TYPE = 'life';
export const PET_INSURANCE_TYPE = 'pet';
export const HEALTH_INSURANCE_TYPE = 'health';
export const PENSIONS_INSURANCE_TYPE = 'pensions';
export const MARKETING_CONSENT = 'marketing';
export const SAVINGS_AND_INVEST_INSURANCE_TYPE = 'savingsAndInvestments';
export const OPTIONAL_EXTRAS = ['CONAD', 'PNCD', 'HEAOPT', 'AFTEROPTEX'];
export const AFTER_SALES_DISCOUNT = ['BOICUS', 'BOIMUL', 'BOICAR', 'PROMOPCT'];
export const CODE_VALUES = {
  RSA: '26',
  FBD: 'INCLEVY'
};
export const ENABLED = 'enabled';
export const RENEWAL = 'renewal';
export const RESUME = 'resume';

export const premiumBreakDownConstants = {
  BOICAR: 'BOICAR',
  BOICUS: 'BOICUS',
  BOIMUL: 'BOIMUL',
  PROMOPCT: 'PROMOPCT',
  CONAD: 'CONAD',
  PNCD: 'PNCD',
  HEAOPT: 'HEAOPT',
  AFTEROPTEX: 'AFTEROPTEX',
  INCLEVY: 'INCLEVY',
  LEVY: 'LEVY',
  AFTERLOADDISC: 'AFTERLOADDISC',
  AFTERSALES: 'AFTERSALES'
};
