/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './MobileBackButton.scss';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import type { RouterHistory } from 'react-router-dom';

import { greyDark } from '../../constants/colors';

interface Props {
  text?: string;
  history: RouterHistory;
  onDesktopClick?: () => void;
  pageJumpCount: number;
}

const className = 'c-MobileBackButton';
const buttonId = 'MobileBackButton__goBack';

export const MobileBackButton = ({ text = 'Back', history }: Props) => {
  // eslint-disable-next-line complexity
  const handleChangePage = () => history.goBack();

  return (
    <button
      type="button"
      className={`${className}__button`}
      id={buttonId}
      data-testid={buttonId}
      onClick={handleChangePage}
    >
      <FontAwesomeIcon
        icon={faArrowLeft}
        color={greyDark}
        className={`${className}__arrowLeft`}
      />
      <span className={`${className}__text`}>{text}</span>
    </button>
  );
};

export default MobileBackButton;
