/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import { yourCarCoverMainDriverPrefix } from '../../components/Forms/YourCarCoverForm/YourCarCoverForm';
import { getCurrentDateAndTime, getDateOnly, irishToRawFormat } from '../../helpers/DateHelper';
import type {
  AboutYouFormType,
  Assumptions,
  MotorClaimType,
  MotorViewQuoteTypes,
  PenaltyPointsAndOffenceType,
  YourCarCoverFormTypes,
  YourCarFormTypes
} from '../../types';
import type { AdditionalDriverType } from '../../types/motor';

const EmplymentValues = {
  'HOUSEWIFE_HUSBAND': 'HBG',
  'RETIRED': 'RAO',
  'STUDENT': 'SDA'
};

const getRiskAddress = (values: AboutYouFormType & YourCarFormTypes) => {

  if (values.isThisYourPostalAddress === false) {
    return {
      "address_line1": values.postalAddressLine1,
      "address_line2": values.postalAddressLine2,
      "address_line3": values.postalAddressLine3,
      "post_code": values.postalAddressEircode,
      "town": values.postalAddressTown,
      "county": values.postalAddressCounty
    };
  } else {
    return {
      "address_line1": values.addressLine1,
      "address_line2": values.addressLine2,
      "address_line3": values.addressLine3,
      "post_code": values.addressEircode,
      "town": values.addressTown,
      "county": values.addressCounty
    };
  }

};
const getLicenseIssueCountryValue = (passedTestInIrelandOrUk: string) => {
  return passedTestInIrelandOrUk === 'true' ? 'IE' : '99';
};

type PenaltyDetailsArrayType = Array<{ driver_order: string, offence: string, penalty_order: number, penalty_points: number }>;

export const getPenaltyDetails = (penaltyDetails: Array<PenaltyPointsAndOffenceType> = [], driverOrder: string = '1'): PenaltyDetailsArrayType => {
  return penaltyDetails.map((penalty: PenaltyPointsAndOffenceType, index: number) => {
    return {
      "driver_order": driverOrder,
      "offence": penalty.offence,
      "penalty_order": index,
      "penalty_points": Number(penalty.penaltyPoints)
    };
  });
};

const formatClaims = (claims: MotorClaimType[] = []): MotorClaimType[] => {
  return claims.map((claim: MotorClaimType) => {
    let claimValue;
    if (typeof claim.claim_amount === 'string') {
      claimValue = claim.claim_amount !== '' ? parseInt(claim.claim_amount.replace(/,/g, ``), 10) : 0;
    } else {
      claimValue = claim.claim_amount;
    }
    return {
      claim_type: claim.claim_type,
      claim_status: claim.claim_status,
      claim_amount: claimValue
    };
  });
};

const getRegistration = (values: AboutYouFormType & YourCarFormTypes & YourCarCoverFormTypes & MotorViewQuoteTypes) => values.isManualSearch ? undefined : values.carReg;
const getABICode = (values: AboutYouFormType & YourCarFormTypes & YourCarCoverFormTypes & MotorViewQuoteTypes) => values.isManualSearch ? values.carABICode : undefined;

const getStartDate = (values: AboutYouFormType & YourCarFormTypes & YourCarCoverFormTypes & MotorViewQuoteTypes) => {
  return values.policyStart ? values.policyStart : getDateOnly(getCurrentDateAndTime());
};

const checkValueIsAvailable = (value: boolean) => value ? value : false;

const mapAdditionalDrivers = (additionalDrivers: Array<AdditionalDriverType> = []): Array<{ [string]: number | string | boolean | PenaltyDetailsArrayType }> => {
  // This driver order starts at 2 because the main driver is given the value 1
  const driverOrder = 2;
  return additionalDrivers.map((driver: AdditionalDriverType, index: number) => {
    return {
      "title": driver.title,
      "first_name": driver.firstName,
      "last_name": driver.lastName,
      "date_of_birth": irishToRawFormat(driver.dob),
      "employment_status": driver.employmentStatus,
      "occupation": EmplymentValues[driver.employmentStatus] ? EmplymentValues[driver.employmentStatus] : driver.mainOccupation,
      "license_type": driver.driversLicenseType,
      "license_years": driver.yearsHeld,
      "driving_experience": driver.lastFiveYearsExperience,
      "driving_test": true,
      "has_boi_house_policy": true,
      "boi_customer": true,
      "relationship_to_proposer": driver.relationshipToProposer,
      "penalty_details": getPenaltyDetails(driver.additionalDriverPenaltyPointOffences, `${driverOrder + index}`)
    };
  });
};

export const mapMotorQuote = (values: AboutYouFormType & YourCarFormTypes & YourCarCoverFormTypes & MotorViewQuoteTypes & Assumptions) => {
  return {
    "motor_quote": {
      "carrier_id": "carrierId",
      "created_by": "createdBy",
      "quote_mode": "ONLINE",
      "quote_status": "Incomplete",
      "promotion_code": values.promoCode,
      "main_driver": {
        "first_name": values.firstName,
        "last_name": values.lastName,
        "title": values.title,
        "email": values.email,
        "date_of_birth": irishToRawFormat(values.dob),
        "mobile_phone": values.phoneNo,
        "home_phone": values.phoneNo, //tej and backend to remove, leave here for the moment
        "work_phone": values.phoneNo, //tej and backend to remove, leave here for the moment
        "employment_status": values.employmentStatus,
        "occupation": EmplymentValues[values.employmentStatus] ? EmplymentValues[values.employmentStatus] : values.mainOccupation,
        "license_number": 1, // Value not needed on backend, leave value here
        "license_type": values.driversLicenseType,
        "license_issue_country": getLicenseIssueCountryValue(values.passedTestInIrelandOrUk),
        "license_years": values.yearsHeld,
        "boi_customer": checkValueIsAvailable(values.bankOfIrelandCustomer),
        "driving_experience": values.lastFiveYearsExperience,
        "driving_test": true, // Is calculated on backend, leave value here
        "has_boi_house_policy": checkValueIsAvailable(values.bankOfIrelandHomeInsurancePolicy),
        "ncb_year_on_other_policy": values.noClaimsBonus,
        "ncb_years": true, // Value not need in backend
        "primary_address": {
          "address_line1": values.addressLine1,
          "address_line2": values.addressLine2,
          "address_line3": values.addressLine3,
          "post_code": values.addressEircode,
          "town": values.addressTown,
          "county": values.addressCounty
        },
        "claims": formatClaims(values.motorClaims),
        "data_protection_consent": values.assumptions.acceptedTermsAndConditions,
        "penalty_details": getPenaltyDetails(values[`${yourCarCoverMainDriverPrefix}PenaltyPointOffences`], '1')
      },
      "cover_details": {
        "start_date": getStartDate(values),
        "type": values.coverType,
        "premium": 20, // Mapped on backend, not removed from Model
        "product_selected": "AXY", //appears to give both lists whereas X or Y on their own will only return the respective product
        "windscreen": "windscreen", // Mapping not removed from Model, Hardcoded on backend
        "excess_amount": 10, // Makes no difference to the quote, leave hard coded for now
        "ncb_protection": checkValueIsAvailable(values.ncdProtection),
        "acc_or_claim": false,  // mapped on BE, not removed from model
        "other_car": "",   //Doesn't seem to change the premium value (successful call when left blank)
        "illness": "",   //Doesn't seem to change the premium value (successful call when left blank)
        "disqualified": values.mainDriverDisqualifiedOrConvicted,
        "car_owner": true, // Must always be true and if not true you get a referral
        "convictions": false, // Backend currently doesn't need, but not clear when does RSA need it
        "irish_resident": null,
        "used_import": null,
        "penalty_points": false, // Mapped on backend, not removed from model
        "driving_exp_type": values.drivingExpType,
        "driving_exp_type_country": "IE", // doesn't affect quote, not removed from model yet
        "other_car_desc": "0", //Returns error for value null or any string that is not a number. Provided number does not affect the result, same values for "1", "-1" and "1000000000000"
        "legal_protection": false,
        "driver_protection": checkValueIsAvailable(values.personalProtectionPlus),
        "car_protection": checkValueIsAvailable(values.additionalBenefitsPlus),
        "vehicle_details": {
          "abi_code": getABICode(values),
          "make": values.carMake,
          "model": values.carModel,
          "year": values.carYear,
          "version": values.carVersion,
          "engine_size": values.carEngineSize,
          "risk": values.carRisk,
          "value": values.vehicleValue ? values.vehicleValue.replace(/,/gi, '') : 0,
          "use": values.carUsedForWork,
          "kept_overnight": {
            "area": "",    //amanda to discuss with backend -risk address or primary address is provided
            "area_code": ""   //set to empty - eventually to be removed from json
          },
          "km_per_year": values.annualKm,
          "left_hand_drive": "R",
          "modifications": "",
          "fuel": values.carFuelType,
          "cc": values.carEngineSize,
          "years_owned_vehicle": values.carPurchased,
          "registration": getRegistration(values),
          "your_car": null
        },
        "modifications": [], //Mandy confirmed that this is an assumption
        "risk_address": getRiskAddress(values)
      },
      "additional_drivers": mapAdditionalDrivers(values.additionalDrivers),
      "discount_breakdowns": [
        {
          "applicable": "true",
          "discount_information": "info"
        }
      ],
      "assumptions": {
        "disability_reported": values.assumptions.disability_reported,
        "no_refusals": values.assumptions.no_refusals,
        "registered_to_you_or_spouse": values.assumptions.registered_to_you_or_spouse,
        "no_modifications": values.assumptions.no_modifications
      }
    },
    "honeypot_value": values.userName,
    "product": "product",
    "version": "0.1"
  };
};
