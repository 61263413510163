// @flow

import { Modal } from '@boi/core/lib';
import React, { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

import { CognitoContext } from '../../CognitoUtils';
import { routes } from '../../routes';
import { LogOutEvent,usePub } from '../../utils/pubSub';

const minutesForModal = 15;
const minutesForLogout = 1;

const PopOverTimeOut = () => {
  const [lastInteractionTime, setLastInteractionTime] = useState(Date.now());
  const [minutesLapsed, setMinutesLapsed] = useState(0);
  const [modalShown, setModalShown] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(0);
  const { cognitoUser } = useContext(CognitoContext);

  const history = useHistory();
  const publish = usePub();


  useEffect(() => {
    const minutesLapsed = minutesFromLastInteraction();
    if (!modalShown && minutesLapsed >= minutesForModal) {
      setModalShown(true);
      setSecondsLeft(60 * minutesForLogout);
    }
  }, [minutesLapsed]);

  useEffect(() => {
    if (modalShown) {
      secondsLeft > 0
        ? setTimeout(
          // eslint-disable-next-line flowtype/require-parameter-type
          () => setSecondsLeft(secondsLeft => Math.max(secondsLeft - 1, 0)),
          1_000
        )
        : signOut(true);
    }
  }, [secondsLeft]);

  useEffect(() => {
    let isMounted = true;
    const interval = setInterval(
      () => isMounted && setMinutesLapsed(minutesFromLastInteraction()),
      60_000
    );
    document.addEventListener('click', () => isMounted && onInteraction());
    document.addEventListener('keydown', () => isMounted && onInteraction());
    return () => {
      isMounted = false;
      clearInterval(interval);
    };
  }, []);

  const minutesFromLastInteraction = () => {
    const msDiff = Date.now() - lastInteractionTime;
    return Math.floor(msDiff / 60_000);
  };

  const onInteraction = () => setLastInteractionTime(Date.now());

  const onContinue = () => {
    setModalShown(false);
    onInteraction();
  };

  const signOut = (timedOut: boolean = false) => {
    setModalShown(false);
    onInteraction();
    timedOut && cognitoUser && publish(LogOutEvent);
    history.push(routes.logoutPage.url);
  };

  const buttonsProps = () =>
    cognitoUser
      ? {
        primaryButtonLabel: 'Sign Out',
        onPrimaryButtonClick: signOut,
        secondaryButtonLabel: 'Continue',
        onSecondaryButtonClick: onContinue
      }
      : {
        primaryButtonLabel: 'Continue',
        onPrimaryButtonClick: onContinue,
        showSecondaryButton: false
      };

  return (
    <Modal
      width={isMobile ? '80%' : '40%'}
      show={modalShown}
      title="Session Timeout"
      modalElementId="root"
      id={'modal-edit-type'}
      {...buttonsProps()}
      showCloseIcon={false}
      data-testid="modal-edit-type"
    >
      <p>
        Your session will expire in {Math.floor(secondsLeft / 60)}m{' '}
        {secondsLeft % 60}s
      </p>
      <p>Continue to stay on the page{cognitoUser && ' or Sign Out'}</p>
    </Modal>
  );
};

export default PopOverTimeOut;
