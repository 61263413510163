import './MotorHoldingPage.scss';

import { ButtonCard, Card, CarIcon, Text, Title } from '@boi/core/lib';
import { colors } from '@boi/core/lib/styles';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import MobileBackButton from '../../components/MobileBackButton/MobileBackButton';
import { commonPageStylesIdentifier, iconsAndTitles } from '../../constants';
import { isAgent } from '../../helpers';

const MotorHoldingPage = () => {
  const history = useHistory();

  const className = 'c-MotorHoldingForm';

  const phoneLink = phoneStr => <a href={`tel:${phoneStr}`}>{phoneStr}</a>;

  const MOTOR_CLOSE_DATE = <b>17 April 2025</b>;

  const NEW_MOTOR_INSURANCE = `01 488 4062`;

  const MOTOR_FIRST_PARA = <>Bank of Ireland Insurance Services will no longer facilitate the general sale of New Motor
    Insurance policies from close of business {MOTOR_CLOSE_DATE}.</>;

  const MOTOR_SECOND_PARA = <>For new motor quotes, please call us on {phoneLink(NEW_MOTOR_INSURANCE)}</>;

  const MOTOR_THIRD_PARA = <>Existing Insurance policies are unaffected and will continue as normal.</>;

  const MOTOR_FOURTH_PARA = <>For breakdown assistance, claims, renewal, policy amendments, please call our services
    team on {phoneLink(`01 290 1958`)}.</>;

  const MOTOR_FIFTH_PARA = <>The SuperSaver offer is unaffected, and customers who wish to redeem their Insurance offer,
    should call us on {phoneLink(NEW_MOTOR_INSURANCE)}.</>;

  const MOTOR_BOI = <>Bank of Ireland Insurance Services</>;

  return (
    <div className={`${className}__container`}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} pageJumpCount={-1} />
      </div>
      <div className={`${className}__titleContainer`}>
        <div className={`${className}__titleText`}>
          <Text size="inherit">{iconsAndTitles.car.title} Insurance</Text>
        </div>
        <div className={`${className}__line`} />
        <div className={`${className}__motorCard`}>
          <Card>
            <div className={`${className}__cardIcon`}>
              <CarIcon color={colors.primaryColor} />
            </div>
            <br />
            <div className={`${className}__cardTitle`}>
              <Title>Change to our Motor Insurance product</Title>
            </div>
            <br />
            <div className={`${className}__cardContent`}>
              <p className={`${className}__cardContent`}>{MOTOR_FIRST_PARA}</p>
              <p className={`${className}__cardContent`}>{MOTOR_SECOND_PARA}</p>
              <p className={`${className}__cardContent`}>{MOTOR_THIRD_PARA}</p>
              <p className={`${className}__cardContent`}>{MOTOR_FOURTH_PARA}</p>
              <p className={`${className}__cardContent`}>{MOTOR_FIFTH_PARA}</p>
              <p className={`${className}__BOIString`}>{MOTOR_BOI}</p>
            </div>
          </Card>
        </div>
        <br /><br />
        <div className={`${className}__cardTitle`}>
          <Title>Explore our other insurance products. Choose a coverage and get a quote</Title>
        </div>
        <br />
        <div className={`${className}__altInsuranceCards`}>


          <div className={`${className}__ButtonGrid`}>
            <div className={`${className}__ButtonContainer`}>
              <Link
                to={'/wallet/product/home'}
                data-ga
                id="WalletLandingPage__homeButtonCard"
                data-testid="WalletLandingPage__homeButtonCard"
              >
                <ButtonCard
                  icon="home"
                  text="Home"
                  color={colors.primaryColor}
                />
              </Link>
            </div>


            {!isAgent() && (<div
              className={`${className}__ButtonContainer`}
            >
              <Link
                to={'/wallet/product/travel'}
                data-ga
                id="WalletLandingPage__travelButtonCard"
                data-testid="WalletLandingPage__travelButtonCard"
              >
                <ButtonCard
                  icon="travel"
                  text="Travel"
                  color={colors.primaryColor}
                />
              </Link>
            </div>)}
          </div>
        </div>
      </div>
    </div>);
};

export default MotorHoldingPage;
