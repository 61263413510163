import 'react-app-polyfill/ie11';
import './index.scss';
import './amplify.config';
import './utils/polyfills';
import './scripts/googleAnalyticsHandlers';

import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';

import { BOIRoutes } from './BOIRoutes';
import CognitoProvider from './CognitoUtils';
import ScrollToTop from './components/ScrollToTop';
import { LOCATION_HISTORY_STORAGE } from './constants/sessionStorage';
import { removeFromSessionStorage } from './helpers';
import * as serviceWorker from './serviceWorker';
import { UserContextProvider } from './UserContext';
import UserProfile from './UserProfile';

if (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'ci') {
  const axe = require('react-axe');
  axe(React, ReactDOM, 1000);
}

const onUnloadApp = () => {
  const pageIsReloading =
    window.performance &&
    performance.navigation.type === performance.navigation.TYPE_RELOAD;
  if (!pageIsReloading) {
    /* If the page is not reloading remove the users navigation history */
    removeFromSessionStorage(LOCATION_HISTORY_STORAGE);
  }
};

window.onbeforeunload = onUnloadApp;

if (
  window.navigator.userAgent.indexOf('MSIE') >= 0 ||
  (!!window.MSInputMethodContext && !!document.documentMode)
) {
  document.getElementById('internetExplorerWarning').style.display = 'block';
} else {
  const ConnectedApp = () => {
    return (
      <UserContextProvider>
        <HashRouter basename="/">
          <ScrollToTop />
          <CognitoProvider>
            {cognitoUser => (
              <UserProfile cognitoUser={cognitoUser}>
                <BOIRoutes />
              </UserProfile>
            )}
          </CognitoProvider>
        </HashRouter>
      </UserContextProvider>
    );
  };

  ReactDOM.render(<ConnectedApp />, document.getElementById('root'));

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.register();
}
