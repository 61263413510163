/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

export interface BOIRoute {
  url: string;
  showInMenu: boolean;
  displayBack: boolean;
}

export const routes = {
  wallet: { url: '/wallet', showInMenu: true, displayBack: false },
  documents: { url: '/documents', showInMenu: true, displayBack: true },
  // More: { url: '/more', showInMenu: true },
  comingSoon: { url: '/wallet/comingsoon', showInMenu: true, displayBack: false },
  policyLinks: {
    url: '/documents/policy/',
    showInMenu: false,
    displayBack: true
  },
  productLandingPage: {
    url: '/wallet/product/',
    showInMenu: false,
    displayBack: true
  },
  viewRenewalQuote: {
    url: '/wallet/viewRenewalQuote/',
    showInMenu: false,
    displayBack: true
  },
  renewPolicyPage: {
    url: '/wallet/renewPolicy/',
    showInMenu: false,
    displayBack: true
  },
  amendPolicyPage: {
    url: '/wallet/amendPolicy/',
    showInMenu: false,
    displayBack: true
  },
  submitClaimPage: {
    url: '/wallet/submitClaim/',
    showInMenu: false,
    displayBack: true
  },
  medicalAssistance: {
    url: '/wallet/medicalAssistance/',
    showInMenu: false,
    displayBack: true
  },
  breakdownAssistance: {
    url: '/wallet/breakdownAssistance/',
    showInMenu: false,
    displayBack: true
  },
  makePayment: {
    url: '/wallet/makePayment/',
    showInMenu: false,
    displayBack: true
  },
  renewalMakePayment: {
    url: '/wallet/renewal/makePayment/',
    showInMenu: false,
    displayBack: true
  },
  viewQuote: {
    url: '/wallet/viewQuote/',
    showInMenu: false,
    displayBack: true
  },
  paymentThankYou: {
    url: '/wallet/paymentthankyou/',
    showInMenu: false,
    displayBack: false
  },
  getQuoteThankYouPage: {
    url: '/wallet/getquotethankyou/',
    showInMenu: false,
    displayBack: false
  },
  getQuotePage: {
    url: '/wallet/getquote/',
    showInMenu: false,
    displayBack: false
  },
  getGenericQuotePage: {
    url: '/wallet/getgenericquote/',
    showInMenu: false,
    displayBack: false
  },
  getDeclarationPage: {
    url: '/wallet/declaration/',
    showInMenu: false,
    displayBack: false
  },
  loginPage: {
    url: '/login',
    showInMenu: false,
    displayBack: false
  },
  confirmLoginCode: {
    url: '/confirmLoginCode',
    showInMenu: false,
    displayBack: false
  },
  logoutPage: {
    url: '/logout',
    showInMenu: false,
    displayBack: false
  },
  forceLogin: {
    url: '/customer-login/',
    showInMenu: false,
    displayBack: false
  },
  newPassword: {
    url: '/newpassword',
    showInMenu: false,
    displayBack: false
  },
  resetPassword: {
    url: '/resetpassword',
    showInMenu: false,
    displayBack: false
  },
  manageMyInsuranceDashboard: {
    url: '/manage',
    showInMenu: false,
    displayBack: true
  },
  manageMyInsuranceProduct: {
    url: '/manage/product/',
    showInMenu: false,
    displayBack: false
  },
  genericErrorPage: {
    url: '/wallet/error/',
    showInMenu: false,
    displayBack: false
  },
  signUp: {
    url: '/signup/',
    showInMenu: false,
    displayBack: false
  },
  verifyEmail: {
    url: '/verifyEmail/',
    showInMenu: false,
    displayBack: false
  },
  viewMultipleQuotes: {
    url: '/wallet/viewMultipleQuotes/',
    showInMenu: false,
    displayBack: true
  },
  viewMultiplePolicies: {
    url: '/manage/viewMultiplePolicies/',
    showInMenu: false,
    displayBack: true
  },
  marketingConsent: {
    url: '/wallet/consent/',
    showInMenu: false,
    displayBack: true
  },
  agentLatestQuotes: {
    url: '/wallet/latest-quotes/',
    showInMenu: false,
    displayBack: true,
  },
  termsAndConditions: {
    url: '/wallet/terms-and-conditions/',
    showInMenu: false,
    displayBack: true,
  },
  retreiveUserPage: {
    url: '/wallet/user/',
    showInMenu: false,
    displayBack: false
  },
  createAccount: {
    url: '/wallet/createAccount/',
    showInMenu: false,
    displayBack: false
  },
  statementOfFact: {
    url: '/wallet/statementOfFact/',
    showInMenu: false,
    displayBack: true
  },
  searchAllQuotes: {
    url: '/wallet/searchQuotes/',
    showInMenu: false,
    displayBack: false
  },
  motorHoldingPage: {
    url: '/wallet/motorHoldingPage/',
    showInMenu: false,
    displayBack: false
  }
};
