import { IdipUrl } from '../../../constants/externalLinks';

export const motorLinks = [
  {
    label: "Terms of use",
    url: "//boi.com/insurancemotortou"
  },
  {
    label: "Policy Booklet",
    url: "//boi.com/insurancemotorpolicybooklet"
  },
  {
    label: "Insurance product information documents Car – Popular",
    url: IdipUrl.Popular
  },
  {
    label: "Insurance product information documents Car – Prestige",
    url: IdipUrl.Prestige
  }
];

export const homeLinks = [
  {
    label: "Terms of use",
    url: "//boi.com/insurancehometou"
  },
  {
    label: "Home insurance policy booklet",
    url: "//boi.com/insurancehomepolicybooklet" },
  {
    label: "Home insurance for your holiday home or residential investment property policy booklet",
    url: "//boi.com/insurancehomepolicybookletHHRIP" },
  {
    label: "Home Insurance Main Private Dwelling IPID Download",
    url: "//boi.com/insurancehomeipidmpd" },
  {
    label: "Home Insurance RIPs and HH IPID Download",
    url: "//boi.com/insurancehomeipidhhrip"
  }
];

export const travelLinks = [];
