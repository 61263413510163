/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import { parse } from 'date-fns';

import { basePath } from '../../constants/commonConstants';
import { makeRequest, rawToIrishFormat } from '../../helpers';

const EXCESS_CODES = {
  "WINTER_SPORTS_EXPENSE": 1,
  "PMI_EXPENSE": 2,
  "CAR_HIRE_EXPENSE": 3,
  "BUSINESS_EXPENSE": 4
};

const getLatestTravelQuote = async (props = {}) => {
  return await makeRequest({
    method: 'GET',
    url: `${basePath}/travel/travel_quotes/latest`,
    history: props.history
  });
};

export const hasPurchasedTravelPolicy = async (cognitoAccessTokenKey, props) => {
  if (!cognitoAccessTokenKey) {
    // If cognitoAccessTokenKey is undefined just return false because the call is going to fail
    return false;
  }
  return getLatestTravelQuote(props).then(response => {
    if (response && response.travel_quote) {
      const data = formatResponse(response);
      return !!data.policy_reference;
    }
    return false;
  });
};

export const getLatestUnpurchasedTravelQuote = (cognitoAccessTokenKey, props) => {
  if (!cognitoAccessTokenKey) {
    // If cognitoAccessTokenKey is undefined just return an object that specifies no token was passed
    return { hasNoAccessToken: true };
  }
  return getLatestTravelQuote(props).then(response => {
    if (response && response.travel_quote) {
      const data = formatResponse(response);
      return !data.policy_reference ? data : {};
    }
    return {};
  });
};

function getPartnerDetails(partnerDetails) {
  if (!partnerDetails) return {};
  return {
    partnerFirstName: partnerDetails.first_name,
    partnerLastName: partnerDetails.last_name,
    partnerDOB: rawToIrishFormat(partnerDetails.date_of_birth)
  };
}

// This value needs to be set as a Date value or undefined so Formik isValidSync works correctly
const getTravelToDateDependingOnTripType = (tripType, travelToDate) => tripType === 1 ? travelToDate : undefined;

/* If start date is before today remove it
   This value needs to be set as a Date value or undefined so Formik isValidSync works correctly */
const getTravelFromDate = (travelFromDate) => parse(travelFromDate.substr(0, 10), 'YYYY-MM-DD') > new Date() ? travelFromDate : undefined;

function formatResponse(data) {
  const { quote_reference, policy_reference } = data;
  const travelQuote = data.travel_quote;
  const coverQuote = travelQuote.cover_details;
  const policyHolderDetails = travelQuote.policy_holder_details;
  const partnerDetails = getPartnerDetails(travelQuote.additional_holder_details);
  const excess = coverQuote.addons_selected.map(addon => addon.id);

  const values = {
    excess,
    carHireExcess: excess.includes(EXCESS_CODES.CAR_HIRE_EXPENSE),
    winterSportsExcess: excess.includes(EXCESS_CODES.WINTER_SPORTS_EXPENSE),
    businessExpenseExcess: excess.includes(EXCESS_CODES.BUSINESS_EXPENSE),
    tripType: `${coverQuote.trip_type}`,
    travelFromDate: getTravelFromDate(coverQuote.start_date),
    travelToDate: getTravelToDateDependingOnTripType(coverQuote.trip_type, coverQuote.end_date),
    destinationZone: coverQuote.destination_zone_id,
    destinationCountry: coverQuote.destination_country,
    insuredPerson: `${coverQuote.insured_person_type}`,
    numOfChildren: policyHolderDetails.number_of_children || undefined,
    title: policyHolderDetails.title.toUpperCase().replace(".", ""),
    firstName: policyHolderDetails.first_name,
    lastName: policyHolderDetails.last_name,
    dob: rawToIrishFormat(policyHolderDetails.date_of_birth),
    phoneNo: policyHolderDetails.home_telephone,
    email: policyHolderDetails.email,
    addressLine1: policyHolderDetails.address.address_line1,
    addressLine2: policyHolderDetails.address.address_line2,
    addressLine3: policyHolderDetails.address.address_line3,
    addressCounty: policyHolderDetails.address.address_line4,
    addressEircode: policyHolderDetails.address.post_code,
    addressTown: policyHolderDetails.address.town,
    coverAbroad: travelQuote.additional_questions.length > 0 ? 'yes' : 'no',
    bankOfIrelandCustomer: policyHolderDetails.boi_customer,
    promoCode: travelQuote.promotion_code,
    provider: travelQuote.additional_questions.length > 0 ? travelQuote.additional_questions.find(question => question.id === 145).value : undefined, // code for private medical insurance question
    ...partnerDetails
  };
  return {
    policy_reference,
    quote_reference,
    values
  };
}
