// @flow

import '../PremiumBreakdown.scss';

import { AccordionCard, WideDivider } from '@boi/core/lib';
import React from 'react';

import { dividerColor } from '../../../constants/colors';
import type { MotorPremiumOptionalExtrasType, MotorQuoteType, MotorViewQuoteTypes } from '../../../types';
import { PremiumBreakdownRow } from '../PremiumBreakdownRow';

interface Props {
  premiumDetails: MotorQuoteType,
  values: MotorViewQuoteTypes,
}

const MotorPremiumBreakdown = (props: Props) => {
  const className = 'c-PremiumBreakdown';

  const { premiumDetails, values } = props;

  let totalPremiumOptionalExtras = [];
  const extras = {
    'Personal Protection Plus': 'personalProtectionPlus',
    'NCD Protection': 'ncdProtection',
    'Additional Benefits Plus': 'additionalBenefitsPlus'
  };

  const prices = {
    'personalProtectionPlus': 0,
    'ncdProtection': 0,
    'additionalBenefitsPlus': 0
  };
  let optionalExtras = false;

  const setOptionalExtra = (valuesIsSet: boolean, price: number = 0) => {
    if (valuesIsSet) {
      optionalExtras = true;
      return price;
    }
    return 0;
  };

  const setPremiumOptionalExtras = (premiumOptionalExtras: MotorPremiumOptionalExtrasType[]) => {
    totalPremiumOptionalExtras = premiumOptionalExtras;

    totalPremiumOptionalExtras.map((optionalExtra: MotorPremiumOptionalExtrasType) => {
      const extraFieldKey = extras[optionalExtra.text];
      prices[extraFieldKey] = setOptionalExtra(values[extraFieldKey], optionalExtra.amount);
    });
  };

  if (premiumDetails) {
    const {
      premium_optional_extras = []
    } = premiumDetails;
    setPremiumOptionalExtras(premium_optional_extras);
  }

  const renderPremiumOptionalExtras = () => {
    if (optionalExtras) {
      return (
        <>
          <WideDivider color={dividerColor} height={1} />
          <div className={`${className}__section`}>
            <div className={`${className}__heading`}>Optional Extras</div>
            {prices.personalProtectionPlus > 0 ?
              <PremiumBreakdownRow
                label={`Personal Protection Plus`}
                price={prices.personalProtectionPlus}
              /> : ''}
            {renderNCDProtection()}
            {renderAdditionalBenefitsPlus()}
          </div>
        </>
      );
    }
  };

  const renderNCDProtection = () => {
    if (prices.ncdProtection > 0) {
      return (
        <PremiumBreakdownRow
          label={`NCD Protection`}
          price={prices.ncdProtection}
        />
      );
    }
  };

  const renderAdditionalBenefitsPlus = () => {
    if (prices.additionalBenefitsPlus > 0) {
      return (
        <PremiumBreakdownRow
          label={`Additional Benefits Plus`}
          price={prices.additionalBenefitsPlus}
        />
      );
    }
  };

  return (
    <AccordionCard
      id={`MotorPremiumBreakdown__viewPremium`}
      label="View premium breakdown"
      status="none"
      labelStyle="black"
      isOnInit={true}
      isOnState={true}
      childContainerClass={`${className}__noPaddingAccordion`}
      customLabelClass={`${className}__accordionLabel`}
    >
      <div className={`${className}`}>
        <div className={`${className}__section`}>
          <PremiumBreakdownRow
            label={`Total No Claims Discount`}
            price={premiumDetails.total_no_claims_discount}
          />
          <PremiumBreakdownRow
            label={`Total payment after No Claims Discount`}
            price={premiumDetails.total_payment_after_no_claims_discount}
          />
        </div>
        {premiumDetails.total_bank_discount > 0 ?
          <>
            <WideDivider color={dividerColor} height={1} />
            <div className={`${className}__section`}>
              <PremiumBreakdownRow label={`Includes bank discount of ${premiumDetails.total_bank_discount_percentage}%`}
                                   price={premiumDetails.total_bank_discount} />
              <PremiumBreakdownRow
                label={`Total payment after Bank Discounts`}
                price={premiumDetails.total_payment_after_bank_discount}
              />
            </div>
          </> : <></>}
        {renderPremiumOptionalExtras()}
        <WideDivider color={dividerColor} height={1} />
        <div className={`${className}__section`}>
          <div className={`${className}__heading`}>Payment Options</div>
          <PremiumBreakdownRow label={'Single Payment'} price={premiumDetails.single_payment} />
        </div>
        <WideDivider color={dividerColor} height={1} />
        <div className={`${className}__section`}>
          <div className={`${className}__heading`}>If paid monthly</div>
          {/* @amanda to be removed after day 0.2  */}
          <PremiumBreakdownRow
            label={'If paid monthly, cost will be spread across 10 instalments'}
            price={premiumDetails.direct_debit_amount}
          />
          <PremiumBreakdownRow
            label={'Plus two months deposit'}
            price={premiumDetails.card_deposit_amount}
          />
          <PremiumBreakdownRow
            label={'Total annual charge for payments by installment'}
            price={premiumDetails.total_annual_charge_for_payments_by_installment}
          />
          <PremiumBreakdownRow
            label={'Total if paid by installment'}
            price={premiumDetails.total_if_paid_by_installment}
          />
        </div>
        <WideDivider color={dividerColor} height={1} />
        <div className={`${className}__section`}>
          <PremiumBreakdownRow
            label={'The above includes Government Levy of:'}
            price={premiumDetails.government_levy}
          />
        </div>
      </div>
    </AccordionCard>
  );
};

export default MotorPremiumBreakdown;
