/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../../styles/commonFormStyles.scss';
import './AboutYouForm.scss';

import {
  AccordionText,
  AutoAddressAutocomplete,
  ButtonGroup,
  DatePicker,
  Dropdown,
  InputField,
  WideDivider
} from '@boi/core/lib';
import { Form, FormikProps, getIn, setNestedObjectValues, withFormik } from 'formik';
import React, { Component } from 'react';
import * as yup from 'yup';

import EmailInput from '../../../components/EmailInput';
import { commonFormStylesIdentifier } from '../../../constants';
import { dividerColor } from '../../../constants/colors';
import {
  ABOUT_YOU_INITIAL_VALUES,
  ADDRESS_TOOLTIP_MSG_PRIMARY,
  ADDRESS_TOOLTIP_MSG_SECONDARY,
  OPTION_TITLE_VALUES_CONSTANT,
  OTHER_TITLE_CONSTANT,
  PREPOPULATION_PREFORMED,
  TITLES_CONSTANT,
  TITLES_TO_SHOW
} from '../../../constants/home';
import { HOME_INSURANCE_TYPE } from '../../../constants/insuranceTypeConstants';
import { localPhoneNumber } from '../../../constants/phoneNumbers';
import { isFieldError } from '../../../helpers';
import { saveData } from '../../../helpers/HomeCommonMethods';
import { phoneNumberMask } from '../../../helpers/NumberMask';
import { getAppliedAddress } from '../../../services/homeServices';
import AboutYouFormBOIQuestions from './AboutYouFormChildComponents/AboutYouFormBOIQuestions';
import AboutYouFormMisc from './AboutYouFormChildComponents/AboutYouFormMisc';
import AboutYouJointlyForm from './AboutYouFormChildComponents/AboutYouJointlyForm';
import AboutYouFormYupSchema from './AboutYouFormYupSchema';
import {
  getAddressErrorMessage,
  getDefaultCorrespondenceAddressValue,
  getDefaultDisplayAddressValue,
  modifyManualAddressData,
  showSpinner
} from './AboutYouUtil';
import AddressInfo from './AddressInfo';
import type { AboutYouFormValues } from './models/AboutYouFormModels';
import { Address, Props, State } from './models/AboutYouFormModels';

const className = 'c-AboutYouForm';

class AboutYouForm extends Component<
  Props & FormikProps<AboutYouFormValues>,
  State
> {
  state = {
    emailExisting: false,
    addressloader: false,
    correspondenceLoader: false,
    showManualAddress: false,
    showCorrespondenceManualAddress: false,
    promoCodeValid: false,
    promoCodeRequestSent: false
  };

  setAddressFieldData = (
    correspondenceAddr: boolean,
    key: string,
    value: mixed
  ) => {
    const { setFieldValue } = this.props;
    if (!correspondenceAddr) {
      setFieldValue(key, value);
    } else {
      setFieldValue(`correspondence_${key}`, value);
    }
  };

  activateManualAddress = (correspondenceAddr: boolean) => {
    if (!correspondenceAddr) {
      this.setState({
        ...this.state,
        showManualAddress: true
      });
    } else {
      this.setState({
        ...this.state,
        showCorrespondenceManualAddress: true
      });
    }
  };

  updatePromoCode = (flag: boolean) => {
    this.setState({ promoCodeValid: flag });
  };

  updatePromoCodeRequestSent = (flag: boolean) => {
    this.setState({promoCodeRequestSent: flag})
  }

  // eslint-disable-next-line complexity
  prepoulateWithLatest = () => {
    if (
      this.props.aboutYouFormTrackCount > 0 ||
      this.props.prepopulationStatus === PREPOPULATION_PREFORMED
    ) {
      if (this.props.values.eircode) {
        this.setSelectedAddress(true, {
          eircode: this.props.aboutYouValues.eircode,
          addressLine1: this.props.aboutYouValues.addressLine1,
          county: this.props.aboutYouValues.county
        });
      }
      if (
        !this.props.aboutYouValues.is_correspondence_address &&
        this.props.aboutYouValues.correspondence_eircode
      ) {
        this.setSelectedAddress(false, {
          eircode: this.props.aboutYouValues.correspondence_eircode,
          addressLine1: this.props.aboutYouValues.correspondence_addressLine1,
          county: this.props.aboutYouValues.correspondence_county
        });
      }
      this.props.setValues({
        ...this.props.values,
        ...this.props.aboutYouValues,
        displayAddress: getDefaultDisplayAddressValue(
          this.props.aboutYouValues
        ),
        correspondence_displayAddress: getDefaultCorrespondenceAddressValue(
          this.props.aboutYouValues
        )
      });
      this.setSelectedAddress(false, this.props.aboutYouValues);
      this.setSelectedAddress(true, this.props.aboutYouValues);
    }
  };

  //eslint-disable-next-line complexity
  touchAllFields = () => {
    if (
      this.props.aboutYouValues &&
      this.props.aboutYouValues.policyLength !== undefined &&
      (this.props.aboutYouValues.policyLength > 0 ||
        this.props.aboutYouValues.policyLength === 0) &&
      this.props.aboutYouFormTrackCount === 0
    ) {
      this.props.setFieldTouched(
        setNestedObjectValues(this.props.values !== '', true)
      );
    } else if (
      this.props.aboutYouFormTrackCount > 0 ||
      this.props.prepopulationStatus === PREPOPULATION_PREFORMED
    ) {
      this.props.setTouched(setNestedObjectValues(this.props.values, true));
    }
  };

  componentDidMount() {
    if (this.props.formOpened) {
      this.props.formOpened('aboutYou');
    }
    if (this.props.isPreFilled) {
      this.props.validateForm().then((errors: {}) => {
        this.props.validForm('aboutYou', Object.keys(errors).length === 0);
      });
    }
    this.touchAllFields();
    this.prepoulateWithLatest();
  }

  // eslint-disable-next-line complexity
  async componentDidUpdate(prevProps: Props) {
    if (
      this.props.aboutYouFormTrackCount !== prevProps.aboutYouFormTrackCount ||
      this.props.prepopulationStatus !== prevProps.prepopulationStatus
    ) {
      this.touchAllFields();
    }
    if (this.props.prepopulationStatus !== prevProps.prepopulationStatus) {
      this.prepoulateWithLatest();
    }
  }

  isFieldError = (fieldName: string) => {
    const { touched, errors } = this.props;
    return touched[fieldName] && errors[fieldName] !== undefined;
  };

  setSelectedAddress(correspondenceAddr: boolean, address: Address) {
    const {
      setFieldValue,
      setAboutYouObjForMultipleUpdatedFields
    } = this.props;

    const addressDetails = correspondenceAddr
      ? {
        addressLine1: 'addressLine1',
        addressLine2: 'addressLine2'
      }
      : {
        addressLine1: 'correspondence_addressLine1',
        addressLine2: 'correspondence_addressLine2'
      };
    if (correspondenceAddr) {
      this.setState({ ...this.state, addressloader: true });
    } else {
      this.setState({ ...this.state, correspondenceLoader: true });
    }

    const setFieldData = (key: string, value: mixed) => {
      if (correspondenceAddr) {
        setFieldValue(key, value);
        saveData('aboutYouObj', key, value);
      } else {
        setFieldValue(`correspondence_${key}`, value);
        saveData('aboutYouObj', `correspondence_${key}`, value);
      }
    };

    const saveDisplayAddress = (appliedAddress: any) => {
      if (correspondenceAddr) {
        saveData(
          'aboutYouObj',
          'displayAddress',
          appliedAddress.addressLine1 +
          ',' +
          appliedAddress.addressLine2 +
          ',' +
          appliedAddress.county
        );
      } else {
        saveData(
          'aboutYouObj',
          'correspondence_displayAddress',
          appliedAddress.correspondence_addressLine1 +
          ',' +
          appliedAddress.correspondence_addressLine2 +
          ',' +
          appliedAddress.correspondence_county
        );
      }
    };

    const setAddress = (appliedAddress: any) => {
      // eslint-disable-next-line complexity
      Object.entries(appliedAddress).forEach(
        // eslint-disable-next-line complexity
        ([key, value]: [string, mixed]) => {
          switch (key) {
            case 'addressLine1':
            case 'address_line1':
              setFieldValue(addressDetails.addressLine1, value);
              saveData('aboutYouObj', addressDetails.addressLine1, value);
              appliedAddress[addressDetails.addressLine1] = value;
              delete appliedAddress.address_line1;
              break;
            case 'addressLine2':
            case 'address_line2':
              setFieldValue(addressDetails.addressLine2, value);
              saveData('aboutYouObj', addressDetails.addressLine2, value);
              appliedAddress[addressDetails.addressLine2] = value;
              delete appliedAddress.address_line2;
              break;
            default:
              setFieldData(key, value);
              if (!correspondenceAddr) {
                appliedAddress[`correspondence_${key}`] = value;
                delete appliedAddress[key];
              }
          }
        }
      );
      saveDisplayAddress(appliedAddress);
      return appliedAddress;
    };
    if (!address?.eircode) {
      const manualAddr = modifyManualAddressData(address);
      setAddress(manualAddr);
      setAboutYouObjForMultipleUpdatedFields(manualAddr);
      this.setState({
        ...this.state,
        addressloader: false,
        correspondenceLoader: false
      });
    } else {
      getAppliedAddress({ eircode: address.eircode })
        .then((appliedAddress: any) => {
          if (appliedAddress.length > 0 && appliedAddress[0].eircode) {
            const updatedAddr = setAddress(appliedAddress[0]);
            setAboutYouObjForMultipleUpdatedFields(updatedAddr);
          }
          this.setState({
            ...this.state,
            addressloader: false,
            correspondenceLoader: false
          });
        })
        .catch(() => {
          this.setState({
            ...this.state,
            addressloader: false,
            correspondenceLoader: false
          });
        });
    }
  }

  // eslint-disable-next-line complexity
  showEirCodeError = () => {
    return this.props.values.addressLine1 !== '' &&
      this.props.values.town !== '' &&
      this.props.values.county !== '';
  };

  // eslint-disable-next-line complexity
  showCorrespondenceEirCodeError = () => {
    return this.props.values.correspondence_addressLine1 !== '' &&
      this.props.values.correspondence_town !== '' &&
      this.props.values.correspondence_county !== '';
  };

  // eslint-disable-next-line complexity
  getEirCodeErrorStatus = (val: boolean) => {
    if (
      this.props.values.is_correspondence_address !== '' &&
      !this.props.values.is_correspondence_address
    ) {
      if (this.state.showCorrespondenceManualAddress !== val) {
        this.setState({ ...this.state, showCorrespondenceManualAddress: val });
      }
    } else if (this.state.showManualAddress !== val) {
      this.setState({ ...this.state, showManualAddress: val });
    }
  };

  getAddressFields = (manualAddress: boolean) => {
    const {
      addressLine1,
      addressLine2,
      county,
      eircode,
      town
    } = this.props.values;
    const address = {
      addressLine1,
      addressLine2,
      town,
      county,
      eircode
    };
    const { errors } = this.props;
    if (manualAddress) {
      return (
        <AddressInfo
          className={className}
          addressLine1={this.props.values.addressLine1}
          addressLine2={this.props.values.addressLine2}
          town={this.props.values.town}
          county={this.props.values.county}
          eircode={this.props.values.eircode}
          handleBlur={this.props.handleBlur}
          handleChange={this.props.handleChange}
          setFieldValue={this.props.setFieldValue}
          handleCustomBlur={this.props.handleCustomBlur}
          setFieldTouched
          isFieldError
          touched
          errors
          townAndCountyData={this.props.townAndCountyData}
          selectedAppliedAddressIndex={
            this.props.values.selectedAppliedAddressIndex
          }
          setAddressFieldData={this.setAddressFieldData}
          correspondenceAddr={false}
          findMyAddress={false}
          showEircode={this.state.showManualAddress}
          displayAddress={this.props.values.displayAddress}
          activateManualAddressCallback={(val: boolean) => {
            this.activateManualAddress(val);
          }}
        />
      );
    } else {
      if (county === '' || errors.eircode) return null;
      return (
        <AddressInfo
          className={className}
          {...address}
          handleBlur={this.props.handleBlur}
          handleChange={this.props.handleChange}
          setFieldValue={this.props.setFieldValue}
          handleCustomBlur={this.props.handleCustomBlur}
          setFieldTouched
          isFieldError
          touched
          errors
          townAndCountyData={this.props.townAndCountyData}
          selectedAppliedAddressIndex={
            this.props.values.selectedAppliedAddressIndex
          }
          setAddressFieldData={this.setAddressFieldData}
          correspondenceAddr={false}
          findMyAddress={false}
          showEircode={this.state.showManualAddress}
          displayAddress={this.props.values.displayAddress}
          activateManualAddressCallback={(val: boolean) => {
            this.activateManualAddress(val);
          }}
        />
      );
    }
  };

  // eslint-disable-next-line complexity
  getCorrespondingAddress = (manualAddress: boolean) => {
    const showCorrespondingSection = () => {
      return (
        Boolean(is_correspondence_address) === true ||
        is_correspondence_address === ''
      );
    };
    const {
      correspondence_addressLine1,
      correspondence_addressLine2,
      correspondence_county,
      correspondence_eircode,
      correspondence_town,
      is_correspondence_address
    } = this.props.values;
    const address = {
      addressLine1: correspondence_addressLine1,
      addressLine2: correspondence_addressLine2,
      town: correspondence_town,
      county: correspondence_county,
      eircode: correspondence_eircode
    };
    const { errors } = this.props;
    if (showCorrespondingSection()) return null;
    if (manualAddress) {
      return (
        <AddressInfo
          className={className}
          addressLine1={this.props.values.correspondence_addressLine1}
          addressLine2={this.props.values.correspondence_addressLine2}
          town={this.props.values.correspondence_town}
          county={this.props.values.correspondence_county}
          eircode={this.props.values.correspondence_eircode}
          handleBlur={this.props.handleBlur}
          handleChange={this.props.handleChange}
          setFieldValue={this.props.setFieldValue}
          handleCustomBlur={this.props.handleCustomBlur}
          setFieldTouched
          isFieldError
          touched
          errors
          townAndCountyData={this.props.townAndCountyData}
          selectedAppliedAddressIndex={
            this.props.values.correspondence_selectedAppliedAddressIndex
          }
          setAddressFieldData={this.setAddressFieldData}
          correspondenceAddr={true}
          findMyAddress={false}
          showEircode={this.state.showCorrespondenceManualAddress}
          displayAddress={this.props.values.correspondence_displayAddress}
          activateManualAddressCallback={(val: boolean) => {
            this.activateManualAddress(val);
          }}
        />
      );
    } else {
      if (correspondence_county === '' || errors.correspondenceEircode)
        return null;
      return (
        <AddressInfo
          className={className}
          {...address}
          handleBlur={this.props.handleBlur}
          handleChange={this.props.handleChange}
          setFieldValue={this.props.setFieldValue}
          handleCustomBlur={this.props.handleCustomBlur}
          setFieldTouched
          isFieldError
          touched
          errors
          townAndCountyData={this.props.townAndCountyData}
          selectedAppliedAddressIndex={
            this.props.values.correspondence_selectedAppliedAddressIndex
          }
          setAddressFieldData={this.setAddressFieldData}
          correspondenceAddr={true}
          findMyAddress={false}
          showEircode={this.state.showManualAddress}
          displayAddress={this.props.values.correspondence_displayAddress}
          activateManualAddressCallback={(val: boolean) => {
            this.activateManualAddress(val);
          }}
        />
      );
    }
  };

  // eslint-disable-next-line complexity
  render() {
    const {
      errors,
      isSubmitting,
      isValid,
      touched,
      values,
      handleChange,
      setAboutYouObjForUpdatedFields,
      handleBlur,
      setFieldValue,
      setFieldTouched
    } = this.props;
    if (!isSubmitting && isValid !== this.props.aboutYouValid) {
      this.props.validForm('aboutYou', isValid);
      if (this.props.setUpdatedAboutYouValues) {
        this.props.setUpdatedAboutYouValues(this.props.values);
      }
    }

    const handleCustomBlur = (
      fieldName: string,
      e: SyntheticEvent<HTMLInputElement>
    ) => {
      setAboutYouObjForUpdatedFields(fieldName, values[fieldName]);
      handleBlur(e);
    };

    const getBankOfIrelandQuestion = () => {
      return (
        <AboutYouFormBOIQuestions
          className={className}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          setAboutYouObjForUpdatedFields={setAboutYouObjForUpdatedFields}
          errors={errors}
          values={values}
          touched={touched}
          handleCustomBlurFunc={(e: any, fieldName: string) => {
            handleCustomBlur.bind(e, fieldName);
          }}
        />
      );
    };

    const clearPropertyJoinedFields = (value: string) => {
      if (value === 'false') {
        const resetFields = [
          'jointlyOwnedDetails.title',
          'jointlyOwnedDetails.otherTitle',
          'jointlyOwnedDetails.firstName',
          'jointlyOwnedDetails.lastName',
          'jointlyOwnedDetails.dateOfBirth'
        ];

        const { setFieldValue, setFieldTouched } = this.props;

        resetFields.forEach((field: string) => {
          setFieldValue(field, '');
          setFieldTouched(field, false);
        });
      }
    };

    const renderOtherTitleInputField = () => {
      return (
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor="otherTitle"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
            data-testid={`${commonFormStylesIdentifier}__otherTitleLabel`}
          >
            Other title
          </label>
          <span className={`${className}__input`}>
            <InputField
              error={this.isFieldError('otherTitle')}
              errorMessage={errors.otherTitle}
              name="otherTitle"
              placeholder="Type here"
              touched={touched.otherTitle}
              value={values.otherTitle}
              onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                handleChange(e);
                saveData('aboutYouObj', 'otherTitle', e.target.value);
              }}
              type="text"
              onBlur={handleCustomBlur.bind(this, 'otherTitle')}
            />
          </span>
        </div>
      );
    };

    const getJointlyOwnedFields = () => {
      const getJointlyOwnedFieldValue = (name: string) =>
        getIn(values, name) || '';

      const handleCustomBlur = (
        fieldName: string,
        e: SyntheticEvent<HTMLInputElement>
      ) => {
        setAboutYouObjForUpdatedFields(fieldName, values[fieldName]);
        handleBlur(e);
      };

      const checkjointlyOwnedExists = (index: mixed) => {
        return (
          errors.jointlyOwnedDetails !== undefined &&
          errors.jointlyOwnedDetails[index] &&
          touched.jointlyOwnedDetails !== undefined &&
          touched.jointlyOwnedDetails[index]
        );
      };

      const checkError = (fieldName: string, index: mixed) => {
        if (checkjointlyOwnedExists(index))
          return (
            errors[fieldName][index] !== undefined && touched[fieldName][index]
          );
      };

      return (
        <>
          <div
            className={`${commonFormStylesIdentifier}__nonDynamicFieldsContainer`}
          >
            <div
              className={`${commonFormStylesIdentifier}__fieldContainer ${commonFormStylesIdentifier}--withNoMargin  ${commonFormStylesIdentifier}--withMarginTop`}
            >
              <label
                htmlFor="propertyJointlyOwned"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                Is the property jointly owned?
              </label>
              <span className={`${className}__input`}>
                <div className={`${className}--fieldLabelSpacing`}>
                  <ButtonGroup
                    name="propertyJointlyOwned"
                    onClick={(val: string) => {
                      setFieldValue('propertyJointlyOwned', val);
                      clearPropertyJoinedFields(val);
                      saveData('aboutYouObj', 'propertyJointlyOwned', val);
                    }}
                    options={[
                      { label: 'Yes', value: 'true' },
                      { label: 'No', value: 'false' }
                    ]}
                    touched={touched.propertyJointlyOwned}
                    selected={values.propertyJointlyOwned}
                    onBlur={handleCustomBlur.bind(this, 'propertyJointlyOwned')}
                    error={this.isFieldError('propertyJointlyOwned')}
                    errorMessage={errors.propertyJointlyOwned}
                  />
                </div>
              </span>
            </div>
          </div>
          {values.propertyJointlyOwned === 'true' ? (
            <AboutYouJointlyForm
              className={className}
              checkError={checkError}
              getIn={getIn}
              errors={errors}
              getJointlyOwnedFieldValue={getJointlyOwnedFieldValue}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              handleCustomBlurFunc={(e: any, fieldName: string) => {
                handleCustomBlur(fieldName, e);
              }}
              values={values}
              touched={touched}
            />
          ) : null}
        </>
      );
    };

    // eslint-disable-next-line complexity
    const getAddressErrorStatus = (referenceAddr: boolean) => {
      const addressDetails = referenceAddr
        ? {
          displayAddress: 'displayAddress'
        }
        : {
          displayAddress: 'correspondence_displayAddress'
        };
      return isFieldError(addressDetails.displayAddress, touched, errors);
    };

    return (
      <div className={`${className}__container`}>
        <Form>
          <div
            className={`${commonFormStylesIdentifier}__nonDynamicFieldsContainer`}
          >
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="title"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
                data-testid={`${commonFormStylesIdentifier}__titlelabel`}
              >
                Title
                <div className={`${className}__infoText`}>
                  The main proposer is the person completing the application.
                </div>
              </label>
              <span className={`${className}__input`}>
                <Dropdown
                  data-testid={`${className}__titleinput`}
                  error={this.isFieldError('title')}
                  errorMessage={errors.title}
                  name="title"
                  placeholder="Type here"
                  type="text"
                  value={values.title}
                  onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                    handleChange(e);
                    setFieldValue('otherTitle', '');
                    saveData('aboutYouObj', 'title', e.target.value);
                  }}
                  onBlur={handleCustomBlur.bind(this, 'title')}
                >
                  {TITLES_CONSTANT.slice(0, TITLES_TO_SHOW).map(
                    (title: string, index: number) => {
                      return (
                        <option
                          value={OPTION_TITLE_VALUES_CONSTANT[index]}
                          key={`${index}_${title}`}
                        >
                          {TITLES_CONSTANT[index]}
                        </option>
                      );
                    }
                  )}
                </Dropdown>
              </span>
            </div>

            {this.props.values.title === OTHER_TITLE_CONSTANT
              ? renderOtherTitleInputField()
              : null}
            <div
              className={`${commonFormStylesIdentifier}__fieldContainer ${className}__username`}
              data-testid={`${commonFormStylesIdentifier}__UserName`}
            >
              <label
                htmlFor="UserName"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                User name
              </label>
              <span className={`${className}__input`}>
                <InputField
                  name="userName"
                  placeholder="E.g. John"
                  value={values.userName}
                  onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                    handleChange(e);
                    saveData('aboutYouObj', 'userName', e.target.value);
                  }}
                  type="text"
                  onBlur={handleCustomBlur.bind(this, 'userName')}
                />
              </span>
            </div>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="firstName"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                First name
              </label>
              <span className={`${className}__input`}>
                <InputField
                  error={this.isFieldError('firstName')}
                  errorMessage={errors.firstName}
                  name="firstName"
                  placeholder="E.g. John"
                  touched={touched.firstName}
                  value={values.firstName}
                  onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                    handleChange(e);
                    saveData('aboutYouObj', 'firstName', e.target.value);
                  }}
                  type="text"
                  onBlur={handleCustomBlur.bind(this, 'firstName')}
                />
              </span>
            </div>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="lastName"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                Last name
              </label>
              <span className={`${className}__input`}>
                <InputField
                  error={this.isFieldError('lastName')}
                  errorMessage={errors.lastName}
                  name="lastName"
                  placeholder="E.g. Smith"
                  touched={touched.lastName}
                  type="text"
                  onBlur={handleCustomBlur.bind(this, 'lastName')}
                  value={values.lastName}
                  onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                    handleChange(e);
                    saveData('aboutYouObj', 'lastName', e.target.value);
                  }}
                />
              </span>
            </div>
            <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
              <WideDivider color={dividerColor} height={1} />
            </div>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="displayAddress"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                <AccordionText
                  label="Enter Eircode or full address of the property to be insured (and select your address from the drop down list)"
                  icon="info"
                  iconAlign="right"
                  customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
                >
                  <div className={`${className}__infoText`} tabIndex={0}>
                    {ADDRESS_TOOLTIP_MSG_PRIMARY}
                    <a
                      href={'https://finder.eircode.ie'}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {'https://finder.eircode.ie'}
                    </a>{' '}
                    <br />
                    {ADDRESS_TOOLTIP_MSG_SECONDARY}
                  </div>
                </AccordionText>
              </label>
              <span className={`${className}__input`}>
                <AutoAddressAutocomplete
                  onSelect={this.setSelectedAddress.bind(this, true)}
                  value={values.displayAddress}
                  name="displayAddress"
                  errorMessage={
                    this.state.addressloader
                      ? ''
                      : getAddressErrorMessage(true, errors)
                  }
                  handleBlur={(
                    event: SyntheticInputEvent<HTMLInputElement>
                  ) => {
                    setTimeout(() => {
                      setFieldTouched('addressLine1');
                      setFieldTouched('town');
                      setFieldTouched('county');
                    }, 1500);
                    handleBlur(event);
                  }}
                  handleChange={handleChange}
                  hasError={
                    this.state.addressloader
                      ? false
                      : getAddressErrorStatus(true)
                  }
                  getEirCodeErrorStatus={this.getEirCodeErrorStatus}
                  showEirCodeError={this.showEirCodeError}
                />
              </span>
            </div>
            {this.state.addressloader
              ? showSpinner()
              : this.getAddressFields(this.state.showManualAddress)}
            <div
              className={`${commonFormStylesIdentifier}__fieldContainer`}
              tabIndex={0}
            >
              {
                (this.props.values.addressLine1?.length > 0) ? (
                  <div
                    className={`${commonFormStylesIdentifier}__fieldContainer ${commonFormStylesIdentifier}--withNoMargin  ${commonFormStylesIdentifier}--withMarginTop`}
                  >
                    <label
                      htmlFor="is_correspondence_address"
                      className={`${commonFormStylesIdentifier}__fieldLabel`}
                    >
                      Is your correspondence address the same as this property being
                      insured?
                    </label>
                    <span className={`${className}__input`}>
              <div className={`${className}--fieldLabelSpacing`}>
                <ButtonGroup
                  name="is_correspondence_address"
                  onClick={(value: boolean) => {
                    setFieldValue(
                      'is_correspondence_address',
                      value === 'true'
                    );
                    setFieldTouched('is_correspondence_address', value);
                    setAboutYouObjForUpdatedFields(
                      'is_correspondence_address',
                      value
                    );
                    saveData(
                      'aboutYouObj',
                      'is_correspondence_address',
                      value === 'true'
                    );
                  }}
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false }
                  ]}
                  touched={touched.is_correspondence_address}
                  selected={values.is_correspondence_address}
                  error={this.isFieldError('is_correspondence_address')}
                  errorMessage={errors.is_correspondence_address}
                  onBlur={handleCustomBlur.bind(
                    this,
                    'is_correspondence_address'
                  )}
                />
              </div>
            </span>
                  </div>) : null}
            </div>
            {this.props.values.is_correspondence_address === false && (
              <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
                <label
                  htmlFor="correspondence_displayAddress"
                  className={`${commonFormStylesIdentifier}__fieldLabel`}
                >
                  <AccordionText
                    label="Eircode or Full Address for correspondence"
                    icon="info"
                    iconAlign="right"
                    customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
                  >
                    <div className={`${className}__infoText`} tabIndex={0}>
                      {ADDRESS_TOOLTIP_MSG_PRIMARY}
                      <a
                        href={'https://finder.eircode.ie'}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {'https://finder.eircode.ie'}
                      </a>{' '}
                      <br /> {ADDRESS_TOOLTIP_MSG_SECONDARY}
                    </div>
                  </AccordionText>
                </label>
                <span className={`${className}__input`}>
                  <AutoAddressAutocomplete
                    onSelect={this.setSelectedAddress.bind(this, false)}
                    value={values.correspondence_displayAddress}
                    name="correspondence_displayAddress"
                    errorMessage={
                      this.state.correspondenceLoader
                        ? ''
                        : getAddressErrorMessage(false, errors)
                    }
                    handleBlur={(
                      event: SyntheticInputEvent<HTMLInputElement>
                    ) => {
                      setTimeout(() => {
                        setFieldTouched('corresonding_addressLine1');
                        setFieldTouched('correspondence_county');
                      }, 1500);
                      handleBlur(event);
                    }}
                    handleChange={handleChange}
                    hasError={
                      this.state.correspondenceLoader
                        ? false
                        : getAddressErrorStatus(false)
                    }
                    getEirCodeErrorStatus={this.getEirCodeErrorStatus}
                    showEirCodeError={this.showCorrespondenceEirCodeError}
                  />
                </span>
              </div>
            )}
            {this.state.correspondenceLoader
              ? showSpinner()
              : this.getCorrespondingAddress(
                this.state.showCorrespondenceManualAddress
              )}
            <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
              <WideDivider color={dividerColor} height={1} />
            </div>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="phoneNo"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                Mobile Phone Number
                <div className={`${className}__infoText`}>
                  We may need to contact you in relation to your quote
                </div>
              </label>
              <span className={`${className}__input`}>
                <InputField
                  error={this.isFieldError('phoneNo')}
                  errorMessage={errors.phoneNo}
                  name="phoneNo"
                  placeholder="E.g. 0871234567"
                  type="tel"
                  onBlur={handleCustomBlur.bind(this, 'phoneNo')}
                  value={values.phoneNo}
                  onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                    handleChange(e);
                    saveData('aboutYouObj', 'phoneNo', e.target.value);
                  }}
                  mask={phoneNumberMask}
                />
              </span>
            </div>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="email"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                <AccordionText
                  label="Email address"
                  icon="info"
                  iconAlign="right"
                  customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
                >
                  <div className={`${className}__infoText`} tabIndex={0}>
                    Your email address should be in the correct format. We will
                    use your email address to send quote details to you. A valid
                    email address is required to purchase the policy, if you do
                    not have a valid email address please contact us on{' '}
                    {localPhoneNumber}
                  </div>
                </AccordionText>
              </label>
              <EmailInput
                formik={this.props}
                insuranceType={HOME_INSURANCE_TYPE}
                handleCustomBlur={handleCustomBlur}
                loadAgentFormValues={this.props.loadAgentFormValues}
                emailParam={this.props.emailParam}
              />
            </div>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="dob"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                Date of birth
              </label>
              <span className={`${className}__input`}>
                <DatePicker
                  error={errors.dob}
                  name="dob"
                  onBlur={handleCustomBlur.bind(this, 'dob')}
                  onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                    handleChange(e);
                    saveData('aboutYouObj', 'dob', e.target.value);
                  }}
                  placeholder="DD/MM/YYYY"
                  touched={touched.dob}
                  value={values.dob}
                  textType
                />
              </span>
            </div>
          </div>
          {getBankOfIrelandQuestion()}
          {getJointlyOwnedFields()}
          <AboutYouFormMisc
            className={className}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            touched={touched}
            values={values}
            handleChange={handleChange}
            errors={errors}
            handleCustomBlurFunc={(e: any, fieldName: string) => {
              handleCustomBlur(fieldName, e);
            }}
            setAboutYouObjForUpdatedFields={setAboutYouObjForUpdatedFields}
            updatePromoCode={this.updatePromoCode}
            promoCodeValid={this.state.promoCodeValid}
            updatePromoCodeRequestSent={this.updatePromoCodeRequestSent}
            promoCodeRequestSent={this.state.promoCodeRequestSent}
          />
        </Form>
      </div>
    );
  }
}

const FormikAboutYouForm = withFormik<Props, AboutYouFormValues>({
  mapPropsToValues(): AboutYouFormValues {
    let initialValues = ABOUT_YOU_INITIAL_VALUES;
    const valuesObject = {};
    Object.keys(initialValues).forEach((key: string) => {
      valuesObject[key] = initialValues[key];
    });
    return valuesObject;
  },
  handleSubmit(values: AboutYouFormValues, { setSubmitting }: FormikProps) {
    setSubmitting(false);
  },
  isInitialValid: (props: Props) => props.aboutYouValid,
  validationSchema: yup.object().shape(AboutYouFormYupSchema),
  displayName: 'AboutYouForm'
})(AboutYouForm);

export default FormikAboutYouForm;
