/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './WalletLandingPage.scss';
import '../../styles/commonPageStyles.scss';

import {
  ButtonCard,
  CarIcon,
  HomeIcon,
  Modal,
  Text,
  TitleWithUnderLine,
  Typeahead
} from '@boi/core/lib';
import { colors, screenSize } from '@boi/core/lib/styles';
import { faSearch, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { CognitoContext } from '../../CognitoUtils';
import BoiNotificationCard from '../../components/BoiNotificationCard';
import BoiRetrieveCard from '../../components/BoiRetrieveCard';
import {
  commonPageStylesIdentifier,
  DIRECT_DEBIT_STORAGE,
  HOME_QUOTE_THANK_YOU_STORAGE,
  SELECTED_HOME_QUOTE_STORAGE
} from '../../constants';
import { darkOrange } from '../../constants/colors';
import { QUOTE_REFERENCE } from '../../constants/home';
import {
  CAR_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE
} from '../../constants/insuranceTypeConstants';
import {
  AGENT_LATEST_QUOTE_LOADED,
  PAYMENT_SUCCESS
} from '../../constants/sessionStorage/genericStorageIdentifiers';
import {
  HOME_LATEST_QUOTE_STORAGE,
  HOME_RESUME_QUOTE,
  HOME_RESUME_QUOTE_OBJ
} from '../../constants/sessionStorage/homeStorageIdentifiers';
import {
  AGENT_CLICKED_REFERENCE_QUOTES,
  AGENT_SELECTED_QUOTE,
  USER_BRANCH
} from '../../constants/sessionStorage/userStorageIdentifiers';
import {
  getItemFromSessionStorage,
  isAgent,
  removeAllJourneysSessionStorage,
  removeAllStoredPolicyNumbers,
  removeFromSessionStorage,
  saveInSessionStorage,
  saveObjectInSessionStorage
} from '../../helpers';
import { isSuperAgent } from '../../helpers/AgentCheck';
import { routes } from '../../routes';
import {
  formatResponse,
  getLatestHomeQuote
} from '../../services/latestHomeQuoteService';
import {
  getBranchData,
  updateUserProfileBranch
} from '../../services/profile/profile';
import type { UserProfileType } from '../../types/profile';
import UserContext from '../../UserContext';

type WalletLandingPageProps = {
  userProfile: UserProfileType,
  showBanner: boolean
};

// eslint-disable-next-line complexity
const WalletLandingPage = ({
  userProfile,
  showBanner
}: WalletLandingPageProps) => {
  const [, setIsDesktop] = useState(false);
  const [existingPolicies, setExistingPolicies] = useState({
    car: false,
    home: false,
    travel: false,
    van: false,
    business: false,
    life: false,
    pet: false,
    health: false,
    pensions: false,
    savings: false
  });
  const [showJourneys, setShowJourneys] = useState(false);
  const [showRenewal] = useState(false);
  const [showResume] = useState(showBanner);
  const [showLogin, setShowLogin] = useState(true);
  const { userDispatch } = useContext(UserContext);
  const { cognitoUser, feature, branchList } = useContext(CognitoContext);
  const [showModal, setShowModal] = useState(false);
  const [showBranch, setShowBranch] = useState({});

  useEffect(() => {
    // check if its a desktop screen
    const desktop = window.innerWidth > screenSize.minDesktopWidth;
    setIsDesktop(desktop);
    setShowJourneys(desktop);

    if (cognitoUser !== null) {
      setShowLogin(false);
      if (cognitoUser?.attributes && cognitoUser?.attributes.given_name) {
        userDispatch({
          type: 'setFirstName',
          payload: { firstName: cognitoUser?.attributes.given_name }
        });
      }
    }
    removeAllJourneysSessionStorage();
    removeAllStoredPolicyNumbers();
  }, [cognitoUser]);

  const saveLatestQuote = async (date: string) => {
    const latest = await getLatestHomeQuote();
    const data = formatResponse(latest.home_quote);
    const extraQuestionsValues = {
      listed_or_protected:
        latest.home_quote.cover_details.risk_address.listed_or_protected,
      operational_locks:
        latest.home_quote.cover_details.risk_address.operational_locks,
      roof_tile: latest.home_quote.cover_details.risk_address.roof_tile,
      un_occupied: latest.home_quote.cover_details.risk_address.un_occupied
    };
    const value = {
      aboutYouObj: data.aboutYou,
      yourHomeObj: data.yourHome,
      yourCoverObj: {
        ...data.yourCover,
        policyStart: format(new Date(date), 'YYYY-MM-DD')
      },
      assumptions: latest.home_quote.assumptions,
      extraQuestionsValues: extraQuestionsValues
    };
    saveInSessionStorage(HOME_RESUME_QUOTE_OBJ, JSON.stringify(value));
    saveInSessionStorage(HOME_QUOTE_THANK_YOU_STORAGE, JSON.stringify(value));
  };

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (userProfile?.purchasedInsuranceTypes) {
      const purchasedInsuranceTypes = userProfile?.purchasedInsuranceTypes;
      //call api to check what policies user has & update existingPolicies object
      setExistingPolicies({
        car: purchasedInsuranceTypes[CAR_INSURANCE_TYPE],
        home: purchasedInsuranceTypes[HOME_INSURANCE_TYPE],
        travel: isAgent()
          ? false
          : purchasedInsuranceTypes[TRAVEL_INSURANCE_TYPE],
        van: false,
        business: false,
        life: false,
        pet: false,
        health: false,
        pensions: false,
        savings: false
      });
    }
    if (userProfile?.quote) {
      let quote = userProfile.quote;
      saveObjectInSessionStorage(SELECTED_HOME_QUOTE_STORAGE, quote);
      const flag = JSON.parse(getItemFromSessionStorage(HOME_RESUME_QUOTE));
      if (flag || flag === null) {
        saveInSessionStorage(HOME_RESUME_QUOTE, false);
      }
      let quoteDetails = {
        price: quote.cover_premium,
        quoteReference: quote.quote_reference,
        monthlyPrice: quote.direct_debit_amount,
        insurerType: quote.insurer
      };

      saveObjectInSessionStorage(
        `${HOME_INSURANCE_TYPE}${DIRECT_DEBIT_STORAGE}`,
        quoteDetails
      );
      saveLatestQuote(quote.start_date);
    }
  }, [userProfile]);

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (
      (getItemFromSessionStorage(AGENT_CLICKED_REFERENCE_QUOTES) ||
        getItemFromSessionStorage(AGENT_SELECTED_QUOTE)) &&
      isAgent()
    ) {
      getItemFromSessionStorage(AGENT_CLICKED_REFERENCE_QUOTES)
        ? removeFromSessionStorage(AGENT_CLICKED_REFERENCE_QUOTES)
        : removeFromSessionStorage(AGENT_SELECTED_QUOTE);
    }
    if (isAgent()) {
      if (getItemFromSessionStorage(USER_BRANCH) === null) {
        getBranchData('branch')
          .then((response: any) => {
            if (!response?.error) {
              saveInSessionStorage(USER_BRANCH, JSON.stringify(response));
              userDispatch({
                type: 'setBranchName',
                payload: { branchName: response }
              });
            } else {
              setShowModal(true);
            }
          })
          .catch((error: any) => {
            console.error(error);
          });
      } else {
        const userBranch = JSON.parse(getItemFromSessionStorage(USER_BRANCH));
        userDispatch({
          type: 'setBranchName',
          payload: { branchName: userBranch }
        });
      }
    }
    removeFromSessionStorage(QUOTE_REFERENCE);
    removeFromSessionStorage(PAYMENT_SUCCESS);
    removeFromSessionStorage(AGENT_LATEST_QUOTE_LOADED);
    removeFromSessionStorage(HOME_LATEST_QUOTE_STORAGE);
  }, []);

  const getButtonCardColour = (card: string): string => {
    return existingPolicies[card] ? colors.primaryColor : colors.darkBlue;
  };

  const getTitleText = () =>
    cognitoUser?.attributes?.given_name
    // $FlowFixMe
      ? `Welcome, ${cognitoUser.attributes.given_name}!`
      : 'Welcome!';

  const className = 'c-WalletLandingPage';
  const hiddenJourneys = {
    pet: 'Pet',
    health: 'Health',
    pensions: 'Pensions',
    savings: 'Savings & Investments'
  };

  const continueQuoteNotification =
    showResume &&
    feature.flags?.home_resume_enabled ? (
      <BoiRetrieveCard userProfile={userProfile} />
    ) : null;
  const renewalNotification = showRenewal ? (
    <BoiNotificationCard
      color={darkOrange}
      heading={() => (
        <Text align="left" type="quinary">
          Renewal time!
        </Text>
      )}
      icon="car"
    >
      <Text align="left">Its almost time to renew your car insurance.</Text>
    </BoiNotificationCard>
  ) : null;

  if (showJourneys) {
    const buttonCards = [];
    Object.keys(hiddenJourneys).forEach((key: string) =>
      buttonCards.push(
        <div className={`${className}__ButtonContainer`} key={key}>
          <Link
            to="/wallet"
            data-ga
            id={`WalletLandingPage__${key}WalletCardLink`}
            data-testid={`WalletLandingPage__${key}WalletCardLink`}
            className={`${className}__buttonCardLink--disabled`}
          >
            <ButtonCard
              icon={key}
              text={hiddenJourneys[key]}
              comingSoon
              color={getButtonCardColour(key)}
            />
          </Link>
        </div>
      )
    );
  }

  // eslint-disable-next-line complexity
  const renderTravelTile = () => (
    <div
      className={`${className}__ButtonContainer`}
      style={
        checkFlagValue(TRAVEL_INSURANCE_TYPE) ? null : { cursor: 'not-allowed' }
      }
    >
      <Link
        to={isAgent() ? '/wallet' : '/wallet/product/travel'}
        data-ga
        id="WalletLandingPage__travelButtonCard"
        data-testid="WalletLandingPage__travelButtonCard"
        style={
          checkFlagValue(TRAVEL_INSURANCE_TYPE)
            ? null
            : { pointerEvents: 'none' }
        }
      >
        <ButtonCard
          icon="travel"
          text="Travel"
          comingSoon={isAgent()}
          color={getButtonCardColour('travel')}
        />
      </Link>
    </div>
  );

  const renderLatestQuotes = () => {
    return (
      isAgent() && (
        <div className={`${className}__latestQuotesContainer`}>
          <Link
            to={`/wallet/createAccount`}
            data-ga
            id="WalletLandingPage__agentLatestQuotesHome"
            data-testid="WalletLandingPage__agentLatestQuotesHome"
          >
            <div className={`${className}__latestQuotes`}>
              <div className={`${className}__latestQuotes__homeIcon`}>
                <HomeIcon color={colors.primaryColor} />
              </div>
              <div className={`${className}__latestQuotes__label`}>
                Create Customer Account
              </div>
            </div>
          </Link>
          {isSuperAgent(cognitoUser) && (
            <Link
              to={`/wallet/user/`}
              data-ga
              id="WalletLandingPage__SuperAgentMatchingService"
              data-testid="WalletLandingPage__SuperAgentMatchingService"
            >
              <div className={`${className}__latestQuotes`}>
                <div className={`${className}__latestQuotes__icon`}>
                  <FontAwesomeIcon
                    className={'signupIcon'}
                    icon={faUserAlt}
                    size="2x"
                    color={colors.primaryColor}
                  />
                </div>
                <div className={`${className}__latestQuotes__label`}>
                  Matching Service
                </div>
              </div>
            </Link>
          )}
          <Link
            to={`/wallet/searchQuotes/`}
            data-ga
            id="WalletLandingPage__agentLatestQuotesCar"
            data-testid="WalletLandingPage__agentLatestQuotesCar"
          >
            <div className={`${className}__latestQuotes`}>
              <div className={`${className}__latestQuotes__icon`}>
                <FontAwesomeIcon
                  className={'signupIcon'}
                  icon={faSearch}
                  size="2x"
                  color={colors.primaryColor}
                />
              </div>
              <div className={`${className}__latestQuotes__label`}>
                Search All Home Quotes
              </div>
            </div>
          </Link>
          <Link
            to={`/wallet/latest-quotes/${CAR_INSURANCE_TYPE}`}
            data-ga
            id="WalletLandingPage__agentLatestQuotesCar"
            data-testid="WalletLandingPage__agentLatestQuotesCar"
          >
            <div className={`${className}__latestQuotes`}>
              <div className={`${className}__latestQuotes__icon`}>
                <CarIcon color={colors.primaryColor} />
              </div>
              <div className={`${className}__latestQuotes__label`}>
                Latest Quotes
              </div>
            </div>
          </Link>
          <Link
            to={`/wallet/latest-quotes/${HOME_INSURANCE_TYPE}`}
            data-ga
            id="WalletLandingPage__agentLatestQuotesHome"
            data-testid="WalletLandingPage__agentLatestQuotesHome"
          >
            <div className={`${className}__latestQuotes`}>
              <div className={`${className}__latestQuotes__homeIcon`}>
                <HomeIcon color={colors.primaryColor} />
              </div>
              <div className={`${className}__latestQuotes__label`}>
                Latest Quotes
              </div>
            </div>
          </Link>
        </div>
      )
    );
  };

  const checkFlagValue = (flagValue: string) => {
    switch (flagValue) {
      case HOME_INSURANCE_TYPE:
        return feature.flags?.home_enabled;
      case TRAVEL_INSURANCE_TYPE:
        return feature.flags?.travel_enabled;
      default:
        return true;
    }
  };

  const onBranchSelected = useCallback(() => {
    if (showBranch !== '') {
      updateUserProfileBranch({ branchName: showBranch }).then(
        (response: any) => {
          if (response.branch_name) {
            userDispatch({
              type: 'setBranchName',
              payload: { branchName: response }
            });
          }
        }
      );
    }
    setShowModal(false);
  }, [showModal, showBranch]);

  return (
    <div className={className} id="walletlanding" data-testid="walletlanding">
      {renewalNotification}
      {!isAgent() && (
        <div className={`${className}__welcomeContainer`}>
          <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
            <div className={`${className}__welcomeText`}>
              {renderLatestQuotes()}
              <Text size="inherit">{getTitleText()}</Text>
            </div>
          </div>

          <div className={`${commonPageStylesIdentifier}__showOnDesktopOnly`}>
            <div className={`${commonPageStylesIdentifier}__pageTitle`}>
              <TitleWithUnderLine>{getTitleText()}</TitleWithUnderLine>
              {renderLatestQuotes()}
            </div>
          </div>

          {continueQuoteNotification}
          <div className={`${className}__welcomeSubtext`}>
            <Text size="inherit">Tap an icon to get started.</Text>
          </div>
        </div>
      )}
      {isAgent() && (
        <div className={`${className}__welcomeContainer`}>
          <div className={`${commonPageStylesIdentifier}`}>
            <div className={`${commonPageStylesIdentifier}__pageTitle`}>
              <TitleWithUnderLine>Customer Search</TitleWithUnderLine>
              <div className={`${className}__agentSubtext`}>
                <Text size="inherit">Select your search option below:</Text>
              </div>
              {renderLatestQuotes()}
            </div>
          </div>
        </div>
      )}
      {isAgent() && (
        <div className={`${commonPageStylesIdentifier}__pageTitle`}>
          <TitleWithUnderLine>Create a new Quote</TitleWithUnderLine>
        </div>
      )}
      <div className={`${className}__ButtonGridContainer`}>
        <div className={`${className}__ButtonGrid`}>
          <div
            className={`${className}__ButtonContainer`}
            style={
              checkFlagValue(HOME_INSURANCE_TYPE)
                ? null
                : { cursor: 'not-allowed' }
            }
          >
            <Link
              style={
                checkFlagValue(HOME_INSURANCE_TYPE)
                  ? null
                  : { pointerEvents: 'none' }
              }
              to={
                feature.flags?.home_enabled
                  ? '/wallet/product/home'
                  : ''
              }
              data-ga
              id="WalletLandingPage__homeButtonCard"
              data-testid="WalletLandingPage__homeButtonCard"
            >
              <ButtonCard
                icon="home"
                text="Home"
                callInsurance={
                  feature.flags?.home_enabled
                }
                color={getButtonCardColour('home')}
              />
            </Link>
          </div>
          <div className={`${className}__ButtonContainer`}>
            <Link
              to='/wallet/product/car'
              data-ga
              id="WalletLandingPage__carButtonCard"
              data-testid="WalletLandingPage__carButtonCard"
            >
              <ButtonCard
                icon="car"
                text="Car"
                color={getButtonCardColour('car')}
              />
            </Link>
          </div>
          {renderTravelTile()}
        </div>
      </div>
      {showLogin ? (
        <Link
          to={{ pathname: routes.loginPage.url }}
          className={`${className}__link`}
          data-ga
          id="homeLoginButtonLink"
          data-testid="homeLoginButtonLink"
        >
          Have an account? Login
        </Link>
      ) : null}
      <Modal
        show={showModal}
        showSecondaryButton={false}
        modalElementId="walletlanding"
        height="250px"
        id={'modal-branch-type'}
        showCloseIcon={false}
        title="Please Select Branch"
        data-testid="modal-branch-type"
        onPrimaryButtonClick={() => {
          onBranchSelected();
        }}
      >
        <Typeahead
          data={branchList}
          data-testid="modal-branch-text"
          id={'modal-branch-text'}
          placeholder="Search Branch Type"
          onChange={(selectedOpt: any) => {
            setShowBranch(selectedOpt);
          }}
          // $FlowFixMe
          value={showBranch?.value || ''}
        />
      </Modal>
    </div>
  );
};

export default WalletLandingPage;
