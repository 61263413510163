/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
import { Button, Modal, WideDivider } from '@boi/core/lib';
import { noop } from '@boi/core/lib/utils';
import { faCheck, faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';

import { commonFormStylesIdentifier } from '../../constants';
import { errorRed, green } from '../../constants/colors';
import { dividerColor } from '../../constants/colors';
import { ERROR_CODE, INVALID_PORTFOLIO_CODE_FORMAT, PORTFOLIO_CODE_REGEX } from '../../constants/retriveUserConstant';
import { matchUserToPortfolioCode, unMatchUserToPortfolioCode } from '../../services/retriveUser';
import AgGridTable from '../AgGridTable/AgGridTable';



interface Props {
  showModal: noop;
  params: noop;
  setShowModal: noop;
  portfolioCodes: noop;
}

type PortfolioCodeData = {
  portfolio_code: string,
  third_party_identifier: string,
  portfolio_id?: string,
};

// eslint-disable-next-line complexity
const UserMatchPortfolioIdModal = (props: Props) => {
  const className = 'c-RetreiveUser';
  const {
    showModal,
    params,
    setShowModal,
    portfolioCodes
  } = props;

  const [rowData, updateRowData] = useState < PortfolioCodeData[] > (portfolioCodes);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [tableProps, setTableProps] = useState({});
  const [matchPortfolioCodeError, setMatchPortfolioCodeError] = useState()
  const [matchPortfolioCodeSuccess, setMatchPortfolioCodeSuccess] = useState()
  const [errorText, setErrorText] = useState('')
  const [portfolioCodeAgGridApi, setPortfolioCodeAgGridApi] = useState();
  const paramsCellObjectStateRef = useRef(null);


  const setErrorState = () => {
    setMatchPortfolioCodeError(false)
    setErrorText('')
    setMatchPortfolioCodeSuccess(false)
  }

  const onIconClickHandler = (props: any) => {
    setTableProps(props);
    //TODO need to remove all code related to this button after getting confirmation from bank
    // const handleEditClick = () => {
    //   setErrorState()
    //   if (props) {
    //     const rowIndex = props.node.rowIndex;
    //     const colId = "portfolio_code";
    //     if (props?.agGridReact?.props?.rowData[rowIndex].portfolio_code !== "") {
    //       unMatchUserToPortfolioCode(props.agGridReact.props.rowData[rowIndex])
    //     }
    //     props.api.startEditingCell({ rowIndex, colKey: colId })
    //   }
    // }

    const setShowDeleteModalFun = () => {
      if(props) {
        setTableProps(props)
        setShowDeleteModal(true)
        setErrorState()
      }
    }

    return (
      <div className={`${className}__tableIcons`}>
        {/* <div className={`${className}__matchButton ${className}__editButton`}>  //todo need to remove all code related to this button after getting confirmation from bank
          <Button
            onClick={handleEditClick}
          > Edit</Button>
        </div> */}
        {props?.agGridReact?.props?.rowData[props.node.rowIndex].portfolio_code ?
        <div className={`${className}__agGridButton ${className}__deleteButton`}>
          <Button
            onClick={setShowDeleteModalFun}
          > Delete</Button>
        </div> : 
        <div className={`${className}__agGridButton ${className}__saveButton`}>
          <Button
            onClick={handleEditClick}
          > Save</Button>
        </div>
        }
      </div>
    );
  };

  const deleteCell = () => {
    const cloneRowData = JSON.parse(JSON.stringify(tableProps.agGridReact.props.rowData))
    const updatedRowData: PortfolioCodeData[] = cloneRowData.filter((row: any, index: number) => index !== tableProps.rowIndex);
    tableProps.agGridReact.api.setRowData(updatedRowData);
    updateRowData(updatedRowData)
  }

  const handleDeleteClick = () => {
    if (tableProps?.agGridReact?.props?.rowData[tableProps.rowIndex].portfolio_code === "") {
      deleteCell()
    } else {
      unMatchUserToPortfolioCode(tableProps.agGridReact.props.rowData[tableProps.rowIndex]).then((response: any) => {
        if (response.error) {
          setErrorText("Failed to delete Portfolio Code")
          setMatchPortfolioCodeError(true)
        } else {
          deleteCell()
        }
      })
    }
  }

  const updatedRowDataFunc = (newPortfolioCode: string, newThirdpartyIdentifier: string) => {
    const updateData = {
      portfolio_code: newPortfolioCode,
      third_party_identifier: newThirdpartyIdentifier
    }
    return updateData;
  }

  // eslint-disable-next-line complexity
  const handleEditClick = () => {
    if (paramsCellObjectStateRef.current && portfolioCodeAgGridApi) {
      const { newValue, rowIndex } = paramsCellObjectStateRef.current;
      const updatedRowData = [...rowData];
      if (newValue) {
        if (!PORTFOLIO_CODE_REGEX.test(newValue)) {
          paramsCellObjectStateRef.current?.oldValue !== "" ? updatedRowData[rowIndex] = { ...updatedRowData[rowIndex], portfolio_code: paramsCellObjectStateRef.current?.oldValue } :
            updatedRowData[rowIndex] = updatedRowDataFunc("", "")
          portfolioCodeAgGridApi.setRowData(updatedRowData);
          updateRowData(updatedRowData);
          setErrorText(INVALID_PORTFOLIO_CODE_FORMAT)
          setMatchPortfolioCodeError(true)
          deleteCell()
        } else {
          updatedRowData[tableProps.rowIndex] = updatedRowDataFunc(newValue, params?.data?.username)
          portfolioCodeAgGridApi.setRowData(updatedRowData);
          updateRowData(updatedRowData);
          matchUserToPortfolioCode(updatedRowDataFunc(newValue, params?.data?.username)).then((response: any) => {
            if (response.successfully_paired === false) {
              (response.failure_reason === ERROR_CODE) ? setErrorText(response.failure_reason) : setErrorText("Failed to add Portfolio Code")
              setMatchPortfolioCodeError(true)
              deleteCell()
            } else {
              setMatchPortfolioCodeSuccess(true)
            }
          })
        }
      }
    }
  }

  const handleCellValueChanged = (paramsCellObject: any) => {
    paramsCellObjectStateRef.current = paramsCellObject;
    if (paramsCellObject.oldValue !== "") {
      setShowEditModal(true)
    } else {
      handleEditClick()
    }
  };

  const setPreviousCellData = () => {
    if (portfolioCodeAgGridApi) {
      const updatedRowData: PortfolioCodeData[] = [...rowData];
      const rowIndex = tableProps.rowIndex;
      updatedRowData[rowIndex] = { portfolio_code: paramsCellObjectStateRef.current?.oldValue, third_party_identifier: params?.data?.username };
      portfolioCodeAgGridApi.setRowData(updatedRowData);
      updateRowData(updatedRowData);
    }
  }



  const columnDefs = [
    {
      headerName: "Portfolio Code", field: 'portfolio_code', editable: true, floatingFilter: false,
      cellClassRules: {
        'align-left': (params: noop) => params.value !== undefined && params.value !== null
      },
      cellEditor: "agTextCellEditor",
      cellEditorParams: {
        placeholder: "PortfolioId Format: XXXXXXXX-XXXX-XXXX-XXXXXXXXXXXX"
      }
    },
    { headerName: "", field: '', cellRendererFramework: onIconClickHandler, floatingFilter: false }
  ];

  useEffect(() => {
    updateRowData(portfolioCodes);
  }, [portfolioCodes]);

  useEffect(() => {
    if (portfolioCodeAgGridApi) {
      portfolioCodeAgGridApi.sizeColumnsToFit();
    }
  }, [showDeleteModal, showEditModal, portfolioCodeAgGridApi])

  return (
    <>
      <Modal
        show={showModal}
        width='45%'
        modalElementId='root'
        id={'modal-portfolioId-type'}
        showCloseIcon={false}
        primaryButtonLabel='Exit'
        showSecondaryButton={false}
        onPrimaryButtonClick={() => {
          setShowModal(false)
          setErrorState()
        }}
        data-testid="modal-portfolioId-type"
        classNameModalBody={className}
        classNameModalHeader={className}
        classNameModalFooter={className} >
        <div className={`${commonFormStylesIdentifier}__nonDynamicFieldsContainer ${className}__nameEmailDivSpacing`}>
          <div>
            <h4>Name: {`${params?.data?.first_name} ${params?.data?.last_name}`}</h4>
          </div>
          <div>
            <h4>Email: {`${params?.data?.email}`}</h4>
          </div>
        </div>
        {(matchPortfolioCodeError) && (<div className="error-modal">
          <div className="error-content">
            <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" color={errorRed} />
            <span className="span-text">{errorText}</span>
            <FontAwesomeIcon icon={faTimes} className="close-icon" onClick={() => {
              setErrorState()
            }} />
          </div>
        </div>)}
        {(matchPortfolioCodeSuccess) && (<div className="success-modal">
          <div className="success-content">
            <FontAwesomeIcon icon={faCheck} className="error-icon" color={green} />
            <span className="span-text">{`Portfolio Code successfully matched to email address ${params?.data?.email}`}</span>
            <FontAwesomeIcon icon={faTimes} className="close-icon" onClick={() => {
              setErrorState()
            }} />
          </div>
        </div>)}
        <div className={`${className}__button`}>
          <Button
            fluid={true}
            onClick={() => {
              const colId = "portfolio_code";
              const newIndex = rowData.length;
              if(newIndex === 0 || rowData[newIndex - 1]?.portfolio_code !== "") {
              updateRowData([...rowData, { "portfolio_code": "", "third_party_identifier": "" }]);
              setTimeout(() => {
                if (portfolioCodeAgGridApi && portfolioCodeAgGridApi.setFocusedCell && portfolioCodeAgGridApi.startEditingCell) {
                  portfolioCodeAgGridApi.setFocusedCell(newIndex, colId);
                  portfolioCodeAgGridApi.startEditingCell({ rowIndex: newIndex, colKey: colId })
                }
              }, 0);
              setMatchPortfolioCodeSuccess(false)
              if (matchPortfolioCodeError) {
                setErrorState()
              }
            }
            }}
          > Add Portfolio Code</Button> </div>
        <div className='agGrid'>
          <AgGridTable
            rowData={rowData}
            columnDefs={columnDefs}
            handleCellValueChanged={handleCellValueChanged}
            gridReady={(params: noop) => {
              params.api.sizeColumnsToFit();
              setPortfolioCodeAgGridApi(params.api)
            }}
          />
        </div>
      </Modal>
      <Modal
        width='30%'
        show={showDeleteModal}
        modalElementId='root'
        id={'modal-delete-type'}
        showCloseIcon={false}
        showSecondaryButton={true}
        secondaryButtonClassName="No"
        primaryButtonLabel="No"
        secondaryButtonLabel="Yes"
        onSecondaryButtonClick={() => {
          setShowDeleteModal(false)
          handleDeleteClick()
        }}
        onPrimaryButtonClick={() => {
          setShowDeleteModal(false)
        }}
        data-testid="modal-edit-type">
        <div>
          <label
            htmlFor="deleteConfirmation"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            Delete Confirmation
          </label>
          <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
            <WideDivider color={dividerColor} height={1} />
          </div>
          <p className={`${className}__paragraph`}>Are you sure you want to delete {tableProps?.agGridReact?.props?.rowData[tableProps.rowIndex].portfolio_code}?</p>
        </div>
      </Modal>
      <Modal
        width='30%'
        show={showEditModal}
        modalElementId='root'
        id={'modal-edit-type'}
        showCloseIcon={false}
        showSecondaryButton={true}
        secondaryButtonClassName="No"
        primaryButtonLabel="No"
        secondaryButtonLabel="Yes"
        onSecondaryButtonClick={() => {
          setShowEditModal(false)
          handleEditClick()
        }}
        onPrimaryButtonClick={() => {
          setShowEditModal(false)
          setPreviousCellData()
        }}
        data-testid="modal-edit-type">
        <div>
          <label
            htmlFor="editConfirmation"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            Edit Confirmation
          </label>
          <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
            <WideDivider color={dividerColor} height={1} />
          </div>
          <p className={`${className}__paragraph`}>Are you sure you want to edit portfolioID {paramsCellObjectStateRef.current?.oldValue} to {paramsCellObjectStateRef.current?.newValue}?</p>
        </div>
      </Modal>
    </>
  )
}

export default UserMatchPortfolioIdModal;
