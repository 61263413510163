import Auth from '@aws-amplify/auth';

import { BOI_WALLET_USER_STORAGE } from '../constants';
import { FORCE_LOGIN } from '../constants/home';
import {
  getAccessToken,
  getItemFromSessionStorage,
  saveInSessionStorage
} from '../helpers';
import { getDocument } from '../services/common/commonServices';

export const parseBool = str => {
  if (str && typeof str === 'string') {
    return JSON.parse(str.toLowerCase());
  } else {
    return str;
  }
};

export const getCognitoUrl = flag => {
  if (flag) {
    return `https://${process.env.REACT_APP_DOMAIN_NAME}/login?client_id=${
      process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID
    }&response_type=token&scope=email+openid+profile&redirect_uri=http://localhost:3000/`;
  } else {
    return `https://${process.env.REACT_APP_DOMAIN_NAME}/login?client_id=${
      process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID
    }&response_type=token&scope=email+openid+profile&redirect_uri=${
      process.env.PUBLIC_URL
    }`;
  }
};

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const maxLengthErrorMsg = maxLength => {
  return `Maximum length is ${maxLength} characters`;
};

export const specifiedItemsMaxLengthErrorMsg = maxLength => {
  return `Enter not more than ${maxLength} characters`;
};

export const modifyBranchList = countyList => {
  let tempBranchList = [];
  countyList.forEach(item => {
    const branchObj = {
      id: item?.id,
      key: item?.branch_name.toUpperCase() || item.toUpperCase(),
      value: item?.branch_name.toUpperCase() || item.toUpperCase(),
      text: item?.branch_name || item
    };
    tempBranchList.push(branchObj);
  });
  return tempBranchList;
};

export const downloadURLConstruct = (blobURL, fileName) => {
  const link = document.createElement('a');
  link.setAttribute('target', '_blank');
  link.rel = 'noopener noreferrer';
  link.setAttribute('download', `${fileName}.pdf`);
  link.href = blobURL;
  document.body.appendChild(link);
  link.click();
};

export const base64ToBlob = base64 => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; ++i) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return new Blob([bytes], { type: 'application/pdf' });
};

export const addIrishCountryCode = phoneNo => {
  let phoneNumber = phoneNo;
  return phoneNumber[0] === '0'
    ? `+353${phoneNumber.substring(1)}`
    : phoneNumber;
};

export const checkCognitoUser = () => {
  const cognitoAccessTokenKey = getAccessToken(
    JSON.parse(getItemFromSessionStorage(BOI_WALLET_USER_STORAGE))
  );
  return cognitoAccessTokenKey;
};

export const groupBy = (arr, key) => {
  return arr.reduce((acc, curr) => {
    let groupKey = curr[key];
    if (!acc[groupKey]) {
      acc[groupKey] = [];
    }
    acc[groupKey].push(curr);
    return acc;
  }, {});
};

export const getUnique = (arr, key) => {
  const output = Object.values(
    arr.reduce((r, o) => {
      if (!r[o[key]]) {
        r[o[key]] = o;
      }
      return r;
    }, {})
  );
  return output;
};

export const sortRenewalPolicyDocuments = (
  // eslint-disable-next-line flowtype/no-types-missing-file-annotation
  documents: any,
  // eslint-disable-next-line flowtype/no-types-missing-file-annotation
  order: string[],
  // eslint-disable-next-line flowtype/no-types-missing-file-annotation
  key: string
) => {
  let mainArr = [];
  let otherArr = [];

  documents.forEach(data => {
    if (!order.includes(data[key].toLowerCase())) {
      otherArr.push(data);
    } else if (mainArr.length < order.length) {
      order.forEach(elem => {
        const keyEle = documents.find(item => {
          if (item[key].toLowerCase() === elem) {
            return item;
          }
        });
        if (keyEle) {
          mainArr.push(keyEle);
        }
      });
    }
  });
  const getUniqueArr = getUnique(mainArr, key);
  return [...getUniqueArr, ...otherArr];
};

export const getDownloadLink = async (e, id, fileName, url) => {
  e.preventDefault();
  const documentFile = await getDocument(id, url);
  if (documentFile.data) {
    const blob = base64ToBlob(documentFile.data);
    const blobURL = URL.createObjectURL(blob);
    downloadURLConstruct(blobURL, fileName);
  }
};

export const checkObectKeysHasData = dataSet => {
  if (dataSet) {
    const response = Object.keys(dataSet).find(
      data => dataSet[data].length > 0
    );
    return response;
  } else {
    return false;
  }
};

export const startSessionCheck = () => {
  if (!isLocalhost) {
    // eslint-disable-next-line complexity
    const intervalId = setInterval(async () => {
      try {
        let currentUser = await Auth.currentUserInfo();
        if (!currentUser || Object.keys(currentUser).length === 0) {
          Auth.signOut();
          const baseUrl = process.env.PUBLIC_URL || '/';
          window.location.replace(`${baseUrl}`);
          clearInterval(intervalId);
        }
      } catch (e) {
        console.log(`failure: ${JSON.stringify(e)}`);
      }
    }, 5000);
    saveInSessionStorage('sessionIntervalId', intervalId);
  }
};

export const goToUrlForcingLoggedIn = (url, history) => {
  saveInSessionStorage(
    FORCE_LOGIN,
    JSON.stringify({ forceLogIn: true, url })
  );
  history.push(url);
}
