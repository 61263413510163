"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var PlaneIcon = function PlaneIcon(_ref) {
  var color = _ref.color;
  return _react2.default.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 53.104 47.203",
    style: {
      fill: color,
      enableBackground: 'new 0 0 63 63'
    },
    alt: "Travel"
  }, _react2.default.createElement("path", {
    className: "a",
    d: "M44.256,17.7H33.719L24.029.743A1.476,1.476,0,0,0,22.748,0H16.709a1.475,1.475,0,0,0-1.418,1.88L19.811,17.7H10.328l-3.983-5.31a1.475,1.475,0,0,0-1.18-.59H1.478A1.475,1.475,0,0,0,.048,13.634L2.953,23.6.048,33.57A1.475,1.475,0,0,0,1.478,35.4H5.165a1.474,1.474,0,0,0,1.18-.59l3.983-5.31h9.483l-4.52,15.821A1.475,1.475,0,0,0,16.709,47.2h6.039a1.476,1.476,0,0,0,1.281-.743L33.719,29.5H44.256c3.259,0,8.851-2.641,8.851-5.9S47.515,17.7,44.256,17.7Z",
    transform: "translate(-0.003)"
  }));
};

exports.default = PlaneIcon;