/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

export const IdipUrl = {
  Prestige: '//personalbanking.bankofireland.com/app/uploads/BOI-Motor-Prestige-Product-IPID-0324.pdf',
  Popular: '//personalbanking.bankofireland.com/app/uploads/BOI-Motor-Popular-Product-IPID-0324.pdf',
};

export const termsOfBusinessUrl = '//personalbanking.bankofireland.com/app/uploads/BOI-TOBs-RSCC8163-1024.pdf';
export const termsOfUseUrl = '//boi.com/insurancehometou';
export const dataPrivacyMotorUrl = '//boi.com/insurancemotordpn';
export const dataPrivacyHomeUrl = '//boi.com/insurancehomedpn';
export const commissionStatementSummaryUrl = '//boi.com/insurancecss';
