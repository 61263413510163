/* eslint-disable react/prop-types */
/* eslint-disable flowtype/require-parameter-type */
// @flow

import './MobileHeader.scss';

import { BOIWhiteLogo } from '@boi/core/lib';
import {
  faSignInAlt,
  faSignOutAlt,
  faUser,
  faUserCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { CognitoContext } from '../../CognitoUtils';
import { darkBlue, greyDark } from '../../constants/colors';
import { isAgent } from '../../helpers';
import { getAgentName } from '../../helpers/AgentCheck';
import { routes } from '../../routes';
import Navigation from '../Navigation';
import PopOverWrapper from '../PopOverWrapper/PopOverWrapper';

const MobileHeader = ({ history }) => {
  const className = 'c-MobileHeader';
  const { cognitoUser } = useContext(CognitoContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userPopOverVisible, setUserPopOverVisible] = useState(false);

  const getNewQuoteDropdownItems = () => {
    return [
      {
        displayText: 'Car',
        link: `${routes.getGenericQuotePage.url}car`
      },
      {
        displayText: 'Home',
        link: `${routes.getQuotePage.url}aboutYou`
      },
      {
        displayText: 'Travel',
        link: `${routes.getGenericQuotePage.url}travel`
      }
    ];
  };

  const getLoggedInNavigationItems = () => {
    return [
      [
        { displayText: 'Home', link: routes.wallet.url, list: false },
        {
          displayText: 'New Quote',
          link: routes.loginPage.url,
          list: true,
          listItems: getNewQuoteDropdownItems()
        },
        {
          displayText: 'Manage My Insurance',
          link: routes.manageMyInsuranceDashboard.url,
          list: false
        },
        {
          displayText: 'Renew',
          link: `${routes.viewMultiplePolicies.url}home`,
          list: false
        }
      ],
      [{ displayText: 'Logout', link: routes.logoutPage.url, list: false }]
    ];
  };

  const getLoggedOutNavigationItems = () => {
    return [
      [
        { displayText: 'Home', link: routes.wallet.url, list: false },
        {
          displayText: 'New Quote',
          link: routes.loginPage.url,
          list: true,
          listItems: getNewQuoteDropdownItems()
        },
        {
          displayText: 'Manage My Insurance',
          link: routes.loginPage.url,
          list: false
        },
        {
          displayText: 'Renew',
          link: `${routes.viewMultiplePolicies.url}home`,
          list: false
        }
      ],
      [
        { displayText: 'Login', link: routes.loginPage.url, list: false },
        { displayText: 'Sign Up', link: routes.signUp.url, list: false }
      ]
    ];
  };

  const navigationItems = isLoggedIn
    ? getLoggedInNavigationItems()
    : getLoggedOutNavigationItems();
  const reRoute = (action: string) => {
    switch (action) {
      case 'login':
        history.push(routes.loginPage.url);
        break;
      case 'logout':
        history.push(routes.logoutPage.url);
        break;
      case 'signIn':
        history.push(routes.signUp.url);
        break;
    }
    setUserPopOverVisible(false);
  };

  // eslint-disable-next-line complexity
  const menuItems = () => {
    return (
      <>
        <div
          className={`${className}__menuItemGrid`}
          onClick={() => {
            reRoute(!isLoggedIn ? 'login' : 'logout');
          }}
          tabIndex={0}
        >
          <div>
            {!isLoggedIn && !isAgent() ? (
              <span>Log In</span>
            ) : (
              <span>Logout</span>
            )}
            {!isLoggedIn && isAgent() ? <span>Logout</span> : <></>}
          </div>
          <div>
            {!isAgent() && (
              <FontAwesomeIcon
                icon={isLoggedIn ? faSignOutAlt : faSignInAlt}
                size="1x"
                color={greyDark}
              />
            )}
            {isAgent() && isLoggedIn && (
              <FontAwesomeIcon icon={faSignOutAlt} size="1x" color={greyDark} />
            )}
          </div>
        </div>
        {!isLoggedIn && !isAgent() && (
          <div
            className={`${className}__menuItemGrid`}
            tabIndex={0}
            onClick={() => {
              reRoute('signIn');
            }}
          >
            <div>
              <span>Sign Up</span>
            </div>
            <div>
              <FontAwesomeIcon icon={faUser} size="1x" color={greyDark} />
            </div>
          </div>
        )}
      </>
    );
  };

  const titleHeader = () => {
    const expandOnTab = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
      if (['Enter','ArrowDown'].includes(e.key)) {
        setUserPopOverVisible(true);
      }
    }

    return (
      <span tabIndex={0}
           onKeyDown={expandOnTab}>
        {!isLoggedIn && (
          <FontAwesomeIcon
            className={`${className}__navBarIcon`}
            icon={faUserCircle}
            size="1x"
            color={greyDark}
          />
        )}
        {isLoggedIn && (
          <>
            <span className={`${className}__navBarUserCircleIcon`}>
              {cognitoUser?.attributes?.given_name || getAgentName(cognitoUser)}
            </span>
            <FontAwesomeIcon
              className={`${className}__navBarIcon`}
              icon={faUserCircle}
              size="1x"
              color={greyDark}
            />
          </>
        )}
      </span>
    );
  };

  useEffect(() => {
    setIsLoggedIn(!!cognitoUser);
  }, [cognitoUser]);

  return (
    <div className={`${className}`}>
      <div
        className={`${className}__menuItems ${className}${
          isLoggedIn ? '__loggedInMenuItem' : '__nonLoggedInMenuItem'
        }`}
      >
        <div className={`${className}__popOverMenuItems`}>
          <PopOverWrapper
            clickTitle={titleHeader()}
            visibility={userPopOverVisible}
            updateVisibility={setUserPopOverVisible}
          >
            {menuItems()}
          </PopOverWrapper>
        </div>
      </div>
      <div
        className={`${className}__logo`}
        onClick={() => history.push(routes.wallet.url)}
      >
        <BOIWhiteLogo color={darkBlue} />
      </div>
      <div className={`${className}__barIcon`}>
        <Navigation menuItems={navigationItems} />
      </div>
    </div>
  );
};

export default withRouter(MobileHeader);
