import { basePath } from '../constants/commonConstants';
import { CAR_INSURANCE_TYPE } from '../constants/insuranceTypeConstants';
import { makeRequest } from '../helpers';

export const storeTemporaryQuote = (insuranceType, quote) => 
  sendRequest(insuranceType, 'POST', JSON.stringify(quote));

export const retrieveTemporaryQuote = insuranceType =>
  sendRequest(insuranceType, 'GET').then(response =>
    response && !response?.error ? JSON.parse(response) : undefined
  );

export const removeTemporaryQuote = insuranceType =>
  sendRequest(insuranceType, 'DELETE');

const sendRequest = (insuranceType, method, payload) =>
  makeRequest({
    method,
    url: `${basePath}/profile/profile/tempQuote/${insuranceTypeToPolicyType(
      insuranceType
    )}`,
    payload,
  });

const insuranceTypeToPolicyType = insuranceType =>
  insuranceType === CAR_INSURANCE_TYPE ? 'MOTOR' : insuranceType.toUpperCase();
