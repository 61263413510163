/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './Signup.scss';
import '../../styles/commonPageStyles.scss';

import Auth from '@aws-amplify/auth';
import {
  AccordionText,
  BOIGroupLogo,
  Button,
  InputField,
  TitleWithUnderLine
} from '@boi/core/lib';
import { Form, FormikProps, withFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import { config, customer_config } from '../../amplify.config';
import {
  commonFormStylesIdentifier,
  commonPageStylesIdentifier,
  EMAIL_TO_REGISTER_STORAGE,
  HOME_QUOTE_THANK_YOU_STORAGE,
  SELECTED_HOME_QUOTE_STORAGE,
  SIGN_UP_PAGE_EMAIL_STORAGE
} from '../../constants';
import { primaryBlue } from '../../constants/colors';
import {
  FIRST_NAME_ERROR_TEXT,
  FORCE_LOGIN,
  LAST_NAME_ERROR_TEXT
} from '../../constants/home';
import {
  passswordMsg,
  passwordRegexPattern,
  phoneNoRegex
} from '../../constants/loginRegistrationConstants';
import {
  getItemFromSessionStorage,
  getObjectFromSessionStorage,
  isAgent,
  makeRequest,
  removeFromSessionStorage,
  saveInSessionStorage
} from '../../helpers';
import { isFieldError } from '../../helpers/FieldErrorHelper';
import { routes } from '../../routes';
import { addIrishCountryCode } from '../../utils/utils';

type Props = {};
type Values = {
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  phoneNo: string
};
const registrationFields = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  password: 'password',
  confirmPassword: 'confirmPassword',
  phoneNo: 'phoneNo'
};

// $FlowFixMe
var generator = require('generate-password');

const getEmailFromStorage = () =>
  getItemFromSessionStorage(EMAIL_TO_REGISTER_STORAGE);

const mapNewProfile = () => {
  let quoteData = getObjectFromSessionStorage(HOME_QUOTE_THANK_YOU_STORAGE);
  let selectedQuote = getObjectFromSessionStorage(SELECTED_HOME_QUOTE_STORAGE);
  return {
    title: quoteData?.aboutYouObj.title,
    first_name: quoteData?.aboutYouObj.firstName,
    last_name: quoteData?.aboutYouObj.lastName,
    address: {
      address_line1: quoteData?.aboutYouObj?.addressLine1,
      address_line2: quoteData?.aboutYouObj?.addressLine2,
      address_line3: quoteData?.aboutYouObj?.town,
      town: quoteData?.aboutYouObj?.town,
      county: quoteData?.aboutYouObj?.county,
      eircode: quoteData?.aboutYouObj?.eircode
    },
    analyse_transactions: null,
    email: quoteData?.aboutYouObj?.email,
    quote_reference: selectedQuote?.quote_reference
  };
};

const createProfile = async () => {
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  let profileData = mapNewProfile();
  const quoteRequest = makeRequest({
    url: `${basePath}/profile/profile/`,
    method: 'POST',
    payload: profileData
  });

  return await quoteRequest;
};

const agentCreateUserProfile = async () => {
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  let profileData = mapNewProfile();
  const quoteRequest = makeRequest({
    url: `${basePath}/profile/profile/create/user`,
    method: 'POST',
    payload: profileData
  });

  return await quoteRequest;
};

//get quote from session storage -> extract quote reference

const SignupForm = (props: Props & FormikProps<Values>) => {
  const className = 'c-Signup';
  const {
    errors,
    handleBlur,
    handleChange,
    isValid,
    touched,
    values,
    setFieldValue
  } = props;

  const [responseError, setResponseError] = useState('');
  const [agentPaymentJourney, setAgentPaymentJourney] = useState(false);

  const generatePassword = useCallback(() => {
    // $FlowFixMe
    var password = generator.generate({
      length: 12,
      numbers: true,
      symbols: true,
      lowercase: true,
      uppercase: true,
      strict: true
    });
    return password;
  }, []);

  const signup = async (fieldValues: Values) => {
    if (isAgent()) {
      Auth.configure(customer_config);
    }
    Auth.signUp({
      username: fieldValues.email,
      password: fieldValues.password,
      attributes: {
        given_name: fieldValues.firstName,
        family_name: fieldValues.lastName,
        email: fieldValues.email,
        phone_number: addIrishCountryCode(fieldValues.phoneNo)
      }
    })
      .then(() => {
        if (agentPaymentJourney) {
          Auth.configure(config);
          props.history.push(`${routes.makePayment.url}home`);
          agentCreateUserProfile();
        } else {
          saveInSessionStorage(SIGN_UP_PAGE_EMAIL_STORAGE, fieldValues.email);
          removeFromSessionStorage(EMAIL_TO_REGISTER_STORAGE);
          props.history.push({
            pathname: routes.verifyEmail.url,
            state: { email: fieldValues.email }
          });
          createProfile();
        }
      })
      .catch((e: Error) => {
        setResponseError(e.message);
      });
  };

  // eslint-disable-next-line complexity
  useEffect(() => {
    const forceLoginData =
      JSON.parse(getItemFromSessionStorage(FORCE_LOGIN)) || '';
    if (forceLoginData) {
      const userDetails =
        forceLoginData.insuranceType === 'home'
          ? JSON.parse(
              getItemFromSessionStorage(`${forceLoginData.insuranceType}Quote`)
            )
          : JSON.parse(
              getItemFromSessionStorage(
                `${forceLoginData.insuranceType}GetQuoteValues`
              )
            );
      if (isAgent()) {
        setAgentPaymentJourney(forceLoginData);
        const fakePassword = generatePassword();
        setFieldValue(registrationFields.password, fakePassword);
        setFieldValue(registrationFields.confirmPassword, fakePassword);
      }
      setFieldValue(
        registrationFields.firstName,
        userDetails?.aboutYouObj?.firstName || userDetails?.firstName || ''
      );
      setFieldValue(
        registrationFields.lastName,
        userDetails?.aboutYouObj?.lastName || userDetails?.lastName || ''
      );
      setFieldValue(
        registrationFields.email,
        userDetails?.aboutYouObj?.email || userDetails?.email || ''
      );
      setFieldValue(
        registrationFields.phoneNo,
        userDetails?.aboutYouObj?.phoneNo || userDetails?.phoneNo || ''
      );
    }
  }, []);

  return (
    <div className={`${className}`}>
      <div className={`${className}__container`}>
        <div className={`${className}__innerContent`}>
          <div className={`${commonPageStylesIdentifier}__logo--flex`}>
            <BOIGroupLogo color={primaryBlue} />
          </div>
          <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
            <div className={`${className}__mobileTitle`}>
              <TitleWithUnderLine>Create your account</TitleWithUnderLine>
            </div>
          </div>

          <div className={`${commonPageStylesIdentifier}__showOnDesktopOnly`}>
            <div className={`${commonPageStylesIdentifier}__desktopTitle`}>
              Create your account
            </div>
          </div>

          <Form>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor={registrationFields.firstName}
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                Name
              </label>
              <span className={`${className}__input`}>
                <InputField
                  error={isFieldError('firstName', touched, errors)}
                  errorMessage={errors.firstName}
                  name={registrationFields.firstName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter first name"
                  touched={touched.firstName}
                  type="text"
                  value={values.firstName}
                />
              </span>
            </div>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor={registrationFields.lastName}
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                Surname
              </label>
              <span className={`${className}__input`}>
                <InputField
                  error={isFieldError('lastName', touched, errors)}
                  errorMessage={errors.lastName}
                  name={registrationFields.lastName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter surname"
                  touched={touched.lastName}
                  type="text"
                  value={values.lastName}
                />
              </span>
            </div>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="phoneNo"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                Mobile Number
              </label>
              <div className={`${commonFormStylesIdentifier}__infoText`}>
                In order to complete your account setup, to keep your account
                safe, you must setup Multi Factor Authentication. Multifactor
                Authentication uses an additional verification method at login
                to verify your identity. You can set this up below by entering a
                valid mobile number to receive a code by SMS, which can then be
                entered at login.
              </div>
              <span className={`${className}__input`}>
                <InputField
                  error={isFieldError('phoneNo', touched, errors)}
                  errorMessage={errors.phoneNo}
                  name="phoneNo"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter mobile number"
                  touched={touched.phoneNo}
                  type="text"
                  value={values.phoneNo}
                />
              </span>
            </div>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor={registrationFields.email}
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                Email address
              </label>
              <span className={`${className}__input`}>
                <InputField
                  error={isFieldError('email', touched, errors)}
                  errorMessage={errors.email}
                  name={registrationFields.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter email address"
                  touched={touched.email}
                  type="email"
                  value={values.email}
                />
              </span>
            </div>
            {!isAgent() && (
              <>
                <div
                  className={`${commonFormStylesIdentifier}__fieldContainer`}
                >
                  <label
                    htmlFor="password"
                    className={`${commonFormStylesIdentifier}__fieldLabel`}
                  >
                    <AccordionText
                      label="Password"
                      icon="info"
                      iconAlign="right"
                      customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
                    >
                      <div
                        className={`${commonFormStylesIdentifier}__infoText`}
                      >
                        Your password should be at least 12 characters which
                        should include 1 uppercase, 1 lowercase, 1 special
                        character and 1 number.
                      </div>
                    </AccordionText>
                  </label>
                  <span className={`${className}__input`}>
                    <InputField
                      error={isFieldError('password', touched, errors)}
                      errorMessage={errors.password}
                      name={registrationFields.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter password"
                      touched={touched.password}
                      type="password"
                      value={values.password}
                    />
                  </span>
                </div>
                <div
                  className={`${commonFormStylesIdentifier}__fieldContainer`}
                >
                  <label
                    htmlFor={registrationFields.confirmPassword}
                    className={`${commonFormStylesIdentifier}__fieldLabel`}
                  >
                    Confirm Password
                  </label>
                  <span className={`${className}__input`}>
                    <InputField
                      error={isFieldError('confirmPassword', touched, errors)}
                      errorMessage={errors.confirmPassword}
                      name={registrationFields.confirmPassword}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Confirm password"
                      touched={touched.confirmPassword}
                      type="password"
                      value={values.confirmPassword}
                    />
                  </span>
                </div>
              </>
            )}

            <div className={`${className}__responseError`}>{responseError}</div>
            <div className={`${className}__button`}>
              <Button
                id="SignupPage__signupButton"
                fluid
                quaternary
                disabled={!isValid}
                onClick={async () => {
                  await signup(values);
                }}
                type="submit"
              >
                Sign up
              </Button>
            </div>
          </Form>

          <Link
            to={{ pathname: routes.loginPage.url }}
            className={`${className}__link`}
            data-ga
            id="SignupPage__loginLink"
            data-testid="SignupPage__loginLink"
          >
            Already have an Account? Click here to sign in
          </Link>
        </div>
      </div>
    </div>
  );
};

const Signup = withFormik<Props, Values>({
  mapPropsToValues(): {} {
    const email = getEmailFromStorage();
    return {
      firstName: '',
      lastName: '',
      email: email ? email : '',
      password: '',
      confirmPassword: '',
      phoneNo: ''
    };
  },
  handleSubmit(values: Values, { setSubmitting }: FormikProps) {
    setSubmitting(false);
  },
  validationSchema: yup.object().shape({
    firstName: yup
      .string()
      .required(FIRST_NAME_ERROR_TEXT)
      .matches(
        /^[a-zA-Z ]+$/,
        'Numbers and symbols are not permitted at this time'
      ),
    lastName: yup
      .string()
      .required(LAST_NAME_ERROR_TEXT)
      .matches(
        /^[a-zA-Z ]+$/,
        'Numbers and symbols are not permitted at this time'
      ),
    phoneNo: yup
      .string()
      .matches(phoneNoRegex, 'Must be a valid Mobile number')
      .max(10, 'Mobile Number Must be 10 digit')
      .required('Mobile number is required'),
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required('Email is required'),
    password: yup
      .string()
      .required('Password is required')
      .matches(passwordRegexPattern, passswordMsg),
    confirmPassword: yup
      .string()
      .required('Please confirm password')
      .matches(passwordRegexPattern, passswordMsg)
      .test('passwords-match', 'Passwords must match', function(
        value: string
      ): boolean {
        return this.parent.password === value;
      })
  }),
  displayName: 'SignupForm'
})(SignupForm);

export default Signup;
