/* eslint-disable flowtype/no-types-missing-file-annotation */
import { ButtonGroup, Dropdown, InputField } from '@boi/core/lib';
import React from 'react';

import { commonFormStylesIdentifier } from '../../../../constants';
import mortgageProviders from '../../../../constants/dropdowns/mortgageProviders';
import { STAFF_CONSTANT } from '../../../../constants/home';
import { isFieldError, mapDropdownOptions } from '../../../../helpers';
import { saveData } from '../../../../helpers/HomeCommonMethods';
import { validatePromoAlias } from '../../../../services/common/commonServices';
import { ChildComponentProps } from '../models/AboutYouFormModels';

// eslint-disable-next-line complexity
const AboutYouFormMisc = (props: ChildComponentProps) => {
  const {
    className,
    setFieldValue,
    setFieldTouched,
    touched,
    values,
    handleChange,
    errors,
    handleCustomBlurFunc,
    updatePromoCode,
    promoCodeValid,
    setAboutYouObjForUpdatedFields,
    updatePromoCodeRequestSent,
    promoCodeRequestSent
  } = props;

  const getMortgageProvider = () => {
    const mortgageProvidersDropdown = [...mortgageProviders];
    // eslint-disable-next-line complexity
    mortgageProvidersDropdown.sort((a, b) => {
      const firstCharA = a.displayName.charAt(0).toLowerCase();
      const firstCharB = b.displayName.charAt(0).toLowerCase();
      if (a.displayName === 'No Mortgage') return -1;
      if (b.displayName === 'No Mortgage') return 1;
      if (firstCharA < firstCharB) {
        return -1;
      }
      if (firstCharA > firstCharB) {
        return 1;
      }
      return 0;
    });

    return mortgageProvidersDropdown;
  };

  const showMortgageApplicationNumberField = () => {
    if (values?.boiMortgage === true || values?.firstTimeBuyer === true) {
      return (
        <div
          className={`${commonFormStylesIdentifier}__fieldContainer ${commonFormStylesIdentifier}--withNoMargin  ${commonFormStylesIdentifier}--withMarginTop`}
        >
          <div
            className={`${commonFormStylesIdentifier}__fieldContainer ${commonFormStylesIdentifier}--withMarginTop`}
          >
            <label
              htmlFor="mortgageApplicationNumber"
              className={`${commonFormStylesIdentifier}__fieldLabel`}
            >
              Mortgage application number (for BOI Mortgage holders only)
            </label>
            <span className={`${className}__input`}>
            <InputField
              name="mortgageApplicationNumber"
              placeholder="Type here"
              value={values?.mortgageApplicationNumber}
              onChange={e => {
                handleChange(e);
                saveData(
                  'aboutYouObj',
                  'mortgageApplicationNumber',
                  e.target.value
                );
              }}
              type="text"
              maxLength={8}
              onBlur={e => {
                handleCustomBlurFunc(e, 'mortgageApplicationNumber');
              }}
              error={isFieldError('mortgageApplicationNumber', touched, errors)}
              errorMessage={errors?.mortgageApplicationNumber}
            />
          </span>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const renderStaffInputField = () => {
    return (
      <div
        className={`${commonFormStylesIdentifier}__fieldContainer  ${commonFormStylesIdentifier}--withNoMargin ${commonFormStylesIdentifier}--withMarginTop`}
      >
        <label
          htmlFor="staffNumber"
          className={`${commonFormStylesIdentifier}__fieldLabel`}
        >
          Please enter your staff number
        </label>
        <InputField
          error={isFieldError('staffNumber', touched, errors)}
          errorMessage={errors?.staffNumber}
          name="staffNumber"
          placeholder="A1234567"
          value={values?.staffNumber}
          onChange={e => {
            handleChange(e);
            saveData('aboutYouObj', 'staffNumber', e.target.value);
          }}
          type="text"
          onBlur={e => {
            handleCustomBlurFunc(e, 'staffNumber');
          }}
        />
      </div>
    );
  };

  async function submitPromocode() {
    if (values?.promoCode.length > 0) {
      let validPromo = await validatePromoAlias(values.promoCode);
      updatePromoCode(validPromo);
      updatePromoCodeRequestSent(true);
      setFieldValue('promoCodeValidChk', validPromo);
      setFieldValue('promoCodeRequestSent', true);
    }
  }

  return (
    <div className={`${commonFormStylesIdentifier}__nonDynamicFieldsContainer`}>
      <div
        className={`${commonFormStylesIdentifier}__fieldContainer ${commonFormStylesIdentifier}--withNoMargin  ${commonFormStylesIdentifier}--withMarginTop`}
      >
        <label
          htmlFor="firstTimeBuyer"
          className={`${commonFormStylesIdentifier}__fieldLabel`}
        >
          Are you a first time buyer?
        </label>
        <span className={`${className}__input`}>
          <div className={`${className}--fieldLabelSpacing`}>
            <ButtonGroup
              name="firstTimeBuyer"
              onClick={(value: boolean) => {
                setFieldValue('firstTimeBuyer', value === 'true');
                setFieldTouched('firstTimeBuyer', value);
                saveData(
                  'aboutYouObj',
                  'firstTimeBuyer',
                  value === 'true' ? 'true' : 'false'
                );
              }}
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' }
              ]}
              touched={touched.firstTimeBuyer}
              selected={values.firstTimeBuyer}
              onBlur={e => {
                handleCustomBlurFunc(e, 'firstTimeBuyer');
              }}
              error={isFieldError('firstTimeBuyer', touched, errors)}
              errorMessage={errors.firstTimeBuyer}
            />
          </div>
        </span>
      </div>
      {showMortgageApplicationNumberField()}
      <div
        className={`${commonFormStylesIdentifier}__fieldContainer ${commonFormStylesIdentifier}--withNoMargin ${commonFormStylesIdentifier}--withMarginTop`}
      >
        <label
          htmlFor="mortgageProvider"
          className={`${commonFormStylesIdentifier}__fieldLabel`}
        >
          Do you have a mortgage on this property? If so, please select your
          provider.
          <div className={`${className}__secondLineLabel`}>
            Please contact Bank of Ireland Insurance Services on 01-4884062 if
            your mortgage provider is not on the list.
          </div>
        </label>
        <div className={`${commonFormStylesIdentifier}--fieldLabelSpacing`}>
          <Dropdown
            key="mortgageProvider"
            name="mortgageProvider"
            error={isFieldError('mortgageProvider', touched, errors)}
            errorMessage={errors.mortgageProvider}
            value={values.mortgageProvider}
            onBlur={e => {
              handleCustomBlurFunc(e, 'mortgageProvider');
            }}
            onChange={(e: string) => {
              handleChange(e);
              saveData('aboutYouObj', 'mortgageProvider', e.target.value);
            }}
          >
            <option value="">Select here</option>
            {mapDropdownOptions(getMortgageProvider(), 'mortgageProvider')}
          </Dropdown>
        </div>
      </div>
      <div
        className={`${commonFormStylesIdentifier}__fieldContainer  ${commonFormStylesIdentifier}--withNoMargin ${commonFormStylesIdentifier}--withMarginTop`}
      >
        <label
          id={'promo-code-label'}
          htmlFor="promoCode"
          className={`${commonFormStylesIdentifier}__fieldLabel`}
        >
          If you have a promotional code please enter it below.
          <div id={'promo-code-sub-label'} className={`${className}__secondLineLabel`}>
            Please click apply to validate any promotional code changes
          </div>
        </label>
        {promoCodeValid && promoCodeRequestSent ?
          <p className={`${className}__success`}>
            Promotional code successfully applied. Please proceed with your
            quote to see your premium breakdown.
          </p>
          : promoCodeRequestSent ?
            <p className={`${className}__failure`}>
              This is not a valid promo code, no discount will be provided.
              Please contact the Bank of Ireland Insurance Operations team on:
              01 - 4884062 if you believe your discount code is correct to proceed.
            </p>
            : values.promoCode.length > 0 ? <p className={`${className}__failure`}>
              Remember to click apply
            </p> : null}
        <div className={`${className}__promoCodeField`}>
          <InputField
            aria-labelledby="promo-code-label promo-code-sub-label"
            containingDivName={`${className}__promodiv`}
            className={`${className}__inputfield`}
            error={isFieldError('promoCode', touched, errors)}
            errorMessage={errors.promoCode}
            name="promoCode"
            placeholder="E.g. Enter code"
            value={values.promoCode}
            //eslint-disable-next-line flowtype/no-types-missing-file-annotation
            onChange={(e: string) => {
              handleChange(e);
              updatePromoCode(false);
              updatePromoCodeRequestSent(false);
              setAboutYouObjForUpdatedFields('promoCode', e.target.value);
              setFieldValue('staffNumber', '');
              setFieldValue('promoCodeValidChk', false);
              setFieldValue('promoCodeRequestSent', false);
              saveData('aboutYouObj', 'staffNumber', '');
              saveData('aboutYouObj', 'promoCode', e.target.value);
            }}
            type="text"
          />
          <button type="button"
                  className={`${className}__applyPromoCodeBtn`} id="ApplyPromoAliasButton"
                  onClick={async () => {
                    await submitPromocode();
                  }}>{'Apply'}</button>
        </div>
      </div>
      {values.promoCode.toLowerCase() === STAFF_CONSTANT
        ? renderStaffInputField()
        : null}
    </div>
  );
};

export default AboutYouFormMisc;
