/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import { Checkbox } from '@boi/core/lib';
import { format } from 'date-fns';
import React, {useCallback} from 'react';
import { useHistory } from 'react-router-dom'

import { ABOUT_YOU, CAR_INSURANCE_TYPE, HOME_INSURANCE_TYPE } from '../../../constants/insuranceTypeConstants';
import { AGENT_CLICKED_REFERENCE_QUOTES } from '../../../constants/sessionStorage/userStorageIdentifiers';
import { saveInSessionStorage } from '../../../helpers';
import { routes } from '../../../routes';
import type { QuoteSummaryType } from '../../../types/commonTypes/QuoteSummaryType';

interface Props {
  quote: QuoteSummaryType;
  customerContacted: (quote: QuoteSummaryType) => void;
  insuranceType: ?string;
  rowIndex: number;
}

const prepareRowValues = (quote: QuoteSummaryType) => {
  let rowValues = {}
  rowValues.price = (quote.premium_amount || 0).toFixed(2);
  const offset = new Date().getTimezoneOffset() * -1; // Local timezone's difference in minutes, reverse
  const UTCDate = new Date(quote.timestamp) 
  rowValues.date = format(new Date(UTCDate.getTime() + (offset * 60000)), 'DD/MM/YYYY HH:mm');
  rowValues.contactNumber = (quote.contact_number || '').replace(/^(00)/, '+');
  return rowValues
}

const QuoteRow = (props: Props) => {
  const { quote, customerContacted, insuranceType, rowIndex } = props;
  const className = 'c-QuoteRows';
  const columnClassName = `${className}__column`;
  const history = useHistory()

  let contacted = quote.contacted || false;

  const url = insuranceType === HOME_INSURANCE_TYPE ? `${routes.getQuotePage.url}${ABOUT_YOU}` : `${routes.getGenericQuotePage.url}${CAR_INSURANCE_TYPE}`

  const rowValues = prepareRowValues(quote)

  const handleURLClick = useCallback((email: string) => {
    saveInSessionStorage(AGENT_CLICKED_REFERENCE_QUOTES, email)
    history.push(url)
  }, [])

  return (
    <>
      <div className={columnClassName}>
        <Checkbox
          id={`contacted_${rowIndex}`}
          name={`contacted_${rowIndex}`}
          label=""
          labelFontSize={14}
          isChecked={contacted}
          value={contacted}
          disabled={contacted}
          onChange={() => customerContacted(quote)}
          isEnabled={false}
        />
      </div>
      <div className={`${columnClassName}__link`}>
        <a onClick={() => {
          handleURLClick(quote.email)
          }}>
          {quote.quote_reference}
        </a>
      </div>
      <div className={columnClassName}>{quote.email}</div>
      <div className={columnClassName}>{`${quote.first_name} ${
        quote.last_name
        }`}</div>
      <div className={columnClassName}>{`€ ${rowValues.price}`}</div>
      <div className={columnClassName}>{rowValues.contactNumber}</div>
      <div className={`${columnClassName} ${columnClassName}__last`}>
        {rowValues.date}
      </div>
    </>
  );
};

export default QuoteRow;
