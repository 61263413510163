/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './AgentLatestQuotes.scss';

import { Button, Text } from '@boi/core/lib';
import React, { useEffect, useState } from 'react';
import type { Match } from 'react-router-dom';

import AnimatedSpinner from '../../components/AnimatedSpinner/AnimatedSpinner';
import { iconsAndTitles } from '../../constants';
import { HOME_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import {
  getLatestQuotes,
  updateContactedStatus
} from '../../services/agent/latestQuotesService';
import type { QuoteSummaryType, RouterPropsType } from '../../types';
import { QuoteRow } from './';

const getInsuranceType = (match: Match) =>
  match && match.params && match.params.type;

const AgentLatestQuotes = (props: RouterPropsType) => {
  const className = 'c-AgentLatestQuotes';

  const { match } = props;

  const [loading, setLoading] = useState(false);
  const [quotes, setQuotes] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 7;

  const insuranceType = getInsuranceType(match);

  const isHome = insuranceType === HOME_INSURANCE_TYPE;

  const getQuotes = async () => {
    setLoading(true);
    const latestQuotes = await getLatestQuotes(pageSize, pageNumber, isHome);

    setLoading(false);
    if (latestQuotes.content) {
      setPageNumber(pageNumber + 1);
      setTotalPages(latestQuotes.total_pages);
      setQuotes([...quotes, ...latestQuotes.content]);
    }
  };

  useEffect(() => {
    async function initQuotesList(): any {
      await getQuotes();
    }
    initQuotesList();
  }, []);

  const handleCustomerContacted = (clickedQuote: QuoteSummaryType) => {
    setQuotes(
      quotes.map((quote: QuoteSummaryType) =>
        quote.quote_reference === clickedQuote.quote_reference
          ? { ...quote, contacted: true }
          : quote
      )
    );
    updateContactedStatus(clickedQuote.quote_reference, true, isHome).then(
      setTimeout(() => {
        setQuotes(
          quotes.filter(
            (quote: QuoteSummaryType) =>
              quote.quote_reference !== clickedQuote.quote_reference
          )
        );
      }, 3000)
    );
  };

  const renderTableHeader = () => {
    const tableHeaderClassName = 'c-QuoteRows';
    const tableHeaderColumnClassName = `${tableHeaderClassName}__column ${tableHeaderClassName}__column--header`;
    const headers = [
      'Contacted Status',
      'Quote Reference',
      'Email',
      'Customer Name',
      'Premium Amount',
      'Customer Contact Number',
      'Timestamp'
    ];

    return (
      <>
        {headers.map((label: string, key: number) => {
          return (
            <div
              className={`${tableHeaderColumnClassName}${
                key === 6 ? ` ${tableHeaderColumnClassName}__last` : ''
              }`}
              key={key}
            >
              {label}
            </div>
          );
        })}
      </>
    );
  };

  const renderIcon = () => insuranceType && iconsAndTitles[insuranceType].icon;

  return (
    <div className={className}>
      <div className={`${className}__titleContainer`}>
        <div className={`${className}__iconContainer`}>
          <div className={`${className}__icon`}>{renderIcon()}</div>
        </div>
        <div className={`${className}__titleText`}>
          <Text size="inherit">
            {isHome ? 'Live Home Quotes' : 'Live Car Quotes'}
          </Text>
        </div>
      </div>
      <div className={`${className}__quotesContainer`}>
        <div className={`c-QuoteRows`}>
          {renderTableHeader()}
          {quotes.map((quote: QuoteSummaryType, key: number) => {
            return (
              <QuoteRow
                key={key}
                rowIndex={key}
                quote={quote}
                customerContacted={handleCustomerContacted}
                insuranceType={insuranceType}
              />
            );
          })}
        </div>
      </div>
      {loading && <AnimatedSpinner size="large" />}
      {pageNumber < totalPages && (
        <div className={`${className}__loadMore`}>
          <Button
            id="LatestQuotes__loadMoreButton"
            fluid
            quaternary
            onClick={() => {
              getQuotes();
            }}
            type="submit"
          >
            LOAD MORE
          </Button>
        </div>
      )}
    </div>
  );
};

export default AgentLatestQuotes;
