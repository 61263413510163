// @flow

import './HomePremiumBreakdown.scss';

import {
  AccordionCard,
  ButtonGroup,
  Checkbox,
  WideDivider
} from '@boi/core/lib';
import { format } from 'date-fns';
import React, { PureComponent } from 'react';

import ClaimsSummary from '../../../components/ClaimsSummary/ClaimsSummary';
import type YourCoverFormValues from '../../../components/Home/YourCoverForm';
import {
  commonFormStylesIdentifier,
  SELECTED_HOME_QUOTE_STORAGE
} from '../../../constants';
import { dividerColor } from '../../../constants/colors';
import { EXCESS, EXCESS_AMOUNT_MAPPER_REVERSE } from '../../../constants/home';
import {
  AFTER_SALES_DISCOUNT,
  OPTIONAL_EXTRAS,
  premiumBreakDownConstants
} from '../../../constants/insuranceTypeConstants';
import { AUTO_RENEWAL_EXISTING_PAYMENT } from '../../../constants/sessionStorage/genericStorageIdentifiers';
import {
  getObjectFromSessionStorage,
  isAgent,
  saveInSessionStorage
} from '../../../helpers';
import type { PremiumDetails,SpecifiedItem, UpdatedPremiumDetailsType } from '../../../types';
import { noop, parseBool } from '../../../utils/utils';
import { PremiumBreakdownRow } from '../PremiumBreakdownRow';
import { PropertyMapper } from './AmountMapper';

interface Props {
  premiumDetails: PremiumDetails[];
  yourCoverObj: YourCoverFormValues;
  unspecifiedItemsValue: string;
  totalPrice: number;
  monthlyPrice: number;
  coverType: string;
  insurer: any;
  insurerType: string;
  claims: any;
  addonDetails: any;
  values: any;
  setFieldValue: noop;
  renewal: boolean;
  alternativePolicy: boolean;
  start_date: string;
  home_premium_discounts_enabled: boolean;
}

const autoRenewalOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
];

class HomePremiumBreakdown extends PureComponent<Props> {
  /* eslint-disable complexity */
  render() {
    const className = 'c-HomePremiumBreakdown';
    const {
      premiumDetails,
      yourCoverObj = {},
      unspecifiedItemsValue,
      insurer,
      insurerType = '',
      addonDetails = [],
      renewal = false,
      values,
      claims = [],
      setFieldValue,
      alternativePolicy = false,
      start_date = '',
      home_premium_discounts_enabled = false
    } = this.props;
    // let buildingsSectionPremiumPrice = 0;
    // let contentsSectionPremiumPrice = 0;
    let unspecifiedPPSectionPremiumPrice = 0;
    let specifiedPPSectionPremiumPrice = 0;
    // let governmentLevyPrice = 0;
    let promotionalDiscountPrice = 0;
    let affinityDiscountPrice = 0;
    let totalDiscountPrice = 0;
    let pedalCycleSectionPremium = 0;
    const coverType = this.props.coverType;

    const setPrices = () => {
      const setPriceActions = {
        // 'Government Levy': (price: number) => (governmentLevyPrice = price),
        // 'Buildings Section Premium': (price: number) =>
        //   (buildingsSectionPremiumPrice = price),
        // 'Contents Section Premium': (price: number) =>
        //   (contentsSectionPremiumPrice = price),
        'Unspecified PP Section Premium': (price: number) =>
          (unspecifiedPPSectionPremiumPrice = price),
        'Specified PP Section Premium': (price: number) =>
          (specifiedPPSectionPremiumPrice = price),
        'Promotional Discount': (price: number) =>
          (promotionalDiscountPrice = price),
        'Affinity Discount': (price: number) => (affinityDiscountPrice = price),
        'Pedal Cycles Section Premium': (price: number) =>
          (pedalCycleSectionPremium = price)
      };

      if (premiumDetails && premiumDetails.length > 0) {
        premiumDetails.forEach((premium: PremiumDetails) => {
          if (setPriceActions[premium.item_name]) {
            setPriceActions[premium.item_name](premium.amount);
          }
        });
      }
    };

    const renderAddonDetails = (addonObj: any, key: string) => {
      if (addonObj?.length > 0) {
        return addonObj.find((item: any) => {
          return item.unique_code === key;
        });
      }
    };

    const getOptionalCoverComponents = () => {
      return totalOptionalCover > 0 ? (
        <>
          <WideDivider color={dividerColor} height={1} />
          <div className={`${className}__section`}>
            <div className={`${className}__heading`}>Optional Cover</div>
            {specifiedPPSectionPremiumPrice !== 0 ? (
              <PremiumBreakdownRow
                label={`Specified items of €${specifiedItemCalculation(
                  yourCoverObj.specifiedItems
                )}`}
                price={
                  specifiedPPSectionPremiumPrice + pedalCycleSectionPremium
                }
              />
            ) : null}

            {unspecifiedPPSectionPremiumPrice !== 0 ? (
              <PremiumBreakdownRow
                label={`Unspecified items of €${unspecifiedItemsValue} `}
                price={unspecifiedPPSectionPremiumPrice}
              />
            ) : null}
            <PremiumBreakdownRow
              label={'Total optional cover'}
              price={totalOptionalCover}
            />
          </div>
        </>
      ) : (
        <></>
      );
    };

    setPrices();
    // const totalMainCover = buildingsSectionPremiumPrice + contentsSectionPremiumPrice;

    const totalOptionalCover =
      unspecifiedPPSectionPremiumPrice +
      specifiedPPSectionPremiumPrice +
      pedalCycleSectionPremium;

    totalDiscountPrice = promotionalDiscountPrice + affinityDiscountPrice;

    const showRebuildCost = () =>
      coverType !== 'rent' &&
      yourCoverObj.rebuildCost &&
      yourCoverObj.rebuildCost !== 'null';
    // const totalPremiumPrice = totalPrice;

    const currencyModifer = (value: string) => {
      if (value) {
        return !value.toString().includes(',')
          ? value
          : value.replace(/,/g, '');
      } else {
        return 0;
      }
    };

    const specifiedItemCalculation = (specifiedItems: any = []) => {
      if (specifiedItems && specifiedItems.length > 0) {
        return specifiedItems.reduce(
          (acc: number, currentValue: SpecifiedItem) =>
            acc + parseFloat(currentValue.value),
          0
        );
      } else {
        return 0;
      }
    };

    // eslint-disable-next-line complexity
    const getPremiumPrice = (codeVal: string): ?UpdatedPremiumDetailsType => {
      let showRunningAmt = false;
      if (premiumDetails && premiumDetails.length > 0) {
        const element: ?PremiumDetails = premiumDetails.find(
          (item: PremiumDetails) => {
            if (
              item.code &&
              insurer &&
              item.code.toString() === insurer[codeVal].toString()
            ) {
              showRunningAmt =
                PropertyMapper[insurerType][codeVal] === 'RUN_AMT'
                  ? true
                  : false;
              return item;
            }
          }
        );
        return { ...element, showRunningAmt };
      } else {
        return {
          amount: 0
        };
      }
    };

    const getOptionalExtrasComponents = () => {
      return (
        <>
          <WideDivider color={dividerColor} height={1} />
          <div className={`${className}__section`}>
            <div className={`${className}__heading`}>Optional Extras</div>
            {getPremiumPrice(premiumBreakDownConstants.CONAD) && (
              <PremiumBreakdownRow
                label={'Accidental Damage for cover for contents'}
                price={renderValue(
                  getPremiumPrice(premiumBreakDownConstants.CONAD)
                )}
              />
            )}
            {getPremiumPrice(premiumBreakDownConstants.PNCD) && (
              <PremiumBreakdownRow
                label={'No Claim Discount Protection Plus'}
                price={renderValue(
                  getPremiumPrice(premiumBreakDownConstants.PNCD)
                )}
              />
            )}
            {getPremiumPrice(premiumBreakDownConstants.HEAOPT) && (
              <PremiumBreakdownRow
                label={'Home Emergency Assist Cover'}
                price={renderValue(
                  getPremiumPrice(premiumBreakDownConstants.HEAOPT)
                )}
              />
            )}
            {getPremiumPrice(premiumBreakDownConstants.AFTEROPTEX) && (
              <PremiumBreakdownRow
                label={'Total Premium with optional extras'}
                price={renderValue(
                  getPremiumPrice(premiumBreakDownConstants.AFTEROPTEX)
                )}
              />
            )}
          </div>
        </>
      );
    };

    const renderValue = (premiumPrice: ?UpdatedPremiumDetailsType): number => {
      if (premiumPrice && Object.keys(premiumPrice).length > 0) {
        if (premiumPrice.showRunningAmt) {
          return premiumPrice.running_total ? premiumPrice.running_total : 0;
        } else {
          return premiumPrice.amount ? premiumPrice.amount : 0;
        }
      } else {
        return 0;
      }
    };

    const getDiscountComponents = () => {
      // Dynamic - only display if customer has qualified for one or more discounts
      const afterSalesRender = () => {
        return (
          getPremiumPrice(premiumBreakDownConstants.BOICUS) ||
          getPremiumPrice(premiumBreakDownConstants.BOIMUL) ||
          getPremiumPrice(premiumBreakDownConstants.BOICAR) ||
          getPremiumPrice(premiumBreakDownConstants.PROMOPCT)
        );
      };

      return (
        <>
          <div className={`${className}__heading`}>{`${
            home_premium_discounts_enabled
              ? 'After Sales Discount'
              : 'Sales Discount'
          }`}</div>
          {home_premium_discounts_enabled && (
            <>
              {getPremiumPrice(premiumBreakDownConstants.BOICUS) && (
                <PremiumBreakdownRow
                  label={'Bank of Ireland customer discount'}
                  price={renderValue(
                    getPremiumPrice(premiumBreakDownConstants.BOICUS)
                  )}
                />
              )}
              {getPremiumPrice(premiumBreakDownConstants.BOIMUL) && (
                <PremiumBreakdownRow
                  label={'Bank of Ireland multi product discount'}
                  price={renderValue(
                    getPremiumPrice(premiumBreakDownConstants.BOIMUL)
                  )}
                />
              )}
              {getPremiumPrice(premiumBreakDownConstants.BOICAR) && (
                <PremiumBreakdownRow
                  label={'Bank of Ireland insurance discount'}
                  price={renderValue(
                    getPremiumPrice(premiumBreakDownConstants.BOICAR)
                  )}
                />
              )}{' '}
            </>
          )}
          {getPremiumPrice(premiumBreakDownConstants.PROMOPCT) && (
            <PremiumBreakdownRow
              label={'Promo Code'}
              price={renderValue(
                getPremiumPrice(premiumBreakDownConstants.PROMOPCT)
              )}
            />
          )}
          {afterSalesRender() && (
            <PremiumBreakdownRow
              label={
                home_premium_discounts_enabled
                  ? 'Total less sales discounts*'
                  : 'Total less sales discount*'
              }
              price={renderValue(
                getPremiumPrice(premiumBreakDownConstants.AFTERSALES)
              )}
            />
          )}
          <div className={`${className}__note`}>
            Discounts subject to a maximum per policy
          </div>
        </>
      );
    };

    const renderCheck = (codes: string[]) => {
      let ele = false;
      let filteredItem = [];
      codes.forEach((key: string) => {
        if (insurer && insurer.hasOwnProperty(key)) {
          filteredItem.push(insurer[key].toString());
        }
      });
      if (premiumDetails && premiumDetails.length > 0) {
        ele = premiumDetails.find((item: PremiumDetails) => {
          if (filteredItem.includes(item.code)) {
            return true;
          }
        });
      }
      return ele;
    };

    const getExcess = (excessCode: number) => {
      return EXCESS.find((item: any) => {
        if (
          item.value === EXCESS_AMOUNT_MAPPER_REVERSE[excessCode.toString()]
        ) {
          return item;
        }
      });
    };

    return (
      <AccordionCard
        id={`HomePremiumBreakdown__viewPremium`}
        label={
          alternativePolicy
            ? 'Alternative Quote Summary'
            : renewal
            ? 'Renewal Summary Including Statement of Fact'
            : 'View premium breakdown'
        }
        status="none"
        labelStyle="black"
        isOnInit={true}
        isOnState={true}
        childContainerClass={`${className}__noPaddingAccordion`}
        customLabelClass={`${className}__accordionLabel`}
      >
        <div className={`${className}`}>
          <div className={`${className}__section`}>
            <div className={`${className}__heading`}>Cover</div>
            {showRebuildCost() && (
              <PremiumBreakdownRow
                label={`Buildings cover`}
                price={parseFloat(currencyModifer(yourCoverObj.rebuildCost))}
              />
            )}
            <PremiumBreakdownRow
              label={`Contents cover`}
              price={parseFloat(currencyModifer(yourCoverObj.contentsCoverOf))}
            />
            {renewal && (
              <div className={`${className}__contentsCoverNote`}>
                <span>*Note:-</span> Please refer to your policy documents for
                more information on the indexation that has been applied on
                building/contents sum insured. (if applicable).
              </div>
            )}
            <PremiumBreakdownRow
              label={`Unspecified Items`}
              price={parseFloat(currencyModifer(unspecifiedItemsValue))}
            />
            <PremiumBreakdownRow
              label={`Specified Items`}
              price={
                isNaN(specifiedItemCalculation(yourCoverObj.specifiedItems))
                  ? 0
                  : specifiedItemCalculation(yourCoverObj.specifiedItems)
              }
            />
            <PremiumBreakdownRow
              label={'Premium after insurer discounts and loadings*'}
              price={renderValue(getPremiumPrice('AFTERLOADDISC'))}
            />
            <div className={`${className}__note`}>
              This premium includes Buildings Cover, Contents Cover and Cover
              for personal possessions as selected
            </div>
            {renewal && (
              <>
                {renderAddonDetails(addonDetails, 'ContentsAD') && (
                  <PremiumBreakdownRow
                    label={'Accidental Damage Cover for Contents'}
                    textValue={'Yes'}
                  />
                )}
                {renderAddonDetails(addonDetails, 'NCDProtection') && (
                  <PremiumBreakdownRow
                    label={'No Claim Discount Protection Plus'}
                    textValue={'Yes'}
                  />
                )}
                {renderAddonDetails(addonDetails, 'HomeEmergencyAssist') && (
                  <PremiumBreakdownRow
                    label={'Home Emergency Assist Cover'}
                    textValue={'Yes'}
                  />
                )}
                {yourCoverObj.excess > 0 && (
                  <PremiumBreakdownRow
                    showSecondColumn={true}
                    label={getExcess(yourCoverObj.excess)?.label}
                    textValue={''}
                  />
                )}
              </>
            )}
            {totalDiscountPrice > 0 ? (
              <PremiumBreakdownRow
                label={`Includes bank discount of`}
                price={totalDiscountPrice}
              />
            ) : (
              <></>
            )}
          </div>
          {getOptionalCoverComponents()}
          {!renewal &&
            renderCheck(OPTIONAL_EXTRAS) &&
            getOptionalExtrasComponents()}
          <WideDivider color={dividerColor} height={1} />
          <div className={`${className}__section`}>
            {renderCheck(AFTER_SALES_DISCOUNT) && getDiscountComponents()}
            <PremiumBreakdownRow
              label={'Total Premium Payable*'}
              price={renderValue(
                getPremiumPrice(premiumBreakDownConstants.INCLEVY)
              )}
            />
            <PremiumBreakdownRow
              label={'Includes Government Levy Amount'}
              price={renderValue(
                getPremiumPrice(premiumBreakDownConstants.LEVY)
              )}
            />
            <div className={`${className}__note`}>
              *Please see insurer issued documents for full premium breakdown
              details
            </div>
          </div>
          <WideDivider color={dividerColor} height={1} />
          <div className={`${className}__section`}>
            <div className={`${className}__heading`}>Payment Options</div>
            <PremiumBreakdownRow
              label={'Single Payment'}
              price={renderValue(
                getPremiumPrice(premiumBreakDownConstants.INCLEVY)
              )}
            />
          </div>
          <WideDivider color={dividerColor} height={1} />
          <div className={`${className}__section`}>
            <div className={`${className}__heading`}>If paid monthly</div>
            <PremiumBreakdownRow
              label={'12 month instalments of'}
              price={
                renderValue(
                  getPremiumPrice(premiumBreakDownConstants.INCLEVY)
                ) / 12
              }
            />
          </div>
          {renewal && (
            <div className={`${className}__section`}>
              <WideDivider color={dividerColor} height={1} />
              <div className={`${className}__heading`}>Claims Summary</div>
              <ClaimsSummary claims={claims} />
              <WideDivider color={dividerColor} height={1} />
              <div className={`${className}__checkbox-container`}>
                <div className={`${className}__checkbox-item`}>
                  <Checkbox
                    id="claimsConditionOne"
                    name="claimsConditionOne"
                    labelFontSize={12}
                    label={
                      <>
                        Please tick to confirm that the information contained in
                        the above claims summary is correct.
                      </>
                    }
                    isChecked={values.claimsConditionOne}
                    onChange={() => {
                      setFieldValue(
                        'claimsConditionOne',
                        !values.claimsConditionOne
                      );
                    }}
                    value={values.claimsConditionOne}
                  />
                </div>
                <div className={`${className}__checkbox-item`}>
                  <Checkbox
                    id="claimsConditionTwo"
                    name="claimsConditionTwo"
                    labelFontSize={12}
                    label={
                      <>
                        Please tick to confirm the summary is correct and that
                        you have reviewed all your renewal policy documentation.
                      </>
                    }
                    isChecked={values.claimsConditionTwo}
                    onChange={() => {
                      setFieldValue(
                        'claimsConditionTwo',
                        !values.claimsConditionTwo
                      );
                    }}
                    value={values.claimsConditionTwo}
                  />
                </div>
                <div className={`${className}__note`}>
                  If you have any queries or any of the information is incorrect
                  please Call us on 01 488 4042
                </div>
              </div>
            </div>
          )}
          {!alternativePolicy &&
            values?.renewal &&
            !isAgent() &&
            getObjectFromSessionStorage(SELECTED_HOME_QUOTE_STORAGE)
              ?.direct_debit_policy && (
              <>
                <div className={`${className}__buttonGroupLabelMargin`}>
                  <label
                    htmlFor="autoRenewal"
                    className={`${commonFormStylesIdentifier}__fieldLabel`}
                  >
                    Your policy is currently set to Automatically Renew on
                    Direct Debit on {format(start_date, 'Do MMMM YYYY') || ''}.
                    Do you wish to continue with your existing payment details?
                  </label>
                </div>
                <span className={`${className}__input`}>
                  <ButtonGroup
                    name="autoRenewal"
                    onClick={(e: string) => {
                      setFieldValue('autoRenewal', parseBool(e));
                      saveInSessionStorage(
                        AUTO_RENEWAL_EXISTING_PAYMENT,
                        parseBool(e)
                      );
                    }}
                    options={autoRenewalOptions}
                    selected={values.autoRenewal}
                  />
                </span>
                <br />
              </>
            )}
          <div className={`${className}__note`}>
            There is no additional Cost of Credit by opting for paying by
            monthly Direct Debit, please note the direct debit monthly amount
            may vary due to rounding
          </div>
        </div>
      </AccordionCard>
    );
  }
}

export default HomePremiumBreakdown;
